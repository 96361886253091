import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  resetMessenger,
  setChannels,
  setSearchListChannels,
  switchSearchMode,
  setSearchMode,
  setActiveChannel,
  setNewActiveChannel,
  addNewMessageToChannel,
  setMessengerError,
  setOldMessagesChannel,
  setOldestMessageId,
  setIsSearching,
} from '../../../store/messengerSlice';
import {
  ChatUserRight,
  createChat,
  getChannelById,
  getChatsList,
  getContactsList,
  getPreviousMessagesListByLastReadAtOfChatId,
  sendMessage,
} from '../../../api/messenger';
import { addNewChannel, fetchChannelsList, fetchSearchChannelsList } from '../../../store/sagas/sagaActions';
import { RootState } from '../../store';
import {
  OLD_MESSAGES_PORTION,
  START_SEARCH_COUNT,
  WRONG_SEND_MESSAGE,
} from '../../../components/Messenger/constants/constants';
import { SearchMode } from '../../../components/Messenger/constants/enum';
import { ChatRoom, LastReadDateDto, Message, SeachedContactCard } from '../../../components/Messenger/types';
import { HIDE_DURATION_MS } from '../../../constants/common';
import { sendLastReadAtToParticipant } from '../../../api/websocketConnection';

export function* handleGetListChannels(): Generator<CallEffect | PutEffect | SelectEffect, void, any> {

  try {
    const {
      activeUserRight,
      profile,
    } = yield select((state) => state.user);

    if (!activeUserRight || !profile) return;
    const result: Array<ChatRoom> = yield call(getChatsList, activeUserRight.id, profile.id);
    yield put(setChannels(result));
  } catch (e) {
    console.error(e);
  }
}

export function* handleGetSearchListChannels({
  payload: searchValue,
}: PayloadAction<string | undefined>):
  Generator<SelectEffect | CallEffect | PutEffect, void, any> {
  try {
    const {
      user: { activeUserRight },
      messenger: { searchedChannels, searchMode },
    } = yield select((state: RootState) => state);

    if (searchMode === SearchMode.Global) {
      if (searchValue && searchValue?.length >= START_SEARCH_COUNT && activeUserRight) {
        yield put(setIsSearching(true));
        const result: Array<ChatUserRight> = yield call(getContactsList, activeUserRight.id, searchValue);
        yield put(setSearchListChannels(result.map(({
          userRightId,
          organization,
          role,
          userId,
          firstName,
          lastName,
        }) => ({
          id: userId || '',
          userRightId,
          name: firstName || '',
          surname: lastName || '',
          role: role.name,
          organization: organization.name,
        }))));
        yield put(setIsSearching(false));
      } else {
        if (searchedChannels.length) yield put(setSearchListChannels([]));
      }
    }
  } catch (e) {
    console.error(e);
    yield put(setSearchListChannels([]));
  }
}

export function* handleChangeSearchMode({
  payload: searchMode,
}: PayloadAction<SearchMode>):
  Generator<SelectEffect | CallEffect | PutEffect, void> {
  try {
    const { searchValue }: any = yield select((state: RootState) => state.messenger);
    yield put(switchSearchMode(searchMode));

    yield put(fetchSearchChannelsList(searchValue));

  } catch (e) {
    console.error(e);
  }
}

export function* handleInitializeChat(): Generator<PutEffect | CallEffect | SelectEffect, void, any> {
  const { user: { activeUserRight }, permissions: { permissionNames } } = yield select((state: RootState) => state);
  const hasPermission = activeUserRight?.role.permissions.some(
    ({ name }: any) => name === permissionNames?.USE_MESSAGING);

  try {
    yield put(resetMessenger());
    if (hasPermission) {
      yield put(fetchChannelsList());
    }

  } catch (e) {
    console.error(e);
  }
}

export function* handleSelectChannel({
  payload: id,
}: PayloadAction<string>): Generator<PutEffect | CallEffect, void, any> {
  try {
    yield put(setOldestMessageId(null));
    yield put(setSearchMode(false));
    yield put(setNewActiveChannel(null));
    yield put(setActiveChannel(id));
  } catch (e) {
    console.error(e);
  }
}

export function* handleSelectNewContact({
  payload: userRightIdChat,
}: PayloadAction<number>): Generator<PutEffect | CallEffect | SelectEffect, void, any> {
  const { messenger: { activeChatId, channels, searchedChannels } } = yield select((state: RootState) => state);

  try {
    yield put(setSearchMode(false));

    const selectedSearchNewContact = searchedChannels.find(
      (channel: SeachedContactCard) => channel.userRightId === userRightIdChat);
    const memberId = `${selectedSearchNewContact.id}/${selectedSearchNewContact.userRightId}`;

    const selectedCardHasAlreadyChat = channels.find(
      (channel: ChatRoom) => channel.participants.member.userId === memberId);

    if (selectedCardHasAlreadyChat) {
      if (selectedCardHasAlreadyChat.id !== activeChatId) {
        yield put(setActiveChannel(selectedCardHasAlreadyChat.id));
      }
    } else if (selectedSearchNewContact) {
      yield put(setActiveChannel(null));
      yield put(setNewActiveChannel(selectedSearchNewContact.userRightId));
    }

  } catch (e) {
    console.error(e);
  }
}

export function* handleCreateChannelSendMessage({
  payload: text,
}: PayloadAction<string>): Generator<PutEffect | CallEffect | SelectEffect, void, any> {
  const {
    messenger: { newActiveChannelId, channels, activeChatId },
    user: { activeUserRight, profile },
  }: RootState = yield select((state) => state);
  if (!activeUserRight || !profile?.id) return;
  const createdById = `${profile.id}/${activeUserRight.id}`;

  try {
    if (newActiveChannelId) {
      const newChannel: ChatRoom = yield call(createChat, createdById, [
        { userRightId: activeUserRight.id },
        { userRightId: newActiveChannelId },
      ]);
      yield put(setNewActiveChannel(null));
      yield put(setChannels([newChannel, ...channels]));
      yield put(setActiveChannel(newChannel.id));

      yield call(sendMessage, {
        chatRoomId: newChannel.id,
        text,
        sentBy: createdById,
      });
    } else if (activeChatId) {
      yield call(sendMessage, {
        chatRoomId: activeChatId,
        text,
        sentBy: createdById,
      });
    }
  } catch (e) {
    yield put(setMessengerError(WRONG_SEND_MESSAGE));
    yield call(() => new Promise(resolve => setTimeout(() => resolve(true), HIDE_DURATION_MS)));
    yield put(setMessengerError(''));
  }
}

export function* handleAddNewChannel({
  payload: chatRoomId,
}: PayloadAction<string>): Generator<PutEffect | CallEffect | SelectEffect, void, any> {
  try {
    const newChannel: ChatRoom = yield call(getChannelById, chatRoomId);

    const {
      messenger: { channels },
      user: { activeUserRight, profile },
    }: RootState = yield select((state) => state);
    if (!activeUserRight || !profile?.id) return;
    const myId = `${profile.id}/${activeUserRight.id}`;

    if (newChannel.createdBy !== myId) {
      yield put(setChannels([newChannel, ...channels]));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* handleAddMessage({
  payload,
}: PayloadAction<Message>): Generator<PutEffect | CallEffect | SelectEffect, void, any> {
  try {
    const {
      messenger: { channels },
    }: RootState = yield select((state) => state);
    const isExistChatInApp = channels.find((channel) => channel.id === payload.chatRoomId);
    if (!isExistChatInApp) {
      yield put(addNewChannel(payload.chatRoomId));
    } else {
      yield put(addNewMessageToChannel(payload));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* handleAddMoreMessages(): Generator<PutEffect | CallEffect | SelectEffect, void, any> {
  try {
    const {
      messenger: { activeChatId, channels },
    }: RootState = yield select((state) => state);
    if (!activeChatId) return;
    const currentChatIndex = channels.findIndex(el => el.id === activeChatId);

    if (currentChatIndex === undefined) return;
    const lastMessage = channels[currentChatIndex].messages?.slice(-1)?.[0];

    const lastMessageDate = lastMessage?.createdAt;
    if (!lastMessageDate) return;
    yield put(setOldestMessageId(lastMessage.id));

    const oldMessages = yield call(
      getPreviousMessagesListByLastReadAtOfChatId,
      activeChatId,
      lastMessageDate,
      OLD_MESSAGES_PORTION,
    );
    yield put(setOldMessagesChannel({
      oldPackMessages: oldMessages.items,
      chatIndex: currentChatIndex,
    }));
  } catch (e) {
    console.error(e);
  }
}

export function* sendRead({
  payload: data,
}: PayloadAction<LastReadDateDto>): Generator<PutEffect | CallEffect | SelectEffect, void, any> {
  try {
    yield call(sendLastReadAtToParticipant, data);
  } catch (e) {

  }
}

