import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientTokens } from '@react-keycloak/core';
import { ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';

import { store } from './store/store';
import EntryPage from './components/EntryPage';
import rootTheme from './themes/rootTheme';

import LoginNotAllowed from './routes/LoginNotAllowed';
import LinksToMobileApp from './routes/LoginNotAllowed/LinksToMobileApp';

const queryClient = new QueryClient();

import keycloakInstance from './keycloak';
import { AMOUNT_SNACKBARS, HIDE_DURATION_MS } from './constants/common';
import { SnackbarUtilsConfigurator } from './helpers/SnackbarUtils';

const App = () => {
  const tokenLogger = (tokens: AuthClientTokens) => {
    if (tokens?.token) {
      sessionStorage.setItem('currentUserToken', tokens.token);
    }
  };

  return keycloakInstance ? (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      onTokens={tokenLogger}
    >
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={HIDE_DURATION_MS}
          hideIconVariant
          maxSnack={AMOUNT_SNACKBARS}
          style={{ minWidth: 'auto' }}
        >
          <SnackbarUtilsConfigurator />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={rootTheme}>
              <HistoryRouter history={createBrowserHistory({ window })}>
                <QueryClientProvider client={queryClient}>
                  <Routes>
                    <Route
                      element={
                        <LoginNotAllowed>
                          <LinksToMobileApp withPasswordStatus={false}/>
                        </LoginNotAllowed>
                      }
                      path="/mobile-links"
                    />
                    <Route
                      element={keycloakInstance ? <EntryPage/> : null}
                      path="*"
                    />
                  </Routes>
                </QueryClientProvider>
              </HistoryRouter>
            </ThemeProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </Provider>
    </ReactKeycloakProvider>
  ) : null;
};

export default App;
