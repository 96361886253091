import { colors } from '../../themes/colors';

export const iconButtonStyles = {
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:hover div': {
    color: colors.accent.green[1],
    bgcolor: colors.base.green[3],
  },
};
