import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, IconButton, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PopupState, { bindTrigger } from 'material-ui-popup-state';

import HeaderPopover from '../HeaderPopover';
import NotificationsItem from './NotificationItem';
import { NotificationIcon } from '../../icons/icons';

import useNotifications from '../../../hooks/useNotifications';

import { iconButtonStyles } from '../styles';
import { colors } from '../../../themes/colors';
import { shadows } from '../../../themes/shadows';
import { useAppSelector } from '../../../store/store';

const noNotificationMessage = 'You don’t have any new notification';

export const stackStyles = {
  justifyContent: 'center',
  alignItems: 'center',
  bgcolor: colors.base.white[1],
  height: 32,
  width: 32,
  borderRadius: '50%',
  boxShadow: shadows[3],
  color: colors.base.black[1],
  transition: 'all 150ms',
};

const NotificationsPopover = () => {
  const location = useLocation();

  const [previousLocation, setPreviousLocation] = useState(location.pathname);
  const [notificationCount, setNotificationCount] = useState(0);
  const disabledSwitchUsers = useAppSelector((state) => state.user.disabledSwitchUsers);
  const navigate = useNavigate();

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useNotifications();

  useEffect(() => {
    const resultsTotal = data?.pages?.at(-1)?.resultsTotal || 0;
    if (notificationCount !== resultsTotal) setNotificationCount(resultsTotal);
  }, [data?.pages]);

  useEffect(() => {
    if (location.pathname !== previousLocation) refetch();
    setPreviousLocation(location.pathname);
  }, [location.pathname]);

  return (
    <PopupState
      popupId="demo-popup-popover"
      variant="popover"
    >
      {(popupState) => {
        const { onClick, ...restProps } = bindTrigger(popupState);

        const onClickCallback = disabledSwitchUsers ? () => {
          navigate(-1);
        } : onClick;

        return (
          <>
            <IconButton
              color="primary"
              sx={iconButtonStyles}
              {...restProps}
              onClick={onClickCallback}
            >
              <Stack sx={stackStyles}>
                <Badge
                  badgeContent={notificationCount}
                  color="warning"
                  max={999}
                >
                  <NotificationIcon/>
                </Badge>
              </Stack>
            </IconButton>
            <HeaderPopover
              footer={(
                <>
                  {!notificationCount && (
                    <Stack
                      alignItems="center"
                      height={120}
                      justifyContent="center"
                    >
                      <Typography
                        color={colors.base.gray[4]}
                        fontWeight={500}
                      >
                        {noNotificationMessage}
                      </Typography>
                    </Stack>
                  )}
                  {!!notificationCount && (
                    <>
                      {data?.pages?.map((page) => (
                        page.items.map((item) => (
                          <NotificationsItem
                            key={item.id}
                            notificationItem={item}
                            onClose={popupState.close}
                            refetch={refetch}
                          />
                        ))
                      ))}
                      {hasNextPage && (
                        <LoadingButton
                          loading={isFetchingNextPage}
                          onClick={() => fetchNextPage()}
                          sx={{
                            width: 1 / 1,
                            height: 30,
                            borderRadius: 0,
                            bgcolor: colors.base.green[4],
                            '&:hover': {
                              backgroundColor: colors.base.green[3],
                            },
                          }}
                        >
                          LOAD MORE
                        </LoadingButton>
                      )}
                    </>
                  )}
                </>
              )}
              header={(
                <Typography
                  fontWeight={700}
                  variant="body1"
                >
                  Notifications
                </Typography>
              )}
              popupState={popupState}
            />
          </>
        );
      }}
    </PopupState>
  );
};

export default NotificationsPopover;
