import { styled } from '@mui/material/styles';
import MuiOutlinedTextField, { TextFieldProps } from '@mui/material/TextField';

import { colors } from '../../../../themes/colors';
import typography from '../../../../themes/typography';

const OutlinedTextField = styled(MuiOutlinedTextField)<TextFieldProps>(() => ({
  backgroundColor: colors.base.white[1],
  ...typography.body1,
  '& .MuiOutlinedInput-root': {
    padding: 0,
    '& fieldset': {
      borderColor: colors.base.gray[6],
    },
    '&:hover fieldset': {
      borderColor: colors.base.gray[4],
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.base.gray[4],
      borderWidth: 1,
    },
    '&.Mui-error fieldset': {
      borderColor: colors.accent.pink[1],
    },
    '&.Mui-disabled fieldset': {
      border: `1px solid ${colors.base.gray[6]}`,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.base.white[2],
    },
    '.Mui-disabled': {
      backgroundColor: colors.base.white[2],
      '-webkit-text-fill-color': colors.base.gray[1],
      '&::placeholder': {
        '-webkit-text-fill-color': colors.base.gray[5],
      },
    },
  },
  '& .MuiInputBase-input': {
    padding: 8,
    '&::placeholder': {
      color: colors.base.gray[5],
      opacity: 1,
    },
  },
}));

export default OutlinedTextField;
