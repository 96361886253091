import { OTHER } from '../constants/common';
import { SortingState } from '@tanstack/react-table';
import { GridSortDirection } from '@mui/x-data-grid';

export const valueToKeys = (obj: Record<string, string>, value: string) => {
  const arr = Object.entries(obj);
  const index = arr.findIndex((item) => item[1] === value);
  if (index >= 0) return arr[index][0];
  return OTHER;
};

export const getValueByKey = (obj: Record<string, string>, value: string) => {
  const arr = Object.entries(obj);
  const index = arr.findIndex((item) => item[0] === value);

  if (index >= 0) {
    return arr[index][0];
  }

  return OTHER;
};

interface GetCheckboxFieldsValuesProps {
  arr: Array<string>;
  defaultValues: { OTHER: boolean, OTHER_DESCRIPTION: string };
  queries: Record<string, string>;
}

export const getCheckboxFieldsValues = ({ arr, defaultValues, queries }: GetCheckboxFieldsValuesProps) => {
  return arr.reduce((obj, item) => {
    const key = valueToKeys(queries, item);
    if (key === OTHER) return { ...obj, [key]: true, OTHER_DESCRIPTION: item };
    return { ...obj, [key]: true };
  }, defaultValues);
};

export const caseInsensetiveSearch = (text: string, searchText: string) => {
  if (typeof text !== 'string' || typeof searchText !== 'string') return false;
  const result = text.search(new RegExp(searchText, 'i'));
  return result >= 0;
};

export const convertSortingOptions = (sortingOptions: SortingState) => {
  if (sortingOptions[0]) return {
    sort: sortingOptions[0].desc ? 'desc' : 'asc' as GridSortDirection,
    field: sortingOptions[0].id,
  };
};
