const urls = {
  activateUser: '/users/activate',
  approveUser: '/users/approve',
  clubs: '/clubs',
  country: '/countries/all',
  deactivateUser: '/users/deactivate',
  destinations: '/destinations',
  files: '/files',
  notifications: '/notifications',
  organizations: '/organizations',
  profiles: '/users',
  region: '/regions/all',
  rejectUser: '/users/reject',
  renewUser: '/users/renew',
  reports: '/reports',
  roles: '/roles',
  timezones: '/timezones',
  user: '/users',
  messages: '/messages',
  events: '/events',
  search: '/search',
  scheduler: '/web/scheduler',
};

export default urls;
