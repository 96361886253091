import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';

import Typography from '../../../components/Typography';

import { colors } from '../../../themes/colors';

const LoginIsNotPossibleMessage = ({ message }: { message: string }) => {
  const { keycloak } = useKeycloak();

  const onLogAgain = () => {
    keycloak?.logout();
  };

  return (
    <Stack minWidth={250}>
      <Box mb={24}>
        <Typography
          color={colors.accent.orange[1]}
          variant="h6"
        >
          Login is not possible
        </Typography>
      </Box>
      <Typography variant="body1">
        {message}
      </Typography>
      <Stack direction="row">
        <Typography variant="body1">
          Please
        </Typography>
        <Box
          ml={4}
          mr={4}
        >
          <Button
            color="error"
            onClick={onLogAgain}
            size="small"
          >
            <Typography
              color={colors.accent.green[1]}
              variant="body1"
            >
              try again
            </Typography>
          </Button>
        </Box>
        <Typography variant="body1">
          later.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LoginIsNotPossibleMessage;
