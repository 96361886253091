import React from 'react';
import { Avatar, Box, Chip, Stack } from '@mui/material';

import Typography from '../../../../components/Typography';

import { colors } from '../../../../themes/colors';
import { getFullName, getStringAvatar } from '../../../../helpers/nameHandler';

const Header = ({ height, cardData }: {
  height: number
  cardData: {
    name?: string
    surname?: string
    organization?: string
    role?: string
  }
}) => {

  const profileData = { firstName: cardData.name, lastName: cardData.surname };

  return (
    <Stack
      alignItems="end"
      direction="row"
      flexShrink={0}
      height={height}
      padding="0px 24px 12px 20px"
      spacing={12}
    >
      <Avatar
        sx={{
          width: 48,
          height: 48,
          backgroundColor: colors.base.white[1],
          border: `1px solid ${colors.base.gray[1]}`,
          textTransform: 'uppercase',
          fontSize: 12,
          fontWeight: 500,
        }}
      >
        {getStringAvatar(profileData)}
      </Avatar>
      <Stack
        flexGrow={1}
        spacing={6}
      >
        <Typography
          variant="h6"
        >
          {getFullName(profileData)}
        </Typography>
        <Box
          display="grid"
          gap={6}
          gridTemplateColumns="repeat(auto-fit, minmax(10px, max-content))"
        >
          <Chip
            label={cardData.organization}
            sx={{
              backgroundColor: colors.base.orange[1],
              '.MuiChip-label': { padding: '0 10px' },
            }}
          />
          <Chip
            label={cardData.role}
            sx={{
              backgroundColor: colors.base.blue[3],
              '.MuiChip-label': { padding: '0 10px' },
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default Header;
