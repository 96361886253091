import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Box, CircularProgress, InputAdornment, ListItem, Stack, Typography } from '@mui/material';

import TextHighlight from '../TextHighlight';
import InputInSelect from '../InputInSelect';

import { START_GLOBAL_SEARCH_FROM } from '../../constants/common';
import { SearchIcon } from '../icons/icons';
import { DefaultRoles, Levels, SearchGroupNames, SearchResponseKeys } from '../../enums/common';
import { EventStatus } from '../../enums/scheduling';
import { globalSearch, GlobalSearchResponseValue, SearchEventItem } from '../../api/common';
import { searchByTitle } from '../../api/scheduler';
import { useAppSelector } from '../../store/store';
import { OptionWithGrouping, ValueOf } from '../../types/common';
import { searchGroupMap } from './searchGroupMap';

import { colors } from '../../themes/colors';
import useAccess from '../../hooks/useAccess';
import { getFullName } from '../../helpers/nameHandler';
import { uniqueId, debounce } from '../../utils/lodashMethods';
import { getPreparedPath } from './utils';

const closeWidth = 200;
const openWidth = 300;

const GlobalSearch = () => {
  const { user: { activeUserRight }, permissions: { permissionNames } } = useAppSelector((state) => state);

  const accessToViewRole = useAccess(permissionNames?.VIEW_ROLE_DETAILS, [Levels.COUNTRY, Levels.REGIONAL]);
  const accessToEditRole = useAccess(permissionNames?.EDIT_ROLE, [Levels.COUNTRY, Levels.REGIONAL]);
  const accessToViewProfile = useAccess(permissionNames?.VIEW_USER_PROFILE_DETAILS);
  const accessToEditProfile = useAccess(permissionNames?.EDIT_USERS_PROFILE_DETAILS);
  const accessToViewParentGuardian = useAccess(permissionNames?.VIEW_CLIENT_DETAILS, [Levels.REGIONAL, Levels.CLUB]);
  const accessToEditParentGuardian = useAccess(permissionNames?.EDIT_CLIENT_DETAILS, [Levels.REGIONAL, Levels.CLUB]);
  const accessToViewYCM = useAccess(permissionNames?.VIEW_YOUTH_DETAILS, [Levels.REGIONAL, Levels.CLUB]);
  const accessToEditYCM = useAccess(permissionNames?.EDIT_YOUTH_FROM_YOUTH_MEMBER_PAGE, [Levels.REGIONAL, Levels.CLUB]);
  const accessToViewEvent = useAccess(permissionNames?.VIEW_EVENT_DETAILS);
  const accessToEditEvent = useAccess(permissionNames?.EDIT_AN_EVENT);

  const [width, setWidth] = useState(closeWidth);
  const [selectedValue, setSelectedValue] = useState<OptionWithGrouping | null | undefined>(null);
  const [searchText, setSearchText] = useState('');
  const [isInFocus, setIsInFocus] = useState(false);
  const [lastRequestId, setLastRequestId] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<OptionWithGrouping[]>([]);

  const navigate = useNavigate();

  const searchByText = useCallback(async (text: string) => {
    if (text && text.length >= START_GLOBAL_SEARCH_FROM && activeUserRight) {
      const searchId = uniqueId();
      setLastRequestId(searchId);

      Promise.all([
        globalSearch({ userRightId: activeUserRight.id, searchText: text }),
        searchByTitle({ userRightId: activeUserRight.id, searchText: text }),
      ])
        .then(([searchResponse, eventsResult]) => {
          const preparedEvents = eventsResult.map(({ id, title, status }) => ({
            id,
            name: title,
            status,
          }));

          const preparedSearchResponse = Object.entries({ ...searchResponse, events: preparedEvents })
            .reduce((arr: OptionWithGrouping[], [k, v]) => {
              const key = k as keyof GlobalSearchResponseValue;
              const value = v as ValueOf<GlobalSearchResponseValue>;

              const preparedValues: OptionWithGrouping<SearchGroupNames>[] = value.map((responseValues) => {
                const isProfile = (
                  key === SearchResponseKeys.PARENTS_GUARDIANS ||
                  key === SearchResponseKeys.YOUTH_CLUB_MEMBERS ||
                  key === SearchResponseKeys.USER_PROFILES
                );

                const label = isProfile
                  ? getFullName(responseValues)
                  : responseValues.name;

                let preparedRoute: string;

                switch (key) {
                  case SearchResponseKeys.ROLES:
                    const accessToEdit = accessToEditRole && (
                      responseValues.name !== DefaultRoles.VOLUNTEER
                      && responseValues.name !== DefaultRoles.SECRETARY
                      && responseValues.name !== DefaultRoles.PARENT
                    );
                    preparedRoute = getPreparedPath({
                      editAllowed: accessToEdit,
                      viewAllowed: accessToViewRole,
                      key,
                      id: responseValues.id,
                    });
                    break;
                  case SearchResponseKeys.YOUTH_CLUB_MEMBERS:
                    preparedRoute = getPreparedPath({
                      editAllowed: accessToEditYCM,
                      viewAllowed: accessToViewYCM,
                      key,
                      id: responseValues.id,
                    });
                    break;
                  case SearchResponseKeys.USER_PROFILES:
                    preparedRoute = getPreparedPath({
                      editAllowed: accessToEditProfile,
                      viewAllowed: accessToViewProfile,
                      key,
                      id: responseValues.id,
                    });
                    break;
                  case SearchResponseKeys.PARENTS_GUARDIANS:
                    preparedRoute = getPreparedPath({
                      editAllowed: accessToEditParentGuardian,
                      viewAllowed: accessToViewParentGuardian,
                      key,
                      id: responseValues.id,
                    });
                    break;
                  case SearchResponseKeys.EVENTS:
                    const { status } = responseValues as SearchEventItem ;
                    preparedRoute = getPreparedPath({
                      editAllowed: accessToEditEvent && (status !== EventStatus.PASSED),
                      viewAllowed: accessToViewEvent,
                      key,
                      id: responseValues.id,
                    });
                    break;
                }

                return {
                  label,
                  value: preparedRoute,
                  groupBy: searchGroupMap[key],
                };
              });

              return [...arr, ...preparedValues];
            }, [])
            .sort((a, b) => (
              a.groupBy.toLowerCase().localeCompare(b.groupBy.toLowerCase()) ||
              a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            ));

          setLastRequestId(prev => {
            if (prev === searchId) {
              setOptions(preparedSearchResponse);
              return null;
            } else {
              return prev;
            }
          });
        });
    }
  }, [
    accessToEditEvent,
    accessToEditParentGuardian,
    accessToEditProfile,
    accessToEditRole,
    accessToEditYCM,
    accessToViewEvent,
    accessToViewParentGuardian,
    accessToViewProfile,
    accessToViewRole,
    accessToViewYCM,
    activeUserRight,
  ]);

  const debouncedSearchRequest = useMemo(() => debounce(searchByText, 300), [searchByText]);

  useEffect(() => {
    return () => {
      debouncedSearchRequest.cancel();
    };
  }, []);

  const onBlur = () => {
    setWidth(closeWidth);
    setIsInFocus(false);
  };

  const onFocus = () => {
    setWidth(openWidth);
    setIsInFocus(true);
  };

  const onOpen = () => {
    if (searchText && searchText.length >= START_GLOBAL_SEARCH_FROM && !lastRequestId) {
      setTimeout(() => {
        setOpen(true);
      }, 300);
    }
  };

  useEffect(() => {
    if (!isInFocus && selectedValue) {
      setSelectedValue(null);
      setOptions([]);
      setSearchText('');
    }
  }, [isInFocus, selectedValue]);

  useEffect(() => {
    if (isInFocus) {
      if (searchText && searchText.length >= START_GLOBAL_SEARCH_FROM) {
        setOpen(true);
      } else if (!searchText) {
        setOptions([]);
        setOpen(false);
      }
    }
  }, [isInFocus, searchText]);

  const onChange = useCallback((_: unknown, data: OptionWithGrouping | null | undefined) => {
    if (data && typeof data !== 'string' && data.groupBy) {
      setSelectedValue(data);
      navigate(data.value as string);
    }
  }, [navigate]);

  const onInputChange = useCallback((_: unknown, data: string) => {
    setSearchText(data);
    debouncedSearchRequest(data);
  }, [debouncedSearchRequest]);

  return (
    <Autocomplete
      blurOnSelect
      filterOptions={(x) => x}
      getOptionLabel={option => option.label ?? option}
      groupBy={(option) => option.groupBy}
      inputValue={searchText}
      loading={!!lastRequestId}
      loadingText={(
        <Stack
          alignItems="center"
        >
          <CircularProgress
            size={16}
            sx={{ margin: 2 }}
          />
        </Stack>
      )}
      noOptionsText={(
        <Stack
          alignItems="center"
        >
          <Typography color={colors.base.gray[4]}>
            No results found
          </Typography>
        </Stack>
      )}
      onBlur={onBlur}
      onChange={onChange}
      onClose={() => setOpen(false)}
      onFocus={onFocus}
      onInputChange={onInputChange}
      onOpen={onOpen}
      open={open}
      options={options}
      popupIcon={false}
      renderGroup={((params) => (
        <>
          <Box
            sx={{
              py: 6,
              px: 20,
              backgroundColor: colors.base.green[5],
              my: 12,
              '&:first-of-type': {
                mt: 8,
              },
            }}
          >
            <Typography
              color={colors.base.gray[3]}
              fontWeight={500}
              lineHeight={1.29}
              variant="caption"
            >
              {params.group}
            </Typography>
          </Box>
          {params.children}
        </>))}
      renderInput={(params) => {
        const preparedParams = {
          ...params,
          InputProps: {
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color={colors.accent.green[1]}/>
              </InputAdornment>
            ),
          },
        };
        return (
          <InputInSelect
            {...preparedParams}
            placeholder="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: '10px !important',
                paddingRight: '0 !important',
              },
              '& .MuiInputBase-input': {
                padding: '8px 8px 8px 2px !important',
              },
            }}
          />
        );
      }}
      renderOption={(props, option, { inputValue }) => {
        return (
          <ListItem
            {...props}
            key={`${option.value}_${option.label}_${props.id}`}
            sx={{
              padding: '6px 32px !important',
              '&:last-of-type': {
                mb: 8,
              },
            }}
          >
            <Typography
              lineHeight={1.4}
              variant="body1"
            >
              <TextHighlight
                highlightText={inputValue ?? ''}
                wholeText={option.label}
              />
            </Typography>
          </ListItem>
        );
      }}
      sx={{
        width: width,
        transition: 'all 300ms ease',
      }}
      value={selectedValue}
    />
  );
};

export default GlobalSearch;
