import { CSSProperties } from 'react';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { colors } from './colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

const typography:TypographyOptions = {
  button: {
    fontSize: 14,
    fontWeight: 700,
  },
  h1: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.45,
    color: colors.base.gray[1],
  },
  h2: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: 1.45,
    color: colors.base.gray[1],
  },
  h3: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.45,
    color: colors.base.gray[1],
  },
  h4: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1.45,
    color: colors.base.gray[1],
  },
  h5: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.45,
    color: colors.base.gray[1],
  },
  h6: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.45,
    color: colors.base.gray[1],
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.45,
    color: colors.base.gray[1],
  },
  body1: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.45,
    color: colors.base.gray[1],
    textTransform:'none',
  },
  body2: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.45,
    color: colors.base.gray[1],
    letterSpacing: 8,
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.45,
    color: colors.base.gray[1],
    letterSpacing: 'normal',
    textTransform:'none',
  },
  label: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 1.45,
    color: colors.base.gray[1],
  },
};

export default typography;
