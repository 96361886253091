import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { Box, Divider, Popover, Stack } from '@mui/material';
import { PopupState } from 'material-ui-popup-state/core';
import { bindPopover } from 'material-ui-popup-state';

import { scrollStyles } from '../../../themes/scrollStyles';

const distanceToTopOfWindow = 56;
const distanceToBottomOfWindow = 32;

interface MenuPopoverProps {
  footer: ReactNode
  header?: ReactNode
  main?: ReactNode
  popupState: PopupState
  width?: number
}

const HeaderPopover = ({
  footer,
  header,
  main,
  popupState,
  width = 400,
}: MenuPopoverProps) => {
  const headerRef: MutableRefObject<any> = useRef();
  const footerRef: MutableRefObject<any> = useRef();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    if (popupState?.isOpen) setRerender(prev => !prev);
  }, [popupState?.isOpen]);

  useEffect(() => {
    if (popupState?.isOpen) {
      const hHeight = headerRef.current?.clientHeight;
      if (hHeight !== headerHeight) setHeaderHeight(hHeight);

      const fHeight = footerRef.current?.clientHeight;
      if (fHeight !== footerHeight) setFooterHeight(fHeight);
    }
  }, [rerender]);

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...bindPopover(popupState)}
    >
      <Box width={width}>
        {header && (
          <>
            <Stack
              ref={headerRef}
              justifyContent="center"
              minHeight={54}
              px={24}
              py={14}
            >
              {header}
            </Stack>
            <Divider/>
          </>)}
        {!!main && (
          <>
            <Box
              maxHeight={`calc(100vh - ${distanceToTopOfWindow}px - ${distanceToBottomOfWindow}px - ${headerHeight}px - ${footerHeight}px)`}
              overflow="auto"
              sx={scrollStyles}
            >
              {main}
            </Box>
            <Divider/>
          </>
        )}
        <Box ref={footerRef}>
          {footer}
        </Box>
      </Box>
    </Popover>
  );
};

export default HeaderPopover;
