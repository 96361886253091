export enum EventStatus {
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  PASSED = 'PASSED',
  UPCOMING = 'UPCOMING',
}

export enum PaymentType {
  FREE = 'FREE',
  PAID = 'PAID',
}

export enum PaymentFilterType {
  ALL = 'ALL',
  FREE = 'FREE',
  PAID = 'PAID',
}

export enum PaymentStatus {
  PAID = 'Paid',
  UNPAID = 'Unpaid',
}

export enum EventPlaceType {
  ONLINE = 'ONLINE',
  VENUE = 'VENUE',
}

export enum AttendeeType {
  EMPLOYEE = 'EMPLOYEE',
  YOUTH_CLUB_MEMBER = 'YOUTH_CLUB_MEMBER',
}

export enum AttendeeRole {
  ASSISTANT = 'ASSISTANT',
  ATTENDEE = 'ATTENDEE',
  RESPONSIBLE_PERSON = 'RESPONSIBLE_PERSON',
  DEFAULT = 'DEFAULT',
}

export enum BookingStatus {
  RESERVED = 'RESERVED',
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  VISITED = 'VISITED',
  MISSED = 'MISSED',
}

export enum ParticipantBookingStatus {
  RESERVED = 'RESERVED',
  BOOKED = 'BOOKED',
  ATTENDED = 'ATTENDED',
  NOT_ATTENDED = 'NOT_ATTENDED',
  CANCELLED = 'CANCELLED',
}

export enum AttendeePaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export enum InvolvementFilterType {
  ALL = 'ALL',
  ATTEND = 'ATTEND',
  MANAGE = 'MANAGE',
  WORK = 'WORK',
}

export enum ParticipantType {
  ATTENDEE = 'attendees',
  ASSISTANT = 'assistants',
}

export enum SwitchType {
  ON = 'ON',
  OFF = 'OFF',
}
