import { GridSortItem } from '@mui/x-data-grid';
import { client } from '../api/client';
import urls from '../constants/urls';
import {
  OrganizationAllocatedByLevel,
  OrganizationFullItem,
  OrganizationsResponse,
  ResponseWithPagination,
} from '../types/common';
import { Levels, MembershipFileRoutes, MembershipType, OrganizationStatus } from '../enums/common';
import { PublishFilePayload, UploadLinkDataResponse, UploadLinkProps } from './files';

const { organizations, files } = urls;

interface GetOrganisationsQueryParams {
  level?: Levels
  status?: OrganizationStatus
  includeParent?: boolean
  includeChildren?: boolean
}

export interface DeleteOrgRequest {
  amountOfOrgsWithUsers: number
}

export const getOrganizations = ({
  level,
  status,
  includeChildren = false,
  includeParent = false,
}: GetOrganisationsQueryParams): Promise<Array<OrganizationsResponse>> => client.get(organizations, {
  params: {
    level,
    status,
    includeParent,
    includeChildren,
  },
});

interface GetOrganizationByIdProps {
  id: string | number
  userRightId: number
}

export const getOrganizationById = ({ id, userRightId }: GetOrganizationByIdProps): Promise<OrganizationFullItem> => client.get(`${organizations}/${id}`, {
  params: {
    userRightId,
  },
});

export const getOrganizationsByDestinationId = (
  destinationId: number | string,
  userRightId: number,
): Promise<OrganizationFullItem> =>
  client.get(`${organizations}/by-destination`, {
    params: {
      destinationId,
      userRightId,
    },
  });

export const getAllocatedByLevelsOrganizationsCount = (): Promise<OrganizationAllocatedByLevel> => client.get(`${organizations}/count-by-levels`);

const levelApiMap = {
  [Levels.COUNTRY]: 'country',
  [Levels.REGIONAL]: 'regional',
  [Levels.CLUB]: 'club',
};
export const createOrganization = (level: Levels, payloadData: any, userRightId: number) =>
  client.post(`${organizations}/create/${levelApiMap[level]}`, payloadData, {
    params: {
      userRightId,
    },
  });

interface EditOrganisationProps {
  level: Levels;
  payloadData: any;
  userRightId: number;
  organizationId: string;
}

export const editOrganization = ({ level, payloadData, organizationId, userRightId }: EditOrganisationProps) =>
  client.patch(`${organizations}/${organizationId}/update/${levelApiMap[level]}`, payloadData, {
    params: {
      userRightId,
      id: organizationId,
    },
  });

interface FilterParams {
  destinations?: string
  clubs?: string
  expireDateFrom?: number
  expireDateTo?: number
  publishDateFrom?: number
  publishDateTo?: number
  publishedByPerson?: string
  publishedByOrganizations?: string
}

interface QueryParams {
  filters?: FilterParams
  includeParent?: boolean
  page?: string | number
  size?: string | number
  status?: OrganizationStatus
  sortingOptions?: GridSortItem | null
  userRightId?: number | null
}

export const getFilteredOrganizations = ({
  filters,
  includeParent,
  page,
  size,
  status,
  sortingOptions,
  userRightId,
}: QueryParams): Promise<ResponseWithPagination<OrganizationsResponse>> => client.get(`${organizations}/filter`, {
  params: {
    includeParent,
    page,
    size,
    status,
    sortBy: sortingOptions?.field,
    sortDirection: sortingOptions?.sort,
    userRightId,
    ...filters,
  },
});

export const downloadOrganizations = ({
  status,
  sortingOptions,
  includeParent,
  userRightId,
  filters = {},
}: QueryParams): Promise<Blob> => client.get(`${organizations}/download`, {
  params: {
    includeParent,
    status,
    sortBy: sortingOptions?.field,
    sortDirection: sortingOptions?.sort,
    userRightId,
    ...filters,
  },
  responseType: 'blob',
});

export const getRenewMembershipOrganizations = ({
  page,
  size,
  sortingOptions,
  userRightId,
  filters,
}: QueryParams): Promise<Array<any>> => client.get(`${organizations}/renew-membership`, {
  params: {
    page,
    size,
    sortBy: sortingOptions?.field,
    sortDirection: sortingOptions?.sort,
    userRightId,
    ...filters,
  },
});

export const downloadRenewMembershipOrganizations = ({
  sortingOptions,
  userRightId,
  filters = {},
}: QueryParams): Promise<Blob> => client.get(`${organizations}/renew-membership/download`, {
  params: {
    sortBy: sortingOptions?.field,
    sortDirection: sortingOptions?.sort,
    userRightId,
    ...filters,
  },
  responseType: 'blob',
});

export const getTemporaryOrganisationId = (): Promise<{ id: string }> => client.get(`${organizations}/temporary-id`);

const getUploadLinkByType = (membershipType: MembershipType) => (
  ({
    payloadData,
    userRightId,
    organisationId,
  }: UploadLinkProps): Promise<UploadLinkDataResponse> => (
    client.post(files + MembershipFileRoutes[membershipType], payloadData, {
      params: {
        id: organisationId,
        userRightId,
      },
    })
  )
);

export const getAffiliationUploadLink = ({
  payloadData,
  userRightId,
  organisationId,
}: UploadLinkProps) => getUploadLinkByType(MembershipType.AFFILIATION)({ payloadData, userRightId, organisationId });

export const getInsuranceUploadLink = ({
  payloadData,
  userRightId,
  organisationId,
}: UploadLinkProps) => getUploadLinkByType(MembershipType.INSURANCE)({ payloadData, userRightId, organisationId });

export const updateMembershipFile = (data: PublishFilePayload, userRightId: number) => client.patch(`${files}/clubs`, data, {
  params: {
    userRightId,
  },
});

export const deleteOrganisations = (
  userRightId: number,
  ids: string,
): Promise<DeleteOrgRequest> => client.delete(organizations, {
  params: {
    userRightId,
    ids,
  },
});
