import React from 'react';
import { SxProps } from '@mui/system';
import { Stack, Typography } from '@mui/material';

import { InfoIcon } from '../../../icons/icons';
import { colors } from '../../../../themes/colors';

const MessengerError = ({ sx, text }: {
  sx?: SxProps
  text: string
}) => {
  return (
    <Stack
      alignItems="center"
      border="1px solid rgba(211, 47, 47, 0.5)"
      borderRadius="4px"
      color={colors.accent.pink[3]}
      direction="row"
      gap={12}
      sx={{
        right: 16,
        top: -40,
        position: 'absolute',
        padding: 4,
        ...sx,
      }}
    >
      <InfoIcon/>
      <Typography
        color="inherit"
        variant="caption"
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default MessengerError;
