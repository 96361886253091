const tabs = {
  details: {
    label: 'Details',
    value: 'details',
  },
  secretary: {
    label: 'Secretary',
    value: 'secretary',
  },
  agreementsAndPolicies: {
    label: 'Agreements & Policies',
    value: 'agreementsAndPolicies',
  },
  membership: {
    label: 'Membership',
    value: 'membership',
  },
};

export default tabs;
