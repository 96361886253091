import { minutesInHour } from 'date-fns';

export const SELECT_CHAT_MESSAGE = 'Select a contact to start messaging';
export const NO_MESSAGES = 'No messages yet';
export const SEARCH_TITLE = 'Search for a user to start messaging';
export const NO_CHATS = 'No chats yet';
export const NO_GLOBAL_CHATS = 'No results found';
export const WRONG_SEND_MESSAGE = 'Something went wrong. Try again later.';
export const START_SEARCH_COUNT = 2;
export const MAX_MESSAGE_LENGTH = 3000;
export const MAX_TIME_DURATION_FOR_MANAGE_EVENT_BEFORE_START = 3 * minutesInHour;
export const MAX_TIME_DURATION_FOR_MANAGE_EVENT_AFTER_START = 3 * minutesInHour;
export const OLD_MESSAGES_PORTION = 20;
