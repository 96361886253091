import React from 'react';
import { Divider, Stack } from '@mui/material';

import Typography from '../../../../components/Typography';
import Header from '../Header';
import ChatContent from '../../components/ChatContent';
import Footer from '../../components/Footer';

import { useAppSelector } from '../../../../store/store';
import { SELECT_CHAT_MESSAGE } from '../../constants/constants';
import { colors } from '../../../../themes/colors';

const ChatBox = ({ width }: { width: number }) => {

  const {
    messenger: {
      searchedChannels,
      newActiveChannelId,
      activeChatId,
      channels,
    },
  } = useAppSelector((state) => state) || {};

  const newActiveChannel = searchedChannels.find(el => el.userRightId === newActiveChannelId);
  const currentChat = channels.find(el => el.id === activeChatId);

  const interlocutor = currentChat?.participants.member;

  const data = newActiveChannel || interlocutor;
  const {
    name,
    surname,
    organization,
    role,
  } = data || {};

  return (
    <>
      {
        data ?
          <Stack
            divider={<Divider flexItem/>}
            position="relative"
            width={width}
          >
            <Header
              cardData={{
                name,
                surname,
                organization,
                role,
              }}
              height={110}
            />
            <ChatContent/>
            <Footer contactIsNotAvaiable={!!interlocutor?.isDeleted}/>
          </Stack>
          :
          <Stack
            bgcolor={colors.base.green[4]}
            width={width}
          >
            <Typography
              color={colors.base.gray[4]}
              fontWeight={700}
              margin="auto"
              variant="subtitle1"
            >
              {SELECT_CHAT_MESSAGE}
            </Typography>
          </Stack>
      }
    </>
  );
};

export default ChatBox;
