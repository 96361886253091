import React from 'react';
import MuiTab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

import typography from '../../themes/typography';
import { colors } from '../../themes/colors';

const Tab = styled((props: TabProps) => <MuiTab {...props} />)(
  ({ theme }) => ({
    ...typography.subtitle1,
    textTransform: 'none',
    fontWeight: 500,
    minWidth: 0,
    color: colors.base.gray[3],
  }),
);

export default Tab;
