import React from 'react';
import { Button, Stack } from '@mui/material';
import { BrowserView, MobileView } from 'react-device-detect';

const { REACT_APP_MOBILE_APP_NAME, REACT_APP_APP_STORE_LINK, REACT_APP_GOOGLE_STORE_LINK } = process.env;

import Typography from '../../../components/Typography';

import { colors } from '../../../themes/colors';
import { AppStoreIcon, GoogleStoreIcon } from '../../../components/icons/icons';

const LinksToMobileApp = ({
  withPasswordStatus,
}: {
  withPasswordStatus: boolean;
}) => (
  <Stack>
    {!!withPasswordStatus && <Typography
      color={colors.accent.green[1]}
      fontWeight={500}
      variant="subtitle1"
    >
      Permanent password was set.
    </Typography>}
    <BrowserView>
      <Typography
        mt={16}
        variant="body1"
      >
        Please open the mobile application to log in.
      </Typography>
    </BrowserView>
    <MobileView>
      <Typography
        mt={16}
        variant="body1"
      >
        Please use the link below to open the mobile application.
      </Typography>
      <Button
        component="a"
        href={`${REACT_APP_MOBILE_APP_NAME}://auth/first-login`}
        sx={{ mt: 8 }}
        variant="text"
      >
        Open the app
      </Button>
    </MobileView>
    <Typography
      mt={50}
      variant="body1"
    >
      If you don’t have the application installed on your device, use the links below to download
    </Typography>
    <Stack
      direction="row"
      mt={8}
      spacing={8}
    >
      <Button
        component="a"
        href={REACT_APP_APP_STORE_LINK}
        variant="text"
      >
        <AppStoreIcon />
      </Button>
      <Button
        component="a"
        href={REACT_APP_GOOGLE_STORE_LINK}
        variant="text"
      >
        <GoogleStoreIcon />
      </Button>
    </Stack>
  </Stack>
);

export default LinksToMobileApp;
