import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link, ListItemButton, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import { colors } from '../../../themes/colors';

interface NavItemProps {
  name: string
  withoutChildElements?: boolean
  route: string
}

const NavItem = ({
  name,
  withoutChildElements = false,
  route,
}: NavItemProps) => {
  const location = useLocation();

  const isSelected = location.pathname.startsWith(route);

  const variant = withoutChildElements ? 'caption' : 'body1';
  const style: SxProps = withoutChildElements
    ? {
      textTransform: 'uppercase',
      fontWeight: 700,
    }
    : {
      pl: 40,
      fontWeight: 500,
      lineHeight: 1.7,
      color: isSelected ? colors.accent.green[1] : colors.base.gray[3],
      '&:before': {
        position: 'absolute',
        content: '""',
        width: 4,
        height: 1 / 1,
        left: 0,
        top: 0,
        borderRadius: 2,
        backgroundColor: colors.accent.green[1],
        opacity: isSelected ? 1 : 0,
      },
    };

  return (
    <Link
      href={route}
      sx={{ textDecoration: 'none' }}
    >
      <ListItemButton sx={{ py: withoutChildElements ? 12 : 4 }}>
        <Typography
          color={isSelected ? colors.accent.green[1] : colors.base.gray[1]}
          position="relative"
          sx={style}
          variant={variant}
        >
          {name}
        </Typography>
      </ListItemButton>
    </Link>
  );
};

export default NavItem;
