import { YouthClubMemberCreateData } from '../../api/user';
import { getStartDayUTCMs } from '../../utils/timeUtils';
import { FormDataType } from '../../forms/YouthClubMemberPagedForm/youthClubMemberDefaultValues';
import { ClientStatus, YouthMemberRightStatus } from '../../enums/common';
import { ClubRoleInformation } from '../../forms/UserProfileForm/types';
import { ClientRightItem } from '../../forms/ParentsGuardiansForm/parentsGuardianDefaultValues';

export type ClubRightStatus = {
  clubRoleInfo: ClubRoleInformation
  initialCreated?: boolean
  status: YouthMemberRightStatus
};

export type ClubRightsStatusKit = {
  rightFormStateItems: Array<ClubRightStatus>
};

export type YouthClubMemberDraft =
  Omit<YouthClubMemberCreateData, 'rightIds' | 'deletedRightIds'>
  & ClubRightsStatusKit;

function valuesToDraftData(values: FormDataType, createRightFormStateItems: ClientRightItem[]): YouthClubMemberDraft {
  const {
    userId,
    personalDetails: {
      dateOfBirth,
      gender,
      name,
      photoConsent,
      relationshipType,
      surname,
    },
    rightFormStateItems,
    medicalDetails: {
      activityRestrictions,
      addressEC,
      addressGP,
      aShock,
      contactNumberEC,
      contactNumberGP,
      emailEC,
      medicationList,
      nameEC,
      nameGP,
      restrictionsDetails,
      shouldUsePGDetails,
      surnameGP,
      secondParentName,
      secondParentContactNumber,
    },
    isCreatedByAdmin,
  } = values;

  const generalPart = {
    name,
    surname,
    dateOfBirth: getStartDayUTCMs(dateOfBirth as Date),
    relationshipType,
    photoAccept: photoConsent,
    activityRestrictions,
    restrictionsDetails: activityRestrictions ? restrictionsDetails : undefined,
    medicationList,
    anaphylacticShock: aShock,
    doctorName: nameGP,
    doctorSurname: surnameGP,
    doctorContactNumber: contactNumberGP || undefined,
    doctorAddress: addressGP || undefined,
    gender,
    emergencyName: nameEC,
    emergencyContactNumber: contactNumberEC,
    emergencyEmail: emailEC || undefined,
    emergencyAddress: addressEC,
    isCreatedByAdmin,
    shouldUsePGDetails,
    secondParentName,
    secondParentContactNumber: secondParentContactNumber || undefined,
  };

  return {
    id: userId,
    ...generalPart,
    rightFormStateItems: rightFormStateItems
      .filter((el) => !!el.clubRoleInfo)
      .map(({ status, ...restData }) => {
        let assignedStatus: YouthMemberRightStatus;
        if (status) {
          assignedStatus = status;
        } else {
          const relatedClientRight = createRightFormStateItems.find(clientRight => (
            clientRight.roleId === restData.clubRoleInfo?.roleId &&
            clientRight.clubData?.value === restData.clubRoleInfo?.value
          ));
          assignedStatus = relatedClientRight?.status === ClientStatus.DEACTIVATED
            ? YouthMemberRightStatus.DEACTIVATED : YouthMemberRightStatus.ACTIVE;
        }

        return {
          ...restData,
          status: assignedStatus,
        } as ClubRightStatus;
      }),
  };
}

export default valuesToDraftData;
