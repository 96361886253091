import { SagaIterator } from 'redux-saga';
import { all, debounce, takeLatest } from 'redux-saga/effects';

import {
  CHANGE_SEARCH_MODE,
  SELECT_CHANNEL,
  SELECT_NEW_CONTACT,
  FETCH_CHANNELS_LIST,
  FETCH_SEARCHED_CHANNELS_LIST,
  INITIALIZE_MESSENGER,
  CREATE_CHANNEL_SEND_MESSAGE,
  ADD_NEW_MESSAGE_IN_CHAT,
  ADD_NEW_CHANNEL,
  ADD_MORE_MESSAGES,
  SEND_READ_MESSAGES,
} from './actionTypes';
import {
  handleAddMessage,
  handleAddNewChannel,
  handleChangeSearchMode,
  handleCreateChannelSendMessage,
  handleGetListChannels,
  handleGetSearchListChannels,
  handleInitializeChat,
  handleAddMoreMessages,
  handleSelectChannel,
  handleSelectNewContact,
  sendRead,
} from './handlers/messenger';

export function* messengerSaga(): SagaIterator {
  all([
    yield takeLatest(INITIALIZE_MESSENGER, handleInitializeChat),
    yield takeLatest(FETCH_CHANNELS_LIST, handleGetListChannels),
    yield debounce(100, FETCH_SEARCHED_CHANNELS_LIST, handleGetSearchListChannels),
    yield takeLatest(CHANGE_SEARCH_MODE, handleChangeSearchMode),
    yield takeLatest(SELECT_CHANNEL, handleSelectChannel),
    yield takeLatest(SELECT_NEW_CONTACT, handleSelectNewContact),
    yield takeLatest(CREATE_CHANNEL_SEND_MESSAGE, handleCreateChannelSendMessage),
    yield takeLatest(ADD_NEW_MESSAGE_IN_CHAT, handleAddMessage),
    yield takeLatest(ADD_NEW_CHANNEL, handleAddNewChannel),
    yield takeLatest(ADD_MORE_MESSAGES, handleAddMoreMessages),
    yield debounce(300, SEND_READ_MESSAGES,  sendRead),
  ]);
}
