const navigationGroupNames = {
  homePage: 'Home',
  scheduling: 'Scheduling',
  registration: 'Registration',
  administration: 'Administration',
  contentManagement: 'Content Management',
  reporting: 'Repotring',
};

export default navigationGroupNames;
