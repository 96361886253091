import React, { useState } from 'react';
import { Collapse, ListItemButton, Stack, Typography } from '@mui/material';
import ChevronWithRotation from '../../ChevronWithRotation';
import NavItem from '../NavItem';
import { AccessRoutes } from '../../../types/common';

interface NavGroupItemProps {
  name: string
  childItems: AccessRoutes[]
}

const NavGroupItem = ({ name, childItems }: NavGroupItemProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const onClick = () => setIsOpen(prev => !prev);
  return (
    <>
      <ListItemButton
        onClick={onClick}
        sx={{ py: 12 }}
      >
        <Stack
          alignItems="center"
          direction="row"
        >
          <Typography
            fontWeight={700}
            sx={{ textTransform: 'uppercase' }}
            variant="caption"
          >
            {name}
          </Typography>
          <ChevronWithRotation open={isOpen}/>
        </Stack>
      </ListItemButton>
      <Collapse in={isOpen}>
        {childItems.map(item => (
          <NavItem
            key={item.route}
            name={item.name}
            route={item.route}
          />
        ))}
      </Collapse>
    </>
  );
};

export default NavGroupItem;
