import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import { setPermissions } from '../../permissionsSlice';
import { getAllPermissionNames } from '../../../api/roles';

export function* handleGetPermissions(): Generator<CallEffect | PutEffect, void, any> {
  try {
    const resPermissions = yield call(getAllPermissionNames);

    yield put(setPermissions( resPermissions ));
  } catch (e) {
    console.log(e);
  }
}
