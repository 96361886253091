import { GridSortItem } from '@mui/x-data-grid';
import { client } from '../api/client';
import urls from '../constants/urls';
import {
  ResponseParentGuardians,
  ResponseWithPagination,
  ResponseYCM,
  ResponseYouthClubMembers,
  ResponseYouthClubMembersByPG,
  User,
  UserFullItem,
  UserHistory,
  UserWithDetailsShort,
} from '../types/common';
import { AddressProfile, ProfileData, ProfileUpdateData } from '../types/user';

import { ClientStatus, RelationshipType, YouthMemberRightStatus } from '../enums/common';
import { CreateRightType } from '../types/common';

const { user, activateUser, approveUser, deactivateUser, rejectUser, renewUser } = urls;

enum ChangeUserStatus {
  DEACTIVATE = 'deactivate',
  ACTIVATE = 'activate',
}

interface CommonParentGuardianPayloadData {
  email: string
  password?: string
  address: Required<AddressProfile>
  title: string
  name: string
  surname: string
  contactNumber: string

  agreeTermAndCondition: boolean
}

export interface ParentGuardianUpdateData extends Partial<CommonParentGuardianPayloadData> {
  createdRights?: CreateRightType[]
  removedRightIds?: number[]
}

export interface ParentGuardianCreateData extends CommonParentGuardianPayloadData {
  createdRights: CreateRightType[]
  userId: string
  isCreatedByAdmin: boolean
}

interface ActionProfileIds {
  id: string
  ids: Array<string | number>
  userRightId: number
}

export interface YouthClubMemberUpdateData {
  name: string
  surname: string
  dateOfBirth: number
  gender: string
  rightIds: number[]
  deletedRightIds: number[]
  relationshipType: RelationshipType
  photoAccept: boolean
  activityRestrictions: boolean
  restrictionsDetails?: string
  medicationList?: string
  anaphylacticShock: boolean
  doctorName?: string
  doctorSurname?: string
  doctorAddress?: string
  doctorContactNumber?: string
  emergencyName?: string
  emergencyAddress?: string
  emergencyEmail?: string
  emergencyContactNumber?: string
  isCreatedByAdmin?: boolean
  shouldUsePGDetails: boolean
  secondParentName?: string
  secondParentContactNumber?: string
}

export interface YouthClubMemberCreateData extends YouthClubMemberUpdateData {
  id: string
}

export interface FilterClientsParams {
  organisations?: string
  changeDateFrom?: number
  changeDateTo?: number
  changedByPersons?: string
  changedByOrganisations?: string
}

export interface FilterYCMParams {
  dateOfBirthFrom?: number
  dateOfBirthTo?: number
  gender?: string
  organisations?: string
  clients?: string
  changeDateFrom?: number
  changeDateTo?: number
  changedByPersons?: string
  changedByOrganisations?: string
}

interface FilteredClientsQueryParams {
  page?: string | number
  size?: string | number
  status?: ClientStatus
  sortingOptions?: GridSortItem | null
  userRightId?: number | null
  filters?: FilterClientsParams
}

interface YouthMembersQueryParams {
  page?: string | number
  size?: string | number
  status: YouthMemberRightStatus
  sortingOptions?: GridSortItem | null
  userRightId?: number | null
  filters?: FilterYCMParams
}

export interface UserDeleteResponse {
  amountOfResponsibleUsers?: number
}

interface ChangeStatusProfile {
  id: string
  gardaVettingExpiredAt?: string | Date
  userRightId: number
  reasonForRejection?: string
}

export interface UserHistoryItem extends UserHistory {
  statusAction: string
}

export const getUserInfo = (): Promise<User> => client.get(`${user}/info`);

export const confirmGDPR = (): Promise<User> => client.patch(`${user}/confirm-gdpr`);

export const getAllUsers = (userRightId: number, hasPublishedFiles?: boolean): Promise<Array<User>> => {
  const hasPublishedFilesReq = hasPublishedFiles !== undefined ? { hasPublishedFiles } : {};
  return client.get(`${user}/all`, {
    params: {
      userRightId,
      ...hasPublishedFilesReq,
    },
  });
};

export const getAllPossibleSecretaries = (userRightId: number, hasPublishedFiles?: boolean): Promise<Array<User>> => {
  const hasPublishedFilesReq = hasPublishedFiles !== undefined ? { hasPublishedFiles } : {};
  return client.get(`${user}/all-secretaries`, {
    params: {
      userRightId,
      ...hasPublishedFilesReq,
    },
  });
};

export const deleteUsers = (userRightId: number, ids: string): Promise<UserDeleteResponse> => client.delete(`${user}/profile`, {
  params: {
    userRightId,
    ids,
  },
});

export const getUserQualifications = () => client.get(`/metadata${user}/qualifications`);
export const getUserExperience = () => client.get(`/metadata${user}/experience`);
export const getUserSkills = () => client.get(`/metadata${user}/skills`);

export const getUserAvailability = () => client.get(`/metadata${user}/availability`);
export const getAvailableDays = () => client.get(`/metadata${user}/availability/days`);
export const getAvailableTimes = () => client.get(`/metadata${user}/availability/times`);

export const activateProfile = ({
  id,
  userRightId,
  gardaVettingExpiredAt,
}: Omit<ChangeStatusProfile, 'reasonForRejection'>) => client.patch(`${activateUser}/${id}`, {
  gardaVettingExpiredAt: gardaVettingExpiredAt ? gardaVettingExpiredAt.toString() : undefined,
},
{
  params: {
    userRightId,
  },
});

export const approveProfile = ({
  id,
  userRightId,
  gardaVettingExpiredAt,
}: Omit<ChangeStatusProfile, 'reasonForRejection'>) => client.patch(`${approveUser}/${id}`, {
  gardaVettingExpiredAt: gardaVettingExpiredAt ? gardaVettingExpiredAt.toString() : null,
},
{
  params: {
    userRightId,
  },
});

export const rejectProfile = ({
  id,
  userRightId,
  reasonForRejection,
}: Required<Omit<ChangeStatusProfile, 'gardaVettingExpiredAt'>>) => client.patch(`${rejectUser}/${id}`, {
  reasonForRejection,
},
{
  params: {
    userRightId,
  },
});

export const renewProfile = ({
  id,
  userRightId,
  gardaVettingExpiredAt,
}: Required<Omit<ChangeStatusProfile, 'reasonForRejection'>>) => client.patch(`${renewUser}/${id}`, {
  gardaVettingExpiredAt: gardaVettingExpiredAt.toString(),
},
{
  params: {
    userRightId,
  },
});

export const deactivateProfile = ({
  id,
  userRightId,
}: {
  id: string
  userRightId: number
}) => client.patch(`${deactivateUser}/${id}`, null, {
  params: {
    userRightId,
  },
});

const changeStatusPG = ({
  id: parentId,
  ids,
  userRightId,
}: ActionProfileIds, status: ChangeUserStatus) => client.put(`${user}/client/${status}/${parentId}`, {
  userRightIds: ids,
}, {
  params: {
    userRightId,
  },
});

export const activateParentGuardian = (data: ActionProfileIds) => changeStatusPG(data, ChangeUserStatus.ACTIVATE);
export const deactivateParentGuardian = (data: ActionProfileIds) => changeStatusPG(data, ChangeUserStatus.DEACTIVATE);

export const getTemporaryUserId = (): Promise<{ id: string }> => client.get(`${user}/profile/temporary-id`);

export const createProfile = (payloadData: ProfileData, userRightId: number) => client.post(`${user}/profile`, payloadData, {
  params: {
    userRightId,
  },
});

export const updateProfile = ({
  userId,
  payloadData,
  userRightId,
}: { payloadData: ProfileUpdateData, userRightId: number, userId: string }): Promise<any> => client.patch(`${user}/profile/${userId}`, payloadData, {
  params: {
    userRightId,
  },
});

export const getProfileById = (id: string): Promise<UserFullItem> => client.get(`${user}/profile/info/${id}`);

export const checkOrganisationAvailability = (): Promise<boolean> => client.get(`${user}/check-organisation-availability`);

export const checkProfileAvailability = (): Promise<boolean> => client.get(`${user}/check-profile-availability`);

export const getFilteredClients = ({
  page,
  size,
  status,
  sortingOptions,
  userRightId,
  filters,
}: FilteredClientsQueryParams): Promise<ResponseWithPagination<ResponseParentGuardians>> =>
  client.get(`${user}/client/filter`, {
    params: {
      page,
      size,
      status,
      sortBy: sortingOptions?.field,
      sortDirection: sortingOptions?.sort,
      userRightId,
      ...filters,
    },
  });

export const createParentGuardian = ({
  payloadData,
  userRightId,
}: { payloadData: ParentGuardianCreateData, userRightId: number }): Promise<any> => client.post(`${user}/client`, payloadData, {
  params: {
    userRightId,
  },
});

// TODO: looks like redundant method
export const updateParentGuardian = ({
  userId,
  payloadData,
  userRightId,
}: { payloadData: ParentGuardianUpdateData, userRightId: number, userId: string }): Promise<any> => client.patch(`${user}/client/${userId}`, payloadData, {
  params: {
    userRightId,
  },
});

export const updateParentGuardianIncludesYCM = ({
  userId,
  payloadData,
  userRightId,
}: any) => client.patch(`${user}/client/full/${userId}`, payloadData, {
  params: {
    userRightId,
  },
});

export const deleteParentGuardian = (userRightId: number, ids: string): Promise<any> => client.delete(`${user}/client`, {
  params: {
    userRightId,
    ids,
  },
});

export const checkUniqueYCM = ({
  parentId,
  name,
  surname,
  dateOfBirth,
  memberId,
}: {
  parentId: string
  name: string
  surname: string
  dateOfBirth: number
  memberId?: string
}): Promise<any> => client.post(`${user}/client/${parentId}/members/check-unique`, {
  name,
  surname,
  dateOfBirth,
  memberId,
});

export const getYouthMembers = ({
  page,
  size,
  status,
  sortingOptions,
  userRightId,
  filters,
}: YouthMembersQueryParams): Promise<ResponseWithPagination<ResponseYouthClubMembers>> =>
  client.get(`${user}/client/youth-members/filter`, {
    params: {
      page,
      size,
      status,
      sortBy: sortingOptions?.field,
      sortDirection: sortingOptions?.sort,
      userRightId,
      ...filters,
    },
  });

export const getYouthMembersByParentId = ({
  parentId,
  userRightId,
}: { parentId: string; userRightId: number }): Promise<ResponseYouthClubMembersByPG[]> => client.get(`${user}/client/${parentId}/members`, {
  params: {
    userRightId,
  },
});

export const createYouthClubMember = ({
  parentId,
  payloadData,
  userRightId,
}: {
  parentId: string
  payloadData: YouthClubMemberCreateData
  userRightId: number
}) => client.post(`${user}/client/${parentId}/members`, payloadData, {
  params: {
    userRightId,
  },
});

export const updateYouthClubMember = ({
  youthClubMemberId,
  parentId,
  payloadData,
  userRightId,
}: { payloadData: YouthClubMemberUpdateData, userRightId: number, parentId: string, youthClubMemberId: string },
): Promise<any> => client.patch(`${user}/client/${parentId}/members/${youthClubMemberId}`, payloadData, {
  params: {
    userRightId,
  },
});

export const deleteYouthClubMembers = (userRightId: number, ids: string): Promise<any> => client.delete(`${user}/client/youth-members`, {
  params: {
    userRightId,
    ids,
  },
});

const changeStatusYCM = ({
  id: ycmId,
  ids,
  userRightId,
}: ActionProfileIds, status: ChangeUserStatus) => client.put(`${user}/client/member/${status}/${ycmId}`,
  {
    statusByRightIds: ids,
  }, {
    params: {
      userRightId,
    },
  });

export const activateYouthClubMember = (data: ActionProfileIds) => changeStatusYCM(data, ChangeUserStatus.ACTIVATE);

export const joinYouthClubMember = ({
  id: ycmId,
  ids,
  userRightId,
}: ActionProfileIds) => client.put(`${user}/client/member/activate-by-join/${ycmId}`,
  {
    statusByRightIds: ids,
  }, {
    params: {
      userRightId,
    },
  });

export const deactivateYouthClubMember = (data: ActionProfileIds) =>
  changeStatusYCM(data, ChangeUserStatus.DEACTIVATE);

export const downloadYCM = ({
  status,
  sortingOptions,
  userRightId,
  filters = {},
}: Omit<YouthMembersQueryParams, 'page' | 'size'>): Promise<Blob> => client.get(`${user}/client/youth-members/download`, {
  params: {
    status,
    sortBy: sortingOptions?.field,
    sortDirection: sortingOptions?.sort,
    userRightId,
    ...filters,
  },
  responseType: 'blob',
});

export const generateYCMReport = ({
  date,
  userRightId,
  organizations,
}: {
  date: number
  userRightId: number
  organizations: Array<string>,
}): Promise<Blob> => client.get(`${user}/client/youth-members/membership-report`, {
  params: {
    date,
    userRightId,
    organisations: organizations.join(','),
  },
  responseType: 'blob',
});

export const getYouthClubMemberById = ({
  id,
  userRightId,
}: {
  id: string | number
  userRightId: number
}): Promise<ResponseYCM> => client.get(`${user}/client/youth-members/${id}`, {
  params: {
    userRightId,
  },
});

export const getParentGuardianById = ({
  id,
  userRightId,
}: {
  id: string | number
  userRightId: number
}): Promise<ResponseParentGuardians> => client.get(`${user}/client/info/${id}`, {
  params: {
    userRightId,
  },
});

export const checkExistingParentGuardianByParams = ({
  email,
  name,
  surname,
  userRightId,
}: {
  email: string
  name: string
  surname: string
  userRightId: number
}): Promise<{
  userId: string | null
  isUserInRequesterClub?: boolean
  isUserExistByEmail?: boolean
  isUserEqualByNameSurname?: boolean
}> => client.get(`${user}/client/check-existing`, {
  params: {
    email,
    name,
    surname,
    userRightId,
  },
});

export const checkExistingUserByParams = ({
  email,
  name,
  surname,
  userRightId,
}: {
  email: string
  name: string
  surname: string
  userRightId: number
}): Promise<{
  user: UserWithDetailsShort
}> => client.get(`${user}/profile/check-existing`, {
  params: {
    email,
    name,
    surname,
    userRightId,
  },
});

export const getTemporaryYCMId = (): Promise<{ id: string }> => client.get(`${user}/client/members/temporary-id`);

export const getHistoryByProfileId = ({
  id,
  page,
  size,
}: {
  id?: string
  page?: string | number
  size?: string | number
}): Promise<ResponseWithPagination<UserHistoryItem>> => client.get(`${user}/profile/history/${id}`, {
  params: {
    page,
    size,
  },
});

export const downloadPG = ({
  status,
  sortingOptions,
  userRightId,
  filters = {},
}: Omit<FilteredClientsQueryParams, 'page' | 'size'>): Promise<Blob> => client.get(`${user}/client/download`, {
  params: {
    status,
    sortBy: sortingOptions?.field,
    sortDirection: sortingOptions?.sort,
    userRightId,
    ...filters,
  },
  responseType: 'blob',
});
