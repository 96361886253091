export const HUNDRED_PERCENT = 100;
export const EMPTY_CONTENT = '-';
export const DEFAULT_PAGE_SIZE = 10;
export const FIRST_PAGE = 1;
export const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];
export const PAGE_SIZE_OPTIONS_SMALL = [3, 5, 7, 10];
export const START_PUBLISHING_INFO_SEARCH_FROM = 2;
export const START_GLOBAL_SEARCH_FROM = 3;
export const MAX_SIZE_FOR_UPLOAD = 209715200;
export const THIRTY_DAYS = 30;
export const SEE_MORE = 'See more';
export const SEE_LESS = 'See less';
export const HIDE_DURATION_MS = 3000;
export const AMOUNT_SNACKBARS = 3;
export const LEAVE_FORM_MESSAGE = 'Are you sure you want to leave this page?';
export const LEAVE_FORM_DESCRIPTION = 'All unsaved data will be deleted';
export const CHANGE_LEVEL_DESCRIPTION = 'Switching between levels will lead to losing specified data.';
export const CHANGE_APP_TYPE_DESCRIPTION = 'Switching between types will lead to losing specified data.';
export const CONFIRM_DELETE_MESSAGE = 'Are you sure you want to delete?';
export const CONFIRM_CANCEL_MESSAGE = 'Are you sure you want to cancel?';
export const CONFIRM_CANCEL_DESCRIPTION = 'All booked spaces will be cancelled';
export const CONFIRM_CHANGE_LEVEL_MESSAGE = 'Are you sure you want to change a level?';
export const CONFIRM_CHANGE_APP_TYPE_MESSAGE = 'Are you sure you want to change a type?';
export const CONFIRM_REMOVE_CLUB = 'Are you sure you want to remove the club?';
export const CONFIRM_REMOVE_CLUB_DESCRIPTION = 'All existed Youth Club member profiles will be deleted from this club as well with all scheduled upcoming events cancellation.';
export const OTHER = 'OTHER';
export const OTHER_DESCRIPTION = 'OTHER_DESCRIPTION';
export const SYSTEM_USER_NAME = 'System';
export const SYSTEM_USER_ID = 'system-user';
export const SUPPORTED_FORMATS_IMAGES = 'Supported formats: images';
export const SUPPORTED_FORMATS_PDF_IMAGES = 'Supported formats: pdf files, images';
export const MAXIMUM_SIZE_TEXT = 'Maximum size: 200 MB';
export const FILE_IS_TOO_BIG_MESSAGE = 'File is too large';
export const FILE_IS_TOO_BIG_DESCRIPTION = 'File size must be less than or equal to 200MB';
export const SUPER_ADMIN_ROLE_NAME = 'Superadmin';
export const DELETED_USER_NAME = 'Deleted user';
export const DELETED_ORGANIZATION_NAME = 'Deleted organization';
export const DELETE = 'Delete';
export const CANCEL = 'Cancel';
export const CANCEL_EVENT = 'Cancel event';
export const DISCARD = 'Discard';
export const DEFAULT_PRECISION = 2;
export const MAX_PRECISION = 20;
export const MIN_PRECISION = 0;
export const EDITOR_TEXT_LIMIT = 5000;
export const YOUTH_CLUB_MEMBER_ROLE_NAME = 'Youth Club member';
export const YOUTH_CLUB_MEMBER_ORGANISATION_NAME = 'Youth Club Org';
