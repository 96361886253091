import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  permissionNames: Record<string, string> | null
}

const initialState: InitialState = {
  permissionNames: null,
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: initialState,
  reducers: {
    getPermissions: () => {},
    setPermissions: (state, action: PayloadAction<Record<string, string> | null>) => {
      state.permissionNames = action.payload;
    },
  },
});

export const { getPermissions, setPermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
