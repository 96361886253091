const routes = {
  home: '/home',
  scheduling: '/scheduling',
  reporting: '/reporting',
  registration: {
    youthClubMembers: {
      main: '/registration/youth-club-members',
      addNew: '/registration/youth-club-members/add-new',
      edit: '/registration/youth-club-members/edit',
      view: '/registration/youth-club-members/view',
    },
    parentsGuardians: {
      main: '/registration/parents-guardians',
      addNew: '/registration/parents-guardians/add-new',
      edit: '/registration/parents-guardians/edit',
      view: '/registration/parents-guardians/view',
    },
  },
  administration: {
    profiles: {
      main: '/administration/profiles',
      addNew: '/administration/profiles/add-new',
      edit: '/administration/profiles/edit',
      view: '/administration/profiles/view',
    },
    organisationsAndClubs: {
      main: '/administration/organisations-and-clubs',
      addNew: '/administration/organisations-and-clubs/add-new',
      edit: '/administration/organisations-and-clubs/edit',
      view: '/administration/organisations-and-clubs/view',
    },
    rolesAndPermissions: {
      main: '/administration/roles-and-permissions',
      addNew: '/administration/roles-and-permissions/add-new',
      edit: '/administration/roles-and-permissions/edit',
      view: '/administration/roles-and-permissions/view',
    },
    renewMembershipClubOrganizations: {
      main: '/administration/renew-membership',
      edit: '/administration/renew-membership/edit',
      view: '/administration/renew-membership/view',
    },
  },
  contentManagement: {
    events: {
      main: '/content-management/events',
      addNew: '/content-management/events/add-new',
      edit: '/content-management/events/edit',
      view: '/content-management/events/view',
    },
    informationPublishing: '/content-management/information-publishing',
  },
};

export default routes;
