import * as React from 'react';
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
  const { href, ...other } = props;
  return (
    <RouterLink
      ref={ref}
      data-testid="custom-link"
      to={href}
      {...other}
    />
  );
});

export default LinkBehavior;
