import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import AccountItem from '../../components/AccountItem/AccountItem';

import { useAppSelector } from '../../store/store';
import { AppType, OrganizationStatus } from '../../enums/common';
import { UserRight } from '../../types/common';

interface AccountItemsContainerProps {
  setIsOpen: (isOpen: boolean) => void
}

const AccountItemsContainer = ({ setIsOpen }: AccountItemsContainerProps) => {
  const { profile, activeUserRight } = useAppSelector((state) => state.user);
  const { userRights = [] } = profile || {};

  const [fetchingItemId, setFetchingItemId] = useState<number | null>(null);

  const [preparedUserRights, setPreparedUserRights] = useState<UserRight[]>([]);

  useEffect(() => {
    if (userRights.length) {
      setPreparedUserRights(userRights.filter((userRightItem) => userRightItem.role.appType === AppType.WEB));
    }
  }, [userRights]);

  useEffect(() => {
    if (fetchingItemId) setFetchingItemId(null);
  }, [activeUserRight?.id]);

  return (
    <Stack>
      {preparedUserRights
        .map((userRight) => (
          <AccountItem
            key={userRight.id}
            accountName={userRight.organization.name}
            disabled={userRight.organization.status === OrganizationStatus.DEACTIVATED}
            fetchingItemId={fetchingItemId}
            role={userRight.role.name}
            selected={userRight.id === activeUserRight?.id}
            setIsFetchingUserRightId={setFetchingItemId}
            setIsOpen={setIsOpen}
            tooltipTitle="Organisation suspended"
            userRight={userRight}
          />
        ))}
    </Stack>
  );
};

export default AccountItemsContainer;
