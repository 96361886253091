import React from 'react';
import { Stack, Typography } from '@mui/material';

import { InfoIcon } from '../../../../components/icons/icons';
import SendMessageArea from '../../components/SendMessageArea';
import MessengerError from '../../components/MessengerError';

import { colors } from '../../../../themes/colors';
import { useAppSelector } from '../../../../store/store';

interface FooterProps {
  contactIsNotAvaiable?: boolean
}

const Footer = ({
  contactIsNotAvaiable,
}: FooterProps) => {
  const {
    messenger: { messengerError },
  } = useAppSelector((state) => state);

  return (
    <Stack
      bgcolor={contactIsNotAvaiable ? colors.base.white[3] : colors.base.green[4]}
      position="relative"
    >
      {messengerError && <MessengerError text={messengerError}/>}
      {contactIsNotAvaiable ?
        (
          <Stack
            alignItems="center"
            direction="row"
            gap={12}
            height={52}
            margin="auto"
          >
            <InfoIcon color={colors.base.gray[3]}/>
            <Typography
              color={colors.base.gray[3]}
              variant="caption"
            >
              Contact is no longer available
            </Typography>
          </Stack>
        ) : (
          <SendMessageArea/>
        )}
    </Stack>
  );
};

export default Footer;
