import React, { ReactNode, ReactChildren } from 'react';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';

import { colors } from '../../themes/colors';

interface ModalWrapperProps {
  backdropStyles?: SxProps
  borderRadius?: number
  children: ReactNode | ReactChildren
  height?: number
  isOpened: boolean
  mb?: number
  ml?: number
  mr?: number
  mt?: number
  onClose?: () => void
  sx?: SxProps
  width?: number
}

const wrapperStyles: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: colors.base.white[1],
};

const ModalWrapper = ({
  backdropStyles,
  borderRadius = 0,
  children,
  height,
  isOpened,
  mb,
  mt,
  mr,
  ml,
  onClose,
  sx = {},
  width,
}: ModalWrapperProps) => {
  const widthCalculated = (mr && ml) ? `calc(100% - ${ml}px - ${mr}px)` : width ? width : 450;
  const heightCalculated = (mb && mt) ? `calc(100% - ${mb}px - ${mt}px)` : height ? height : 'auto';

  return (
    <Modal
      BackdropProps={{ sx: backdropStyles }}
      aria-describedby="parent-modal-description"
      aria-labelledby="parent-modal-title"
      onClose={onClose}
      open={isOpened}
    >
      <Stack sx={{
        ...wrapperStyles,
        height: heightCalculated,
        width: widthCalculated,
        borderRadius,
        ...sx,
      }}>
        { children }
      </Stack>
    </Modal>
  );
};

export default ModalWrapper;
