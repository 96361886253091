import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Levels, YouthMemberRightStatus } from '../enums/common';
import { UploadFileDataItem } from '../types/files';
import {
  ChangeStatusPayload,
  DeleteItem,
  ResponseYouthClubMembersByPG,
  YCMStatusWithRight,
} from '../types/common';
import { ClubRightStatus, YouthClubMemberDraft } from '../forms/YouthClubMemberModalForm/valuesToDataModalForm';

export type TabledData = {
  id: string
  personalDetails: {
    dateOfBirth: number
    gender: string
    name: string
    photoAccept: boolean
    relationshipType: string
    surname: string
  }
  statusWithRightsExpanded: {
    newChangedStatus?: YouthMemberRightStatus //value for new status if only change status without editing YCM
    deleted?: boolean //prop to mark this statusWithRight as removed

    yCMStatusWithRight: YCMStatusWithRight
    hasEvents: boolean
  }[]
  // hasEvents?: boolean //TODO: need to retire
};

export interface YCMItem {
  tabledData?: TabledData

  deleted: boolean //prop to mark YCM as removed
  formData: {
    saved?: YouthClubMemberDraft
  }
  id: string // ycmID for table
  uploadFilesData: Array<UploadFileDataItem>
}

export interface YCMTabItem extends YCMItem {
  disableDelete?: boolean
}

const initialState: {
  list: Array<YCMItem>
  isDirtyList: boolean
} = {
  list: [],
  isDirtyList: false,
};

const youthClubMemberSlice = createSlice({
  name: 'pendedYCM',
  initialState,
  reducers: {
    setYCM: (state, { payload }: PayloadAction<{
      ycmList: ResponseYouthClubMembersByPG[]
      currentOrganizationId: string,
      currentOrganizationLevel: Levels
    }>) => {
      state.list = payload.ycmList.map(({
        id,
        personalDetails,
        statusByRightExpandedItems,
      }) => {
        const statusWithRightsExpanded = statusByRightExpandedItems.reduce((
          acc,
          { statusByRightItem, hasAnyEvents },
        ) => {
          if (!statusByRightItem.isDeleted && payload.currentOrganizationLevel === Levels.REGIONAL
          ) {
            acc.push({
              yCMStatusWithRight: statusByRightItem,
              hasEvents: hasAnyEvents,
            });
          }
          //set only one not joint SBR item which equals current club
          if (
            !statusByRightItem.isDeleted && payload.currentOrganizationLevel === Levels.CLUB &&
            (
              (statusByRightItem.status !== YouthMemberRightStatus.NOT_JOINED) ||
              (
                statusByRightItem.status === YouthMemberRightStatus.NOT_JOINED &&
                statusByRightItem.userRight.organization.id === payload.currentOrganizationId
              )
            )
          ) {
            acc.push({
              yCMStatusWithRight: statusByRightItem,
              hasEvents: hasAnyEvents,
            });
          }

          return acc;
        }, [] as any[]);

        return {
          tabledData: {
            id,
            personalDetails,
            statusWithRightsExpanded,
          },
          deleted: false,
          formData: {},
          id: id,
          uploadFilesData: [],
        };
      });
    },

    createYCM: (state, { payload }: PayloadAction<{
      saved: YouthClubMemberDraft
      uploadFilesData: UploadFileDataItem[]
    }>) => {
      const { saved, uploadFilesData } = payload;
      state.list = [...state.list, {
        tabledData: undefined,
        deleted: false,
        formData: {
          saved,
        },
        id: saved.id,
        uploadFilesData,
      }];
      if (!state.isDirtyList) state.isDirtyList = true;
    },

    updateYCM: (state, { payload }: PayloadAction<{
      saved: YouthClubMemberDraft
      uploadFilesData: UploadFileDataItem[]
      id: string
    }>) => {
      const { saved, uploadFilesData, id } = payload;
      state.list = state.list.map(el => {
        if (el.id !== id) return el;

        return {
          ...el,
          formData: {
            ...el.formData,
            saved,
          },
          uploadFilesData,
        };
      });
      if (!state.isDirtyList) state.isDirtyList = true;
    },

    resetYCM: (state) => {
      state.list = [];
      state.isDirtyList = false;
    },

    deleteYCM: (state, { payload }: PayloadAction<DeleteItem>) => {
      const { isNewItem, id } = payload;
      if (isNewItem) {
        state.list = state.list.filter(el => el.id !== id);
      } else {
        state.list = state.list.map(el => {
          if (el.id !== id) return el;

          return {
            ...el,
            deleted: true,
          };
        });
      }
      if (!state.isDirtyList) state.isDirtyList = true;
    },
    deletePGClubRight: (state, { payload }: PayloadAction<{ clubId: string }>) => {
      const { clubId } = payload;

      state.list = state.list.map(ycm => {
        const { tabledData, formData: { saved }, deleted: isCurrentYCMDeleted } = ycm;
        if (isCurrentYCMDeleted) return ycm;
        const isCurrentYCMInitial = !!tabledData;

        if (saved) {
          if (!saved.rightFormStateItems.length) return ycm;

          return {
            ...ycm,
            formData: {
              ...ycm.formData,
              saved: {
                ...ycm.formData.saved,
                rightFormStateItems: saved.rightFormStateItems.filter(({ clubRoleInfo }) => {
                  return clubRoleInfo.value !== clubId;
                }),
              },
            },
          };
        } else if (isCurrentYCMInitial && ycm.tabledData?.statusWithRightsExpanded) {
          return {
            ...ycm,
            tabledData: {
              ...ycm.tabledData,
              statusWithRightsExpanded: ycm.tabledData.statusWithRightsExpanded.map(el => {
                if (el.yCMStatusWithRight.userRight.organization.id === clubId) return {
                  ...el,
                  deleted: true,
                };
                return el;
              }),
            },
          };
        }

        return ycm;//default
      }) as any;
    },

    changeStatusYCM: (state, { payload }: PayloadAction<ChangeStatusPayload>) => {
      const { id, status, clubId } = payload;

      state.list = state.list.map(el => {
        if (el.id !== id) return el;

        const formData = {
          ...el.formData,
          saved: {
            ...el.formData.saved,
            rightFormStateItems: el.formData.saved?.rightFormStateItems?.map((ele) => {
              if (ele.clubRoleInfo.value === clubId) return {
                ...ele,
                status,
              };
              return ele;
            }),

          },
        };

        return {
          ...el,
          formData: formData,
        };
      }) as any;
      if (!state.isDirtyList) state.isDirtyList = true;
    },
    changeStatusForNotEditedYCM: (state, { payload }: PayloadAction<ChangeStatusPayload>) => {
      const { id, status, clubId } = payload;

      state.list = state.list.map(el => {
        if (el.id !== id) return el;
        if (!el.tabledData) return el;
        const newVar = {
          ...el,
          tabledData: {
            ...el.tabledData,
            statusWithRightsExpanded: el.tabledData?.statusWithRightsExpanded?.map(ew => {
              if (ew.yCMStatusWithRight.userRight.organization.id === clubId) return {
                ...ew,
                newChangedStatus: status,
              };
              return ew;
            }),
          },
        };
        return newVar;
      });
      if (!state.isDirtyList) state.isDirtyList = true;
    },
    joinCurrentClubToYCM: (state, { payload }: PayloadAction<{
      id: string
      newClubRight: ClubRightStatus
    }>) => {
      const { id, newClubRight } = payload;

      state.list = state.list.map(el => {
        if (el.id !== id) return el;

        const formData = {
          ...el.formData,
          saved: {
            ...el.formData.saved,
            rightFormStateItems: [
              ...el.formData.saved?.rightFormStateItems || [],
              newClubRight,
            ],
          },
        };

        return {
          ...el,
          formData: formData,
        };
      }) as any;

      if (!state.isDirtyList) state.isDirtyList = true;
    },
  },
});

export const {
  setYCM,
  createYCM,
  updateYCM,
  resetYCM,
  deleteYCM,
  changeStatusYCM,
  changeStatusForNotEditedYCM,
  deletePGClubRight,
  joinCurrentClubToYCM,
} = youthClubMemberSlice.actions;

export default youthClubMemberSlice.reducer;
