import { useInfiniteQuery } from 'react-query';
import { Notification, ResponseWithPagination } from '../types/common';
import { getNotifications } from '../api/notifications';
import { useAppSelector } from '../store/store';

const pageSize = 5;

const useNotifications = () => {
  const KEY = 'notifications';

  const { activeUserRight } = useAppSelector((state) => state.user);
  const userRightId = activeUserRight?.id;

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
    queryKey: [KEY, userRightId],
    queryFn: ({ pageParam = 1 }) => {
      if (userRightId) return getNotifications({
        userRightId: userRightId,
        page: pageParam,
        size: pageSize,
      });
      return Promise.resolve();
    },
    getNextPageParam: (lastPage: ResponseWithPagination<Notification>) => {
      if (!lastPage) return false;
      if (lastPage.page < lastPage.pagesTotal) return lastPage.page + 1;
      return false;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  };
};

export default useNotifications;
