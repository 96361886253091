import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RoleItem } from '../types/roles';

interface InitialState {
  defaultPGRole: RoleItem | null
}

const initialState: InitialState = {
  defaultPGRole: null,
};

const metaDataSlice = createSlice({
  name: 'metaData',
  initialState: initialState,
  reducers: {
    setDefaultPGRole: (state, action: PayloadAction<RoleItem>) => {
      state.defaultPGRole = action.payload;
    },
  },
});

export const { setDefaultPGRole } = metaDataSlice.actions;

export default metaDataSlice.reducer;
