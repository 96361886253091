import { client } from '../api/client';
import urls from '../constants/urls';
import { AppType } from '../enums/common';
import { PermissionItem } from '../types/common';
import { GetAllRolesResponse, RoleItem, RoleShortItem } from '../types/roles';

const { roles } = urls;

interface QueryParams {
  page?: string | number
  size?: string | number
  userRightId?: number
}

export interface CreateRolePayloadData extends UpdateRolePayloadData {
  appType: AppType | null
}

export interface UpdateRolePayloadData {
  name: string
  description: string
  permissions: number[]
}

export const getAllRoles = ({ page, size, userRightId }: QueryParams = {}): Promise<GetAllRolesResponse> => {
  const paginationParams = page && size ? {
    page,
    size,
  } : {};

  return client.get(roles, {
    params: {
      ...paginationParams,
      userRightId,
    },
  });
};

export const getAllShortRoles = ({ userRightId }: QueryParams = {}): Promise<RoleShortItem[]> => {
  return client.get(`${roles}/all-short`, {
    params: {
      userRightId,
    },
  });
};

export const downloadRoles = ({ type, userRightId }: { type?: AppType, userRightId: number }): Promise<Blob> => client.get(`${roles}/download`, {
  params: {
    type,
    userRightId,
  },
  responseType: 'blob',
});

export const checkIsUniqueRoleName = (roleName: string) => client.get(`${roles}/validate`, {
  params: {
    roleName,
  },
});

export const getAllPermissions = (): Promise<Array<PermissionItem>> => client.get(`${roles}/permissions`);

export const getAllPermissionNames = (): Promise<Record<string, string>> => client.get(`${roles}/permissions/names`);

interface CreateRoleProps {
  userRightId: number
  payloadData: CreateRolePayloadData
}

export const createRole = ({ userRightId, payloadData }: CreateRoleProps) => client.post(roles, payloadData, {
  params: {
    userRightId,
  },
});

interface UpdateRoleProps {
  userRightId: number
  payloadData: UpdateRolePayloadData
  roleId: string
}

export const updateRole = ({
  roleId,
  payloadData,
  userRightId,
}: UpdateRoleProps) => client.patch(`${roles}/${roleId}`, payloadData, {
  params: {
    userRightId,
  },
});

export const getRoleById = (id: string, userRightId: number): Promise<RoleItem> => client.get(`${roles}/${id}`, {
  params: {
    userRightId,
  },
});

export const getDefaultPGRole = (): Promise<RoleItem> => client.get(`${roles}/default-parent`);

export const getVolunteerRole = (): Promise<RoleItem> => client.get(`${roles}/default-volunteer`);
