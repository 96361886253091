import {
  endOfDay,
  format,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getTime,
  getYear,
  isThisWeek,
  isThisYear,
  isToday,
  millisecondsInHour,
  millisecondsToMinutes,
  startOfDay,
  startOfMonth,
} from 'date-fns';

import {
  currectLocationsOffset,
  DAY_DURATION_MINUTES,
  LAST_MONTH_INDEX,
  MINUTE_DURATION_MS,
  MINUTES_IN_HOUR,
  monthNames,
  SECOND_DURATION_MS,
  SUNDAY_INDEX,
} from '../constants/time';
import { DateItem, MonthYear } from '../types/dateAndTime';

export const getStartDayMs = (date: Date | number) => getTime(startOfDay(date));

export const getEndDayMs = (date: Date | number) => getTime(endOfDay(date));

export const getStartDayUTCMs = (date: Date) => {
  const dateObj = new Date(date);

  return Date.UTC(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate(),
    0,
    0,
    0,
    0,
  );
};

export const getFromAgeBorderMs = (age?: number) => {
  if (!age) return;
  const dateObj = new Date();

  return Date.UTC(
    dateObj.getFullYear() - age - 1,
    dateObj.getMonth(),
    dateObj.getDate(),
    23,
    59,
    59,
  ) + SECOND_DURATION_MS;
};
export const getToAgeBorderMs = (age?: number) => {
  if (!age) return;

  const dateObj = new Date();

  return Date.UTC(
    dateObj.getFullYear() - age,
    dateObj.getMonth(),
    dateObj.getDate(),
    23,
    59,
    59,
  );
};

export const getEndDayUTCMs = (date: Date) => {
  const dateObj = new Date(date);

  return Date.UTC(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate(),
    23,
    59,
    0,
    0,
  );
};

export const getYM = (date?: Date | null): MonthYear => {
  const datePrepared = date ?? new Date();
  return {
    year: getYear(datePrepared),
    month: getMonth(datePrepared),
  };
};

export const getYMD = (date?: Date | null): DateItem => {
  const datePrepared = date ?? new Date();
  return {
    year: getYear(datePrepared),
    month: getMonth(datePrepared),
    day: getDate(datePrepared),
  };
};

export const getEndDay = (date?: Date | null) => {
  const datePrepared = date ?? new Date();
  const {
    year,
    month,
    day,
  } = getYMD(datePrepared);
  return new Date(year, month, day, 23, 59, 59);
};

export const getStartDayFromObj = (date: DateItem): number => {
  const {
    year,
    month,
    day,
  } = date;
  return new Date(year, month, day, 0, 0, 0).getTime();
};

export const getEndDayFromObj = (date: DateItem): number => {
  const {
    year,
    month,
    day,
  } = date;
  return new Date(year, month, day, 23, 59, 59).getTime();
};

const getBorderDateFromNow = (diapason: number): DateItem => {
  const { year, month, day } = getYMD();

  const borderYear = month + diapason > 11 ? year + 1 : year;
  const borderMonth = month + diapason > 11
    ? month === 10 ? 0 : 1
    : month + diapason;

  const amountDaysBorderMonth = getDaysInMonth(new Date(borderYear, borderMonth));

  let borderDay = day;

  if (day > amountDaysBorderMonth) {
    borderDay = amountDaysBorderMonth;
  }

  return {
    year: borderYear,
    month: borderMonth,
    day: borderDay,
  };
};

export const isDateInDiapasonFromNow = (checkedDate: number, diapason: number): boolean => {
  const { year, month, day } = getBorderDateFromNow(diapason);
  const borderDateMs = new Date(year, month, day, 23, 59, 59).getTime();

  return borderDateMs < checkedDate;
};

export const convertLocalMsToUTCMs = (localMs: number) => localMs - currectLocationsOffset * MINUTE_DURATION_MS;

export const getIsNotDate = (date: string | number | Date) => {
  if (!date) return true;

  if (typeof date === 'object') {
    return !date.getDate || !date.valueOf();
  }

  if (typeof date === 'string') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new Date(date) == 'Invalid Date';
  }

  return typeof date !== 'number' && typeof date !== 'string';
};

export const getTimeComponents = (time: Date | number = Date.now()) => {
  const timeConverted = new Date(time);
  const year = timeConverted.getFullYear();
  const month = timeConverted.getMonth();
  const date = timeConverted.getDate();
  const hours = timeConverted.getHours();
  const minutes = timeConverted.getMinutes();
  const seconds = timeConverted.getSeconds();
  const ms = timeConverted.getMilliseconds();

  const yearUTC = timeConverted.getUTCFullYear();
  const monthUTC = timeConverted.getUTCMonth();
  const dateUTC = timeConverted.getUTCDate();
  const hoursUTC = timeConverted.getUTCHours();
  const minutesUTC = timeConverted.getUTCMinutes();
  const secondsUTC = timeConverted.getUTCSeconds();
  const msUTC = timeConverted.getUTCMilliseconds();

  return {
    year, month, date, hours, minutes, seconds, ms,
    yearUTC, monthUTC, dateUTC, hoursUTC, minutesUTC, secondsUTC, msUTC,
  };
};

export const getTimeFromMsKeptAsUTCTime = (date: Date | number, outputAs?: 'date' | 'ms') => {
  if (!date) return;
  const { yearUTC, monthUTC, dateUTC, hoursUTC, minutesUTC, secondsUTC, msUTC } = getTimeComponents(date);

  const convertedTime = new Date(
    yearUTC,
    monthUTC,
    dateUTC,
    hoursUTC,
    minutesUTC,
    secondsUTC,
    msUTC,
  );

  return outputAs === 'date' ? convertedTime : convertedTime.getTime();
};

export const getTimeStringHHMMFromMinutes = (minutes: number, separator = ':') => {
  if (minutes <= 0) return `00${separator}00`;
  const labelHour = Math.floor(minutes / MINUTES_IN_HOUR) % 24;
  const labelMin = minutes % MINUTES_IN_HOUR;

  const preparedTimeString = `${labelHour < 10 ? `0${labelHour}` : labelHour}${separator}${+labelMin < 10 ? `0${labelMin}` : labelMin}`;

  return preparedTimeString;
};

export const getTimeSlots = ({
  from = { hours: 0, minutes: 0 },
  stepInMinutes = 5,
  to = { hours: 24, minutes: 0 },
}) => {
  if (to.hours < from.hours || (to.hours === from.hours && to.minutes < from.minutes)) return [];
  const startTimeMinutes = from.hours * MINUTES_IN_HOUR + from.minutes;
  const untilTimeMinutes = to.hours * MINUTES_IN_HOUR + to.minutes;
  return Array.from({ length: (untilTimeMinutes - startTimeMinutes) / stepInMinutes + 1 }, (_, el) => {
    const amountInMinutes = startTimeMinutes + el * stepInMinutes;

    return {
      amountInMinutes: amountInMinutes,
      amountInMs: amountInMinutes * MINUTE_DURATION_MS,
      valueHours: Math.floor(amountInMinutes / MINUTES_IN_HOUR),
      valueMinutes: amountInMinutes % MINUTES_IN_HOUR,
      stringHHMMFormat: getTimeStringHHMMFromMinutes(amountInMinutes),
    };
  });
};

export const getDuration = (durationMinutes: number): string => {
  if (durationMinutes <= 0) return '';
  let remainMinutes;

  const daysDuration = Math.floor(durationMinutes / DAY_DURATION_MINUTES) ? `${Math.floor(durationMinutes / DAY_DURATION_MINUTES)}d ` : '';
  remainMinutes = durationMinutes % DAY_DURATION_MINUTES;

  const hoursDuration = Math.floor(remainMinutes / MINUTES_IN_HOUR) ? `${Math.floor(remainMinutes / MINUTES_IN_HOUR)}h ` : '';
  remainMinutes = durationMinutes % (MINUTES_IN_HOUR);

  const minutesDuration = remainMinutes ? `${remainMinutes}m` : '';

  return `${daysDuration}${hoursDuration}${minutesDuration}`.trim();
};

export const getDurationFromToInString = (from: number, to: number) => getDuration(
  millisecondsToMinutes(to) - millisecondsToMinutes(from),
);

export const getDurationFromToInMinutes = (from: number, to: number) => {
  return millisecondsToMinutes(from - to);
};

export const getDurationInDays = (startDateInMilliseconds: number, endDateInMilliseconds: number) => {
  if (
    !endDateInMilliseconds ||
    !startDateInMilliseconds ||
    typeof startDateInMilliseconds !== 'number' ||
    typeof endDateInMilliseconds !== 'number' ||
    endDateInMilliseconds < startDateInMilliseconds
  ) return 0;
  return Math.floor((
    millisecondsToMinutes(endDateInMilliseconds - getStartDayMs(startDateInMilliseconds)) / DAY_DURATION_MINUTES) + 1);
};

export const getDateDDmmYYYY = (date: number | Date) => {
  if (getIsNotDate(date)) return '';

  return format(date, 'dd.MM.yyyy');
};

export const getDateWithTime = (date: number | Date) => {
  if (getIsNotDate(date)) return '';

  return `${format(date, 'dd.MM.yyyy')} - ${format(date, 'HH:mm')}`;
};

export const getStringFromTimeToDistanceNow = (start: Date | number = Date.now()) => {
  if (!isThisYear(start)) {
    return format(start, 'PP');
  } else if (!isToday(start)) {
    return format(start, 'MMM d');
  } else {
    return format(start, 'HH:mm');
  }
};

export const getLabelStringFromDateTimeMessage = (start: Date | number = Date.now()) => {
  if (!isThisYear(start)) {
    return format(start, 'PP');
  } else if (!isThisWeek(start)) {
    return format(start, 'MMM d');
  } else if (!isToday(start)) {
    return format(start, 'EEEE');
  } else {
    return 'Today';
  }
};

export const getDateFromObj = (date: DateItem | null): string => {
  if (!date) return '';
  return `${date.day} ${monthNames[date.month]} ${date.year}`;
};

export const isDateToday = (date: DateItem): boolean => {
  const today = getYMD();
  return today.year === date.year && today.month === date.month && today.day === date.day;
};

export const isDateInThePast = (date: DateItem): boolean => {
  const { year, month, day } = getYMD();
  const todayStartMs = new Date(year, month, day, 0, 0, 0).getTime();
  const checkedDayMs = new Date(date.year, date.month, date.day).getTime();
  return checkedDayMs < todayStartMs;
};

export const isEqualDates = (d1?: DateItem | null, d2?: DateItem | null): boolean => {
  if (!d1 || !d2) return false;
  return d1.year === d2.year && d1.month === d2.month && d1.day === d2.day;
};

export const getCountDaysInMonth = ({ year, month }: MonthYear): number => getDaysInMonth(new Date(year, month));

export const getNumberOfStartMonthDayInWeek = (date?: Date | number): number => {
  const dateObj = date ?? new Date();

  const startMonth = startOfMonth(startOfDay(dateObj));
  const numberOfDayIfSundayFirs = getDay(startMonth);

  if (numberOfDayIfSundayFirs === 0) return SUNDAY_INDEX;
  return numberOfDayIfSundayFirs - 1;
};

export const getPrevMonth = (monthYearDate: MonthYear): MonthYear => {
  if (monthYearDate.month === 0) {
    return {
      month: LAST_MONTH_INDEX,
      year: monthYearDate.year - 1,
    };
  }

  return {
    month: monthYearDate.month - 1,
    year: monthYearDate.year,
  };
};

export const getNextMonth = (monthYearDate: MonthYear): MonthYear => {
  if (monthYearDate.month === LAST_MONTH_INDEX) {
    return {
      month: 0,
      year: monthYearDate.year + 1,
    };
  }

  return {
    month: monthYearDate.month + 1,
    year: monthYearDate.year,
  };
};

export const getStartMonthMs = (monthYearDate: MonthYear): number => {
  return new Date(monthYearDate.year, monthYearDate.month, 1, 0, 0, 0).getTime();
};

export const getEndMonthMs = (monthYearDate: MonthYear): number => {
  return new Date(monthYearDate.year, monthYearDate.month, getCountDaysInMonth(monthYearDate), 23, 59, 59).getTime();
};

export const getMonthNameByIndex = (index: number): string => {
  if (index < 0 || index > LAST_MONTH_INDEX) return '';
  return monthNames[index];
};

export const getNextDay = (date: Date): Date => (
  new Date(date.getTime() + millisecondsInHour * 24)
);
