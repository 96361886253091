import React from 'react';
import { List } from '@mui/material';
import NavItem from './NavItem';
import NavGroupItem from './NavGroupItem';
import { Access } from '../../types/common';

const Navigation = ({ accesses }: { accesses: Access[] }) => (
  <List
    component="nav"
    sx={{ pt: 30 }}
  >
    {accesses.map(({ navigationGroupName, accessRoutes }) => (
      navigationGroupName ? (
        <NavGroupItem
          key={navigationGroupName}
          childItems={accessRoutes}
          name={navigationGroupName}
        />
      ) : (
        <NavItem
          key={accessRoutes[0].name}
          name={accessRoutes[0].name}
          route={accessRoutes[0].route}
          withoutChildElements
        />)
    ))}
  </List>
);

export default Navigation;
