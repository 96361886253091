import axios, { CancelTokenSource } from 'axios';

import { client } from '../api/client';
import urls from '../constants/urls';
import { FileDirectory, FileRoutes, MembershipFileRoutes } from '../enums/common';
import { MembershipDocumentItem, ResponseWithPagination } from '../types/common';
import { FileInResponse } from '../types/files';

const { files } = urls;

interface FilterParams {
  destinations?: string
  clubs?: string
  expireDateFrom?: number
  expireDateTo?: number
  publishDateFrom?: number
  publishDateTo?: number
  publishedByPerson?: string
  publishedByOrganizations?: string
}

interface FilesQueryParams {
  directory: FileDirectory | string
  filters?: FilterParams
  page?: string | number
  route?: FileRoutes
  size?: string | number
  searchValue?: string
  userRightId?: number | null
}

interface MembershipFilesQueryParams {
  organisationId: string
  page?: string | number
  route: MembershipFileRoutes
  size?: string | number
  searchValue?: string
  userRightId?: number | null
}

export interface UploadLinkData {
  targetDirectory?: string
  files: Array<{
    uploadId: string
    title: string
    extension: string
    description: string
    expiresAt?: number,
  }>
}

export interface UploadLinkProps {
  payloadData: UploadLinkData
  organisationId?: string
  userRightId?: number
}

export interface UploadLinkDataResponse {
  uploadUrls: Array<{
    filePath: string
    headers: Array<{ key: string, value: string }>
    uploadId: string
    uploadUrl: string
  }>
}

interface UploadFileData {
  url: string,
  data: File | Blob,
  cb?: (progress: number) => void,
  setCancelUploading?: CancelTokenSource,
  headers: Record<string, string>
}

export const getFiles = ({
  directory,
  filters,
  page,
  route,
  size,
  searchValue,
  userRightId,
}: FilesQueryParams): Promise<ResponseWithPagination<FileInResponse>> => (
  client.get(files + (route ?? ''), {
    params: {
      directory,
      page,
      size,
      searchValue,
      userRightId,
      ...filters,
    },
  })
);

export const getMembershipFiles = ({
  organisationId,
  page,
  route,
  size,
  searchValue,
  userRightId,
}: MembershipFilesQueryParams): Promise<ResponseWithPagination<MembershipDocumentItem>> => (
  client.get(files + route, {
    params: {
      id: organisationId,
      page,
      size,
      searchValue,
      userRightId,
    },
  })
);

export const getUploadLink = ({ payloadData }: UploadLinkProps): Promise<UploadLinkDataResponse> => (
  client.post(`${files}/upload`, payloadData)
);

export const uploadFile = (
  {
    url,
    headers,
    data,
    cb,
    setCancelUploading,
  }: UploadFileData,
) => {

  return axios.put(url, data,
    {
      headers: {
        ...headers,
        'Content-Type': 'application/octet-stream',
      },
      onUploadProgress: (process) => {
        if (process.total) {
          const progress = Math.round((100 * process.loaded) / process.total);

          cb?.(progress);
        }
      },
      cancelToken: setCancelUploading?.token,
    });
};

export const removeFiles = (filePaths: string) => {

  return client.delete(files, {
    params: {
      filePaths,
    },
  });
};

export const getDownloadUrl = (filePath: string): Promise<string> => (
  client.get(`${files}/download`, { params: { filePath } })
);

export const getBlobData = (url: string): Promise<any> => axios.get(url, { responseType: 'blob' });

export const deleteFile = async (ids: string[]) => client.delete(files, {
  params: {
    filePaths: ids.join(','),
  },
});

export interface PublishFilePayload {
  destinationWithClubs?: Array<{
    destinationId: number,
    clubsIds: Array<number>,
  }>
  description?: string
  filePath: string
  thumbnailPath?: string
  expiresAt?: number | null
  title?: string
}

export const publishFile = (data: PublishFilePayload, userRightId: number) => client.put(files, data, {
  params: {
    userRightId,
  },
});

export const unpublishFile = (ids: string[]) => client.put(`${files}/unpublish`, {
  paths: ids,
});

export const updateFile = (data: PublishFilePayload) => client.patch(files, data);
