import { combineReducers } from '@reduxjs/toolkit';
import appSlice from './appSlice';
import permissionsSlice from './permissionsSlice';
import userSlice from './userSlice';
import youthClubMemberSlice from './youthClubMemberSlice';
import messengerSlice from './messengerSlice';
import schedulingSlice from './schedulingSlice';
import metaDataSlice from './metaDataSlice';

export default combineReducers({
  app: appSlice,
  messenger: messengerSlice,
  pendedYCM: youthClubMemberSlice,
  permissions: permissionsSlice,
  scheduling: schedulingSlice,
  user: userSlice,
  metaData: metaDataSlice,
});
