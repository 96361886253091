
export type ActualPGData = {
  parentAddress?: string
  parentName?: string
  parentSurname?: string
  parentEmail?: string
  parentContactNumber?: string
};
import { getValueByKey } from '../../utils/commonUtils';
import { OTHER } from '../../constants/common';
import { Gender } from '../../enums/common';

const dataToValues = (data: any, actualPGData?: ActualPGData) => {

  const {
    parentAddress = '',
    parentName = '',
    parentSurname = '',
    parentEmail = '',
    parentContactNumber = '',
  } = actualPGData || {};

  const {
    parentId,
    id: userId,
    medicalDetails: {
      activityRestrictions,
      restrictionsDetails,
      medicationList,
      anaphylacticShock: aShock,
      doctorAddress: addressGP,
      doctorContactNumber: contactNumberGP,
      doctorName: nameGP,
      doctorSurname: surnameGP,
      secondParentName,
      secondParentContactNumber,
      emergencyAddress: addressEC,
      emergencyContactNumber: contactNumberEC,
      emergencyEmail: emailEC,
      emergencyName: nameEC,
    },
    personalDetails: {
      dateOfBirth,
      gender,
      name,
      photoAccept: photoConsent,
      relationshipType,
      surname,
    },
    isCreatedByAdmin,
    shouldUsePGDetails,
  } = data;

  const genderKey = getValueByKey(Gender, gender);
  let specifyGender;
  if (genderKey === OTHER) specifyGender = gender;

  return {
    userId,
    personalDetails: {
      parentId,
      relationshipType,
      name,
      surname,
      dateOfBirth: new Date(dateOfBirth),
      gender: genderKey ?? '',
      specifyGender: specifyGender ?? '',
      photoConsent,
    },
    medicalDetails: {
      activityRestrictions,
      restrictionsDetails: String(activityRestrictions) === 'true' ? restrictionsDetails : '',
      medicationList,
      aShock,
      nameGP,
      surnameGP,
      contactNumberGP,
      addressGP,
      secondParentName,
      secondParentContactNumber,
      nameEC: shouldUsePGDetails && actualPGData ? `${parentName} ${parentSurname}` : nameEC,
      contactNumberEC: shouldUsePGDetails && actualPGData ? parentContactNumber : contactNumberEC,
      emailEC: shouldUsePGDetails && actualPGData ? parentEmail : emailEC,
      addressEC: shouldUsePGDetails && actualPGData ? parentAddress : addressEC,
      shouldUsePGDetails,
    },
    isCreatedByAdmin: isCreatedByAdmin,
  };
};

export default dataToValues;
