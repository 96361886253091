import { AttendeeType, ParticipantType } from '../../../enums/scheduling';

export const participantMap = {
  [ParticipantType.ATTENDEE]: 'attendees',
  [ParticipantType.ASSISTANT]: 'assistants',
};

export const userTypeMap = {
  [AttendeeType.EMPLOYEE]: 'employee',
  [AttendeeType.YOUTH_CLUB_MEMBER]: 'client',
};
