import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import App from './App';

import './styles/_common.scss';
import './index.scss';

try {
  const { REACT_APP_BASE_URL, REACT_APP_SENTRY_DSN } = process.env;

  if (REACT_APP_SENTRY_DSN && REACT_APP_BASE_URL) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', REACT_APP_BASE_URL],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production! can be 1.0 for DEV env
      // Session Replay
      // This sets the sample rate at 10%. You may want to change it 
      // to 100% while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: 0.1,
      // If you're not already sampling the entire session,
      // change the sample rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    });
  }
} catch (e) {
  console.error(e);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
