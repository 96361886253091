import { AppType } from '../enums/common';

export const appTypeMapShort = {
  [AppType.WEB]: 'Web',
  [AppType.MOBILE]: 'Mobile',
};

export const appTypeMapFull = {
  [AppType.WEB]: 'Web app',
  [AppType.MOBILE]: 'Volunteer Mobile app',
};
