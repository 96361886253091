import { io, Socket } from 'socket.io-client';
import { Dispatch } from '@reduxjs/toolkit';

import { addNewMessage } from '../store/sagas/sagaActions';
import { LastReadDateDto, Message } from '../components/Messenger/types';
import { setLastReadAtToChannel } from '../store/messengerSlice';

const { REACT_APP_WEBSOCKET_SERVER_URL } = process.env;
const websocketURL = REACT_APP_WEBSOCKET_SERVER_URL;

let socket: Socket | undefined;

export const connectToWebsocket = (dispatch: Dispatch, {
  token,
  userId,
  userRightId,
}: {
  token: string
  userId: string
  userRightId: number
}) => {
  if (socket || !websocketURL) return;
  socket = io(websocketURL, {
    auth: {
      token,
      userId: userId,
      attributes: {
        userRightId: userRightId,
      },
    },
    transports: ['websocket', 'polling'],
    rejectUnauthorized: false,
  });

  socket.on('connect', () => {});

  socket.on('disconnect', (reason) => {
    if (reason === 'io server disconnect') {
      //show that error from server
    }
  });

  socket.on('newChatRoom', (chatRoomId: string) => {
  });
  socket.on('message', (message: Message) => {
    dispatch(addNewMessage(message));
  });
  socket.on('new-last-read-date', (readDateObj: LastReadDateDto) => {
    dispatch(setLastReadAtToChannel(readDateObj));
  });
};

export const sendLastReadAtToParticipant = (obj: {
  chatRoomId: string
  participantId: string
  lastReadAt: number
}) => {
  if (!socket) return;
  socket.emit('update-last-read-date', obj);
};

export const disconnectWebsocket = () => {
  if (!socket) return;
  socket.disconnect();
  socket = undefined;
};
