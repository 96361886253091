import { Box } from '@mui/material';
import { ChevronUpIcon } from '../icons/icons';
import React from 'react';
import { colors } from '../../themes/colors';

interface ChevronWithRotationProps {
  open: boolean
  color?: string
}

const ChevronWithRotation = ({ open, color = colors.base.black[1] }: ChevronWithRotationProps) => (
  <Box
    ml={14}
    sx={{
      transition: 'transform 0.3s ease',
      transform: open ? 'translateY(-3px)' : 'rotate(0.5turn)',
    }}
  >
    <ChevronUpIcon color={color}/>
  </Box>);

export default ChevronWithRotation;
