import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

import { colors } from '../../../../themes/colors';
import { Message } from '../../types';

interface MessageItemProps {
  message: Message
  userId: string
  hiddenTime?: boolean
}

const MessageItem = ({
  message,
  userId,
  hiddenTime = false,
}: MessageItemProps) => {

  const isSenderMe = message.sentBy == userId;

  return (
    <Stack
      alignSelf={isSenderMe ? 'flex-end' : 'flex-start'}
      mb={hiddenTime ? 0 : 16}
    >
      <Box
        bgcolor={colors.base.green[3]}
        borderRadius="4px"
        maxWidth={420}
        mb={4}
        padding="8px 12px"
      >
        <Typography sx={{ overflowWrap: 'break-word' }}>{message.text}</Typography>
      </Box>
      {!hiddenTime && (
        <Typography
          alignSelf="inherit"
          variant="caption"
        >
          {format(message.createdAt, 'HH:mm')}
        </Typography>
      )}
    </Stack>
  );
};

export default MessageItem;
