import React, { ReactChildren, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import Typography from '../../components/Typography';
import {
  AwtgLogo,
  LCETBCorpIdMain,
  DCYA2020Logo,
  ThumbnailLotto,
  LimerickYouthServiceLogo,
  ThumbnailCombinedLogoGrantees,
  YouthWorkIrelandLogoHorizontal,
  YouthServiceLogo,
} from '../../components/icons/logos';
import image from '../../assets/image.jpg';
import { colors } from '../../themes/colors';

interface LoginNotAllowedProps {
  children?: ReactNode | ReactChildren
}

const LoginNotAllowed = ({ children }: LoginNotAllowedProps) => {
  return (
    <Stack
      alignItems="center"
      flexGrow={1}
      height="100vh"
      justifyContent="space-between"
      spacing={24}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap={{ xs: 36, md: 24 }}
        height={{ xs: 30, md: 36 }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        mt={{ xs: 12, md: 30 }}
        px={30}
        width={1 / 1}
      >
        <LimerickYouthServiceLogo height="100%"/>
        <YouthWorkIrelandLogoHorizontal height="100%"/>
      </Stack>
      <Stack
        direction="row"
        justifyContent={{ xs: 'center', md: 'space-between' }}
        width={1 / 1}
      >
        <Box
          px={{ xs: 30, sm: 60, lg: 120 }}
        >
          <Stack
            alignItems="center"
            height={1 / 1}
            justifyContent="center"
            maxWidth={300}
            position="relative"
            py={{ xs: 80, md: 80 }}
          >
            <Typography
              color={colors.accent.green[1]}
              fontSize={{ xs: 24, md: 32 }}
              fontWeight={700}
              left={0}
              position="absolute"
              top={0}
            >
              My Youth Club
            </Typography>
            {children}
          </Stack>
        </Box>
        <Stack
          alignItems="center"
          display={{ xs: 'none', md: 'flex' }}
          justifyContent="center"
        >
          <img
            src={image}
            style={{ width: '100%' }}
          />
        </Stack>
      </Stack>
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent={{ xs: 'center', md: 'space-between' }}
        padding={{ xs: '0 24px 12px', md: '0 24px 24px 140px' }}
        width={1 / 1}
      >
        <Stack
          alignItems="center"
          direction="row"
          display={{ xs: 'none', md: 'flex' }}
          gap={8}
        >
          <LCETBCorpIdMain/>
          <DCYA2020Logo/>
          <ThumbnailLotto/>
          <ThumbnailCombinedLogoGrantees/>
        </Stack>
        <Stack
          alignItems="flex-end"
          direction="row"
          gap={8}
        >
          <Typography
            fontSize={8}
          >
            Powered by:
          </Typography>
          <AwtgLogo/>
          <YouthServiceLogo/>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginNotAllowed;
