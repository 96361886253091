import React, { useMemo, useState } from 'react';
import { Box, FormControlLabel, FormGroup, Stack } from '@mui/material';

import Checkbox from '../Checkbox';
import Modal from '../Modal';
import ModalHeader from '../Modal/ModalHeader';
import Typography from '../Typography';
import ModalFooterButtons from '../Modal/ModalFooter';

import { useAppDispatch, useAppSelector } from '../../store/store';
import { confirmGDPR } from '../../api/user';
import { setUser } from '../../store/sagas/sagaActions';
import { SUPER_ADMIN_ROLE_NAME } from '../../constants/common';

import { colors } from '../../themes/colors';
import { AppType, Levels } from '../../enums/common';

const checkboxLabel = 'I agree that I have read and understood Limerick Youth Services policy on Data Protection ' +
  'and agree to abide by the guidelines';

const GDPRstatement = () => {
  const dispatch = useAppDispatch();
  const { profile, activeUserRight } = useAppSelector((state) => state.user) || {};

  const isSomeWebClubUserRight = useMemo(()=> {
    return profile?.userRights ?
      profile.userRights.some((userRight) => {
        return userRight.role.appType === AppType.WEB && userRight.organization.level === Levels.CLUB;
      }) :
      true;
  }, [activeUserRight?.id]);

  const [isOpened, setIsOpened] = useState(!profile?.isConfirmedGDPR &&
    activeUserRight?.role.name !== SUPER_ADMIN_ROLE_NAME &&
    isSomeWebClubUserRight);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = () => {
    setIsFetching(true);

    confirmGDPR().then(() => {
      if (activeUserRight?.id) dispatch(setUser(activeUserRight.id));
      setIsOpened(false);
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      setIsFetching(false);
    });
  };

  return (
    <Modal
      isOpened={isOpened}
      width={590}
    >
      <ModalHeader
        headerText="GDPR statement"
        showCloseButton={false}
      />
      <Stack
        padding="24px 32px"
        spacing={24}
      >
        <Typography variant="body1">
          Limerick Youth Service are committed to protecting and respecting your privacy rights and comply with our
          obligations under EU General Data Protection Regulation (GDPR, 2016) and the Data Protection Act 2018
        </Typography>
        <Typography variant="body1">
          We collect personal information including name, age, gender, contact details and medical information. This is
          necessary for operational, registration, attendance and safety purposes.
        </Typography>
        <Typography variant="body1">
          No personal data will be divulged to a third party, except in ways that are “compatible” with the specified
          purpose. Data shared with funding bodies will be anonymised and no personal information will be disclosed.
          Please be aware that under the Children's First Act 2015, we are obliged to report any concerns regarding
          child safeguarding to the relevant authority. We keep data in accordance with the guidance set out within our
          formal records retention policies.
        </Typography>
        <Typography variant="body1">
          Under the Irish Data Protection Acts and the EU General Data Protection Legislation (GDPR), 2016, you have a
          right to request a copy of personal information at any time. To exercise all relevant rights, queries or
          complaints please in the first instance contact: Gdpr-lys@limerickyouthservice.org
        </Typography>
        <FormGroup>
          <FormControlLabel
            checked={isConfirmed}
            componentsProps={{
              typography: {
                variant: 'body1',
                mt: 8,
                fontWeight: 500,
              },
            }}
            control={<Checkbox sx={{ mt: -8 }}/>}
            label={checkboxLabel}
            onChange={() => setIsConfirmed(prev => !prev)}
          />
        </FormGroup>
      </Stack>
      <Box
        bgcolor={colors.base.green[5]}
        padding={16}
      >
        <ModalFooterButtons
          isSubmitting={isFetching}
          positiveButtonDisabled={!isConfirmed}
          positiveButtonText="Submit"
          positiveHandler={onSubmit}
        />
      </Box>
    </Modal>
  );
};

export default GDPRstatement;
