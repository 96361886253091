import { client } from '../api/client';
import urls from '../constants/urls';

const { notifications } = urls;

interface GetNotificationsQueryParams {
  userRightId: number
  page: string | number
  size: string | number
}

interface ReadNotificationsPayloadData {
  userRightId: number
  notificationId: number
}

export const getNotifications = ({
  userRightId,
  page,
  size,
}: GetNotificationsQueryParams): Promise<any> => (
  client.get(notifications, {
    params: {
      userRightId,
      page,
      size,
    },
  }));

export const readNotification = ({ notificationId, userRightId }: ReadNotificationsPayloadData): Promise<any> => (
  client.put(`${notifications}/read`, null, {
    params: {
      notificationId,
      userRightId,
    },
  }));

export const getRedirectTypes = () => client.get(`${notifications}/redirect-types`);
