import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User, UserRight } from '../types/common';

interface InitialState {
  profile: User | null
  activeUserRight: UserRight | null
  additionalUserRightData: {
    missionStatement: string
    logoUrl: string
  },
  disabledSwitchUsers: boolean
}

const initialState: InitialState = {
  profile: null,
  activeUserRight: null,
  additionalUserRightData: {
    missionStatement: '',
    logoUrl: '',
  },
  disabledSwitchUsers: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getUser: () => {},
    setUser: (state, action: PayloadAction<User | null>) => {
      state.profile = action.payload;
    },
    setUserRights: (state, action: PayloadAction<UserRight[]>) => {
      if (state.profile) state.profile.userRights = action.payload;
    },
    setAdditionalUserRightData: (state, action: PayloadAction<{
      missionStatement: string
      logoUrl: string
    }>) => {
      state.additionalUserRightData = action.payload;
    },
    setActiveUserRight: (state, action: PayloadAction<UserRight | null>) => {
      state.activeUserRight = action.payload;
    },
    setDisableSwitchUsers: (state, action: PayloadAction<boolean>) => {
      state.disabledSwitchUsers = action.payload;
    },
    blockSwitchUsers: (state) => {
      if (state.disabledSwitchUsers) {
        state.disabledSwitchUsers = false;
      }
    },
  },
});

export const {
  getUser,
  setUser,
  setUserRights,
  setActiveUserRight,
  setAdditionalUserRightData,
  setDisableSwitchUsers,
  blockSwitchUsers,
} = userSlice.actions;

export default userSlice.reducer;
