import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, CircularProgress, ListItemButton, Stack, Tooltip } from '@mui/material';

import { CheckCircleIcon } from '../../components/icons/icons';
import Typography from '../../components/Typography';

import { colors } from '../../themes/colors';
import { useAppDispatch } from '../../store/store';
import { UserRight } from '../../types/common';
import routes from '../../constants/routes';
import { setUser } from '../../store/sagas/sagaActions';

interface AccountItemProps {
  accountName: string
  userRight: UserRight
  disabled?: boolean
  fetchingItemId: number | null
  role: string
  selected?: boolean
  setIsFetchingUserRightId: Dispatch<SetStateAction<number | null>>
  setIsOpen: (isOpen: boolean) => void
  tooltipTitle: string
}

const AccountItem = ({
  accountName,
  userRight,
  disabled,
  fetchingItemId,
  role,
  selected = false,
  setIsFetchingUserRightId,
  setIsOpen,
  tooltipTitle,
}: AccountItemProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const handleClose = () => {
    if (disabled) setOpen(false);
  };

  const handleOpen = () => {
    if (disabled) setOpen(true);
  };

  const onSwitchAccount = () => {
    if (!selected) {
      if (!disabled && !fetchingItemId) {
        dispatch(setUser(userRight.id));
        setIsFetchingUserRightId(userRight.id);
        setIsFetching(true);

        navigate(routes.home);
      }
    }
  };

  useEffect(() => {
    if (!fetchingItemId && isFetching) {
      setIsFetching(false);
      setIsOpen(false);
    }
  }, [fetchingItemId]);

  return (
    <Tooltip
      followCursor
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      placement="top"
      title={tooltipTitle}
    >
      <ListItemButton
        disabled={!!fetchingItemId}
        onClick={onSwitchAccount}
        sx={{
          display: 'grid',
          gap: '24px',
          gridTemplateColumns: '24px 180px minmax(10px, 1fr)',
          alignItems: 'center',
          padding: '10px 14px 10px 24px',
          backgroundColor: disabled ? colors.base.gray[7] : 'none',
          cursor: 'pointer',
          '&.Mui-disabled': {
            opacity: 1,
          },
        }}
      >
        <Box sx={{
          width: '24px',
          height: '24px',
        }}>
          {fetchingItemId && fetchingItemId === userRight.id
            ? (
              <CircularProgress
                size={20}
                sx={{ margin: 2 }}
              />
            )
            : selected && <CheckCircleIcon color={colors.accent.green[2]}/>}
        </Box>
        <Typography
          color={disabled || fetchingItemId ? colors.base.gray[8] : colors.base.gray[1]}
          fontWeight={500}
          variant={'body1'}
          withEllipsis
        >
          {accountName}
        </Typography>
        <Stack
          direction={'row'}
          justifyContent={'end'}>
          <Chip
            label={role}
            sx={{
              color: disabled || fetchingItemId ? colors.base.gray[8] : colors.base.gray[1],
              backgroundColor: colors.base.green[3],
              maxWidth: '100%',
              '.MuiChip-label': { padding: '0 10px' },
            }}
          />
        </Stack>
      </ListItemButton>
    </Tooltip>
  );
};

export default AccountItem;
