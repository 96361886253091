import React, { BaseSyntheticEvent, SyntheticEvent } from 'react';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import { TabContext } from '@mui/lab';
import { RemoveIcon, SearchIcon } from '../../../../components/icons/icons';
import OutlinedInput from '../../../../components/atoms/Inputs/OutlinedInput';
import Tabs from '../../../../components/Tabs';
import Tab from '../../../../components/Tab';
import Typography from '../../../Typography';

import { colors } from '../../../../themes/colors';
import { setSearchMode, setSearchValue } from '../../../../store/messengerSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { SearchMode } from '../../constants/enum';
import { changeSearchMode, fetchSearchChannelsList } from '../../../../store/sagas/sagaActions';

const SearchBox = () => {
  const dispatch = useAppDispatch();
  const {
    isSearchMode,
    searchValue,
    searchMode,
  } = useAppSelector((state) => state.messenger) || {};
  const onFieldChange = (e: BaseSyntheticEvent) => {
    const state = e.target.value;
    dispatch(setSearchValue(state));
    dispatch(fetchSearchChannelsList(state));
  };

  const handleChange = (event: SyntheticEvent, v: any) => {
    dispatch(changeSearchMode(v));
  };

  return (
    <Box>
      <Stack
        padding={16}
        spacing={16}
      >
        <Typography variant="h5">Messages</Typography>
        <OutlinedInput
          disabled={false}
          endAdornment={searchValue ?
            <IconButton onClick={() => dispatch(setSearchMode(false))}>
              <RemoveIcon/>
            </IconButton> : undefined
          }
          fullWidth
          onChange={onFieldChange}
          placeholder={'Search'}
          startAdornment={
            <IconButton
              disableRipple
              disabled
            >
              <SearchIcon color={isSearchMode ? colors.accent.green[1] : undefined}/>
            </IconButton>
          }
          sx={{ '&.MuiInputBase-root': { padding: 0 } }}
          value={searchValue}
        />
      </Stack>
      {isSearchMode &&
        <TabContext value={searchMode}>
          <Tabs
            onChange={handleChange}
            scrollButtons={false}
            sx={{ minHeight: 0 }}
            value={searchMode}
          >
            {Object.values(SearchMode).map((value) => {
              return (
                <Tab
                  key={value}
                  label={
                    <Typography
                      color={searchMode === value
                        ? colors.accent.green[1]
                        : colors.base.gray[3]}
                      fontWeight={500}
                      sx={{ fontSize: 14 }}
                    >
                      {value}
                    </Typography>
                  }
                  sx={{
                    padding: '0px 12px 8px',
                    minHeight: 0,
                    '&:first-of-type': { paddingLeft: 20 },
                    '&:last-of-type': { paddingRight: 20 },
                  }}
                  value={value}
                />);
            })
            }
          </Tabs>
        </TabContext>}
      {!isSearchMode && (<Divider flexItem/>)}

    </Box>
  );
};

export default SearchBox;
