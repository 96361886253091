import urls from '../constants/urls';
import { client } from './client';
import { EventCalendar, FullEvent } from '../types/events';
import { AvailableAttendee, ParticipantPayloadData } from '../types/events';
import { PublishFilePayload, UploadLinkData, UploadLinkDataResponse } from './files';
import { MembershipDocumentItem, ResponseWithPagination } from '../types/common';
import { userTypeMap } from '../components/FullEventItem/map/common';
import { AttendeeType } from '../enums/scheduling';

const { scheduler, files } = urls;

interface ManageSchedulingParticipant {
  eventId: string
  userRightId?: number
  payloadData: ParticipantPayloadData
}

export const getEventByDates = ({ from, to, userRightId }: {
  from: number
  to: number
  userRightId?: number
}): Promise<EventCalendar[]> =>
  client.get(scheduler, {
    params: {
      from,
      to,
      userRightId,
    },
  });

export const getFullCardEvent = ({ eventId, userRightId }: {
  eventId: string
  userRightId?: number
}): Promise<FullEvent> =>
  client.get(`${scheduler}/${eventId}`, {
    params: {
      userRightId,
    },
  });

export const getAvailableAttendees = ({ eventId, searchText, userRightId }: {
  eventId: string
  searchText?: string
  userRightId?: number
}): Promise<AvailableAttendee[]> => {
  const searchLine = searchText ? { searchLine: searchText } : {};
  return client.get(`${scheduler}/${eventId}/attendees/available`, {
    params: {
      userRightId,
      ...searchLine,
    },
  });
};

export const getAvailableAssistants = ({ eventId, searchText, userRightId }: {
  eventId: string
  searchText?: string
  userRightId?: number
}): Promise<AvailableAttendee[]> => {
  const searchLine = searchText ? { searchLine: searchText } : {};
  return client.get(`${scheduler}/${eventId}/assistants/available`, {
    params: {
      userRightId,
      ...searchLine,
    },
  });
};

export const searchByTitle = ({ searchText, userRightId }: {
  searchText?: string
  userRightId?: number
}): Promise<EventCalendar[]> => {
  return client.get(`${scheduler}/search`, {
    params: {
      userRightId,
      titleText: searchText,
    },
  });
};

export const bookAttendee = ({
  eventId,
  userRightId,
  payloadData,
}: ManageSchedulingParticipant) => client.post(`${scheduler}/${eventId}/attendees`,
  payloadData,
  {
    params: {
      userRightId,
    },
  });

export const bookAssistant = ({
  eventId,
  userRightId,
  payloadData,
}: ManageSchedulingParticipant) => client.post(`${scheduler}/${eventId}/assistants`,
  payloadData,
  {
    params: {
      userRightId,
    },
  });

export const cancelAttendee = ({
  eventId,
  userRightId,
  payloadData,
}: ManageSchedulingParticipant) => client.post(`${scheduler}/${eventId}/attendees/cancel`,
  payloadData,
  {
    params: {
      userRightId,
    },
  });

export const cancelAssistant = ({
  eventId,
  userRightId,
  payloadData,
}: ManageSchedulingParticipant) => client.post(`${scheduler}/${eventId}/assistants/cancel`,
  payloadData,
  {
    params: {
      userRightId,
    },
  });

export interface AttendeeUploadLink {
  payloadData: UploadLinkData
  userRightId: number
  eventId: string
  attendeeId: string | number
  type: AttendeeType
}

export const getAttendeeUploadLink = async ({
  payloadData,
  userRightId,
  eventId,
  type,
  attendeeId,
}: AttendeeUploadLink): Promise<UploadLinkDataResponse> => (
  client.post(`${files}/events/${userTypeMap[type]}`, payloadData, {
    params: {
      id: attendeeId,
      type,
      eventId,
      userRightId,
    },
  })
);

export const updateFileAttendee = (data: PublishFilePayload) => client.patch(`${files}/events`, data);

interface GetAttendeeFilesProps {
  memberId: string | number
  eventId: string
  page: number | string
  searchValue?: string
  size: number
  type: AttendeeType
  userRightId: number
}

export const getAttendeeFiles = ({
  memberId,
  eventId,
  page,
  searchValue,
  size,
  type,
  userRightId,
}: GetAttendeeFilesProps): Promise<ResponseWithPagination<MembershipDocumentItem>> => (
  client.get(`${files}/events/${userTypeMap[type]}`, {
    params: {
      id: memberId,
      type,
      eventId,
      page,
      size,
      searchValue,
      userRightId,
    },
  })
);
