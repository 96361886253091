import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { FileDirectory, YouthMemberRightStatus } from '../../../enums/common';
import { RootState } from '../../../store/store';
import { deleteYCM, joinCurrentClubToYCM, updateYCM } from '../../youthClubMemberSlice';
import { ClubRoleInformation } from '../../../forms/UserProfileForm/types';
import { getYouthClubMemberById } from '../../../api/user';
import { deleteFile, getFiles } from '../../../api/files';
import { DeleteItem, ResponseWithPagination, ResponseYCM } from '../../../types/common';
import { FileInResponse } from '../../../types/files';
import dataToValues, { ActualPGData } from '../../../forms/YouthClubMemberPagedForm/dataToValues';
import valuesToDataNewCreated, { ClubRightStatus } from '../../../forms/YouthClubMemberModalForm/valuesToDataModalForm';
import { ClientRightItem } from '../../../forms/ParentsGuardiansForm/parentsGuardianDefaultValues';

export function* handleUpdateYCM({
  payload: { ycmId, parentGuardianData, callback },
}: PayloadAction<{
  ycmId: string, parentGuardianData: ActualPGData & {
    createRightFormStateItems: ClientRightItem[]
  }, callback?: () => void
}>): Generator<CallEffect | PutEffect | SelectEffect, void, any> {
  const {
    user: { activeUserRight },
    pendedYCM: { list },
    metaData,
  }: RootState = yield select((state: RootState) => state);

  const { createRightFormStateItems, ...actualPGData } = parentGuardianData;

  const defaultPGRoleId = metaData.defaultPGRole?.id;
  // find our YCM
  const aimedYCM = list.find(el => el.id === ycmId);

  const rightFormStateItem = {
    initialCreated: false,
    status: YouthMemberRightStatus.ACTIVE,
    clubRoleInfo: {
      roleId: defaultPGRoleId,
      value: activeUserRight?.organization?.id || '',
      label: activeUserRight?.organization?.name || '',
      address: activeUserRight?.organization?.address,
      volunteerMinAge: activeUserRight?.organization?.volunteerMinAge,
      childMinAge: activeUserRight?.organization?.childMinAge ?? null,
      childMaxAge: activeUserRight?.organization?.childMaxAge ?? null,
    },
  };
  if (aimedYCM?.tabledData?.statusWithRightsExpanded && !aimedYCM?.formData?.saved && defaultPGRoleId) {
    try {
      const res: ResponseYCM = yield call(getYouthClubMemberById as any, {
        id: ycmId,
        userRightId: activeUserRight?.id,
      });

      const uploadFiles: ResponseWithPagination<FileInResponse> = yield call(getFiles, {
        directory: `${FileDirectory.YCM_DOCS}/${ycmId}`,
      });

      const saved = {
        ...dataToValues(res, actualPGData), //from server
        rightFormStateItems: aimedYCM.tabledData.statusWithRightsExpanded.reduce((ac, statusWithRight) => {
          if (!statusWithRight.deleted && !statusWithRight.yCMStatusWithRight.isDeleted &&
            statusWithRight.yCMStatusWithRight.status !== YouthMemberRightStatus.NOT_JOINED) {
            ac.push({
              clubRoleInfo: {
                roleId: statusWithRight.yCMStatusWithRight.userRight.role.id,
                address: statusWithRight.yCMStatusWithRight.userRight.organization.address,
                volunteerMinAge: statusWithRight.yCMStatusWithRight.userRight.organization.volunteerMinAge || null,
                childMinAge: statusWithRight.yCMStatusWithRight.userRight.organization.childMinAge,
                childMaxAge: statusWithRight.yCMStatusWithRight.userRight.organization.childMaxAge,
                value: statusWithRight.yCMStatusWithRight.userRight.organization.id,
                label: statusWithRight.yCMStatusWithRight.userRight.organization.name,
              },
              initialCreated: true,
              status: statusWithRight.newChangedStatus || statusWithRight.yCMStatusWithRight.status,
            });
          }

          return ac;
        }, [rightFormStateItem] as Array<{
          clubRoleInfo: ClubRoleInformation
          initialCreated?: boolean
          status?: YouthMemberRightStatus
        }>),
      };

      const savedData = valuesToDataNewCreated(saved, createRightFormStateItems);
      yield put(updateYCM({
        saved: savedData,
        uploadFilesData: uploadFiles.items.map(({ path, extension, size, fileName, title, customAttributes }: any) => ({
          id: path,
          tableFileData: {
            extension,
            size,
            fileName,
            title,
            expiresAt: customAttributes.expiryDate,
          },
          savedFileData: undefined,
          deleted: false,
        })),
        id: ycmId,
      }));

    } catch (e) {
      console.log(e);
    } finally {
      callback?.();
    }
  } else if (aimedYCM?.formData?.saved && defaultPGRoleId) {
    yield put(
      joinCurrentClubToYCM({
        id: ycmId,
        newClubRight: rightFormStateItem as ClubRightStatus,
      }),
    );
    callback?.();
  }
}

export function* handleRemoveYCM({
  payload,
}: PayloadAction<DeleteItem>):
  Generator<CallEffect | PutEffect | SelectEffect, void, any> {

  if (payload.isNewItem) {
    const {
      pendedYCM: { list },
    }: RootState = yield select((state: RootState) => state);

    const aimedYCM = list.find(el => el.id === payload.id);
    if (aimedYCM?.uploadFilesData.length) {
      yield call(deleteFile, aimedYCM?.uploadFilesData.reduce((acc: string[], el) => {
        if (!!el.savedFileData?.filePath) return ([...acc, el.savedFileData.filePath]);
        return acc;
      }, []));
    }
  }
  yield put(deleteYCM(payload));
}
