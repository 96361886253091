import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';

import { colors } from '../../../themes/colors';

import { LeftArrow, TimesIcon } from '../../icons/icons';
import styles from './ModalHeader.module.scss';

interface ModalHeaderProps {
  headerText: string
  onBackClick?: () => void
  onCloseClick?: () => void
  showBackButton?: boolean
  showCloseButton?: boolean
}

const ModalHeader = ({
  headerText,
  onCloseClick,
  onBackClick,
  showBackButton = false,
  showCloseButton = true,
}: ModalHeaderProps) => (
  <div className={styles.headerWrapper}>
    <Stack
      alignItems="center"
      direction="row"
    >
      {showBackButton && (
        <IconButton
          aria-label="back"
          onClick={onBackClick}
          sx={{
            color: colors.base.gray[1],
            mr: 8,
            ml: -8,
          }}
        >
          <LeftArrow/>
        </IconButton>
      )}
      {headerText}
    </Stack>
    {showCloseButton && (
      <IconButton
        aria-label="close modal"
        onClick={onCloseClick}
        sx={{ color: colors.base.gray[1] }}
      >
        <TimesIcon/>
      </IconButton>
    )}
  </div>
);

export default ModalHeader;
