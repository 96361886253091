import React, { useRef } from 'react';
import { Badge, IconButton, Popover, Stack } from '@mui/material';

import { iconButtonStyles } from '../../../components/Header/styles';
import { stackStyles } from '../../../components/Header/NotificationsPopover/NotificationsPopover';
import Messenger from '../../../components/Messenger';
import { MessengerIcon } from '../../../components/icons/icons';

import { setMessengerClosed, setMessengerOpened } from '../../../store/messengerSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import useDebounce from '../../../hooks/useDebounce';

const MessengerPopover = () => {
  const { messenger, user: { profile, activeUserRight } } = useAppSelector((state) => state) || {};
  const {
    isOpen,
    channels,
  } = messenger;

  const dispatch = useAppDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const allUnreadMessages = profile && activeUserRight ?
    channels.reduce((acc, currentChannel) => {
      const unreadMessagesCountCurrentChannel: number = currentChannel.messages?.filter((elt) => (elt.createdAt > currentChannel.participants.user?.lastReadAt || 0) &&
        elt.sentBy !== `${profile.id}/${activeUserRight.id}`).length || 0;
      return unreadMessagesCountCurrentChannel ? unreadMessagesCountCurrentChannel + acc : acc;
    }, 0) : 0;

  const debouncedCount = useDebounce(allUnreadMessages, 500);

  return (
    <>
      <IconButton
        ref={buttonRef}
        color="primary"
        onClick={() => dispatch(setMessengerOpened())}
        sx={iconButtonStyles}
      >
        <Stack sx={stackStyles}>
          <Badge
            badgeContent={debouncedCount}
            color="warning"
            max={999}
          >
            <MessengerIcon/>
          </Badge>
        </Stack>
      </IconButton>
      <Popover
        anchorEl={buttonRef?.current as any}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => dispatch(setMessengerClosed())}
        open={isOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Messenger/>
      </Popover>
    </>
  );
};

export default MessengerPopover;
