import { SagaIterator } from 'redux-saga';
import { all } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { handleGetUser, handleSetUser } from './handlers/user';
import { handleGetPermissions } from './handlers/permissions';
import { handleRemoveYCM, handleUpdateYCM } from './handlers/ycmManagement';
import { getUser } from '../userSlice';
import { getPermissions } from '../permissionsSlice';
import { FETCH_META, REMOVE_YCM_WITH_FILES, SET_USER, UPD_YCM_FORM_DATA_FROM_TAB } from '../sagas/actionTypes';
import { messengerSaga } from './messengerSaga';
import { handleGetMetaData } from './handlers/meta';

function* userSaga(): SagaIterator {
  all([
    yield takeLatest(getUser.type, handleGetUser),
    yield takeLatest(SET_USER, handleSetUser),
  ]);
}

function* permissionsSaga(): SagaIterator {
  yield takeLatest(getPermissions, handleGetPermissions);
}

function* metaSaga(): SagaIterator {
  yield takeLatest(FETCH_META, handleGetMetaData);
}

function* ycmSaga(): SagaIterator {
  yield takeLatest(UPD_YCM_FORM_DATA_FROM_TAB, handleUpdateYCM);
  yield takeLatest(REMOVE_YCM_WITH_FILES, handleRemoveYCM);
}

export function* rootSaga() {
  yield all([
    userSaga(),
    permissionsSaga(),
    messengerSaga(),
    metaSaga(),
    ycmSaga(),
  ]);
}
