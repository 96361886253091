import React, { forwardRef } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';

import { CheckedIcon, SquareCheckedIcon, SquareEmptyIcon } from '../icons/icons';

const Checkbox: React.FC<CheckboxProps> = forwardRef<any>((props, ref) => {
  return (
    <MuiCheckbox
      ref={ref}
      checkedIcon={<CheckedIcon/>}
      icon={<SquareEmptyIcon/>}
      indeterminateIcon={<SquareCheckedIcon/>}
      {...props}
    />
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
