import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const { REACT_APP_BASE_URL } = process.env;

export const client = axios.create({
  baseURL: REACT_APP_BASE_URL,
});

const reqHandler = (config: InternalAxiosRequestConfig) => {
  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${sessionStorage.getItem('currentUserToken')}`,
    },
  } as InternalAxiosRequestConfig;

  return newConfig;
};

const respHandler = (res: AxiosResponse) => res.data;

client.interceptors.request.use(reqHandler);
client.interceptors.response.use(respHandler);

