import React, { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';

import UserMenuPopover from './UserMenuPopover';
import GlobalSearch from '../GlobalSearch';
import NotificationsPopover from './NotificationsPopover';
import MessengerPopover from '../../components/Header/MessengerPopover';

import {  useAppSelector } from '../../store/store';
import useAccess from '../../hooks/useAccess';
import { SUPER_ADMIN_ROLE_NAME } from '../../constants/common';

interface HeaderProps {
  children?: ReactNode
}

const Header = ({
  children,
}: HeaderProps) => {
  const { user: { activeUserRight }, permissions: { permissionNames } } = useAppSelector((state) => state);

  const showGlobalSearch = useAccess(permissionNames?.GLOBAL_SEARCH_ON_WEB_APP);
  const accessUseMessenger = useAccess(permissionNames?.USE_MESSAGING);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent={children ? 'space-between' : 'flex-end'}
      p={8}
    >
      {children}
      {showGlobalSearch && (
        <Box marginX={8}>
          <GlobalSearch/>
        </Box>
      )}
      {activeUserRight?.role.name !== SUPER_ADMIN_ROLE_NAME && <NotificationsPopover/>}
      {accessUseMessenger && <MessengerPopover/>}
      <UserMenuPopover/>
    </Stack>
  );
};

export default Header;
