import { PaletteOptions } from '@mui/material/styles/createPalette';
import { colors } from './colors';

const palette: PaletteOptions = {
  background: {
    paper: colors.base.white[1],
    default: colors.base.green[4],
  },
  common: {
    black: colors.base.black[1],
    white: colors.base.white[1],
  },
  error: {
    main: colors.accent.pink[1],
  },
  grey: {
    400: colors.base.gray[6],
    500: colors.base.gray[5],
    700: colors.base.gray[4],
    800: colors.base.gray[3],
    900: colors.base.gray[1],
  },
  primary: {
    main: colors.accent.green[1],
    light: colors.base.green[2],
    contrastText: colors.base.white[1],
  },
  success: {
    main: colors.accent.green[2],
  },
  text: {
    primary: colors.base.gray[1],
    secondary: colors.base.gray[3],
    disabled: colors.base.gray[5],
  },
  warning: {
    main: colors.accent.orange[2],
    contrastText: colors.base.white[1],
  },
  secondary: {
    main: colors.base.white[1],
    contrastText: colors.accent.green[1],
  },
  info:{
    main: colors.accent.blue[2],
  },
};

export default palette;
