import React, { FC, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

import Navigation from '../Navigation';
import Sidebar from '../Sidebar';
import Header from '../Header';

import routes from '../../constants/routes';
import { matchAccesses } from '../../helpers/matchAccesses';
import { useAppSelector } from '../../store/store';
import { Access } from '../../types/common';
import GDPRstatement from '../GDPRstatement';

const NoMatch = () => {
  const navigate = useNavigate();
  navigate(routes.home);

  return null;
};

const Skeleton: FC = () => {

  const { activeUserRight } = useAppSelector((state) => state.user) || {};
  const currentPermissions = activeUserRight?.role.permissions || [];
  const level = activeUserRight?.organization.level || null;
  const { permissionNames } = useAppSelector((state) => state.permissions);

  const [accesses, setAccesses] = useState<Access[]>([]);

  useEffect(() => {
    if (currentPermissions.length && permissionNames && level) {
      setAccesses(matchAccesses({ currentPermissions, permissionNames, level }));
    }
  }, [activeUserRight]);

  return (
    <>
      <Stack
        direction="row"
        height="100vh"
      >
        <Sidebar>
          <Navigation accesses={accesses}/>
        </Sidebar>
        <Stack
          component={'main'}
          flex="1 0"
          overflow-y="auto"
        >
          <Header/>
          <Suspense fallback={null}>
            <Routes>
              {accesses
                .map(({ accessRoutes }) => (
                  accessRoutes.map(({ allowChildRoutes, route, Component }) => (
                    <Route
                      key={route}
                      element={<Suspense fallback={null}><Component/></Suspense>}
                      path={allowChildRoutes ? `${route}/*` : route}
                    />
                  ))
                ))}
              <Route
                element={<NoMatch/>}
                path="*"
              />
            </Routes>
          </Suspense>
        </Stack>
      </Stack>
      <GDPRstatement/>
    </>
  );
};

export default Skeleton;
