import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Browser } from '../enums/common';

interface InitialState {
  browser: Browser | null,
}

const initialState: InitialState = {
  browser: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setBrowser: (state, action: PayloadAction<Browser | null>) => {
      state.browser = action.payload;
    },
  },
});

export const { setBrowser } = appSlice.actions;

export default appSlice.reducer;
