import React, { memo } from 'react';
import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MUIColorType } from '../../../enums/common';

export interface ModalFooterButtonsProps {
  isSubmitting?: boolean
  negativeButtonText?: string
  negativeHandler?: () => void
  positiveButtonDisabled?: boolean
  positiveButtonColor?: MUIColorType
  positiveButtonText: string
  positiveHandler?: () => void
  showNegativeButton?: boolean
  showPositiveButton?: boolean
  spacing?: number
  positiveButtonType?: 'button' | 'submit' | 'reset'
}

const ModalFooterButtons = ({
  isSubmitting = false,
  negativeButtonText = 'Cancel',
  negativeHandler = () => {},
  positiveButtonDisabled = false,
  positiveButtonColor,
  positiveButtonText,
  positiveButtonType = 'button',
  positiveHandler = () => {},
  showNegativeButton = false,
  showPositiveButton = true,
  spacing = 16,
}: ModalFooterButtonsProps) => (
  <Stack
    alignSelf="flex-end"
    direction="row"
    justifyContent="flex-end"
    spacing={spacing}
  >
    {showNegativeButton && (
      <Button
        data-testid="negativeButton"
        onClick={negativeHandler}
        variant="outlined"
      >
        {negativeButtonText}
      </Button>
    )}
    {showPositiveButton && (
      <LoadingButton
        color={positiveButtonColor}
        data-testid="positiveButton"
        disabled={positiveButtonDisabled}
        loading={isSubmitting}
        onClick={positiveHandler}
        type={positiveButtonType}
        variant="contained"
      >
        {positiveButtonText}
      </LoadingButton>
    )}
  </Stack>
);

export default memo(ModalFooterButtons);
