import { createTheme } from '@mui/material';
import { LinkProps } from '@mui/material/Link';

import LinkBehavior from '../components/atoms/LinkBehavior/LinkBehavior';

import palette from './palette';
import typography from './typography';
import { colors } from './colors';

const theme = createTheme(
  {
    spacing: 1,
    palette,
    typography,
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButton: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
        styleOverrides: {
          root: {
            borderRadius: 4,
            minWidth: 84,
            height: 32,
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 'bold',
            '&.MuiButton-outlinedInfo:disabled, &.MuiButton-outlinedInfo:hover': {
              color: colors.base.blue[8],
              borderColor: colors.base.blue[8],
            },

            '&.MuiButton-outlinedError:disabled': {
              color: colors.base.pink[1],
              borderColor: colors.base.pink[1],
            },
            '&.MuiButton-containedError:disabled': {
              backgroundColor: colors.base.pink[1],
              color: colors.base.white[1],
            },
            '&.MuiButton-containedInfo:disabled': {
              backgroundColor: colors.base.gray[6],
              color: colors.base.white[1],
            },
            '&.MuiButton-outlinedPrimary:disabled, &.MuiButton-outlinedPrimary:hover': {
              color: colors.base.green[2],
              borderColor: colors.base.green[2],
            },
            '&.MuiButton-containedPrimary:disabled': {
              color: colors.base.white[1],
              backgroundColor: colors.base.gray[6],
            },
          },
          outlined: {
            border: '1px solid',
          },
          text: {
            height: 'max-content',
            width: 'max-content',
            minWidth: 'max-content',
            padding: 0,
          },
          containedSecondary: {
            '&:hover': {
              backgroundColor: colors.base.green[3],
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: colors.base.gray[1],
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: '145%',
            '& *': {
              fontSize: '12px !important',
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.45,
            width: 32,
            height: 32,
            color: colors.base.gray[1],
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            ...typography.caption,
            borderRadius: 4,
            borderColor: colors.base.gray[4],
            fontWeight: 400,
            height: 24,
          },
          colorPrimary: {
            backgroundColor: colors.base.green[3],
            color: colors.accent.green[2],
            fontWeight: 'bold',
          },
          deleteIcon: {
            marginLeft: -4,
            flexShrink: 0,
            color: colors.base.gray[4],
            height: 12,
            width: 12,
          },
          outlined: {
            backgroundColor: colors.base.white[1],
          },
          filled: {
            backgroundColor: colors.base.orange[1],
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              '-webkit-box-shadow': '0 0 0 100px white inset',
              '-webkit-text-fill-color': colors.base.black[1],
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.MuiAutocomplete-option.Mui-disabled': {
              cursor: 'auto',
              pointerEvents: 'auto',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              padding: 0,
            },
            '& .MuiAutocomplete-endAdornment': {
              '& .MuiAutocomplete-popupIndicator:not(.Mui-disabled) svg': {
                color: colors.base.green[1],
              },
              '& .MuiAutocomplete-clearIndicator svg': {
                color: colors.base.gray[6],
              },
              right: '6px!important',
              top: '50%!important',
              transform: 'translateY(-50%)',
            },
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            '& .MuiBadge-badge': {
              right: '50%',
              top: -4,
              transform: 'translate(100%, -50%)',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-disabled.Mui-checked': {
              color: colors.base.green[2],
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            marginBottom: '8px !important',
            backgroundColor: colors.base.gray[9],
            opacity: '90% !important',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '& .Mui-disabled.Mui-checked': {
              color: colors.base.green[2],
            },
            '& .MuiFormControlLabel-label.Mui-disabled': {
              color: colors.base.gray[1],
            },
          },
        },
      },
    },
  });

export default theme;
