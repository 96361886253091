import { useEffect, useState } from 'react';
import { useAppSelector } from '../store/store';
import { Levels } from '../enums/common';

const useAccess = (permissionName: string | undefined, availableLevels?: Levels[]) => {
  const [hasAccess, setHasAccess] = useState(false);
  const { activeUserRight } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (activeUserRight?.role?.permissions && permissionName) {
      const hasPermission = activeUserRight?.role.permissions.some(({ name }) => name === permissionName);

      if (availableLevels) {
        const hasLevel = availableLevels.some(level => level === activeUserRight.organization.level);
        if ((hasPermission && hasLevel) !== hasAccess) setHasAccess(hasPermission && hasLevel);
      } else {
        if (hasPermission !== hasAccess) setHasAccess(hasPermission);
      }
    }
  }, [activeUserRight, availableLevels, permissionName, hasAccess]);

  return hasAccess;
};

export default useAccess;
