import { SearchResponseKeys } from '../../enums/common';
import routes from '../../constants/routes';

export const searchEditRoutesMap = {
  [SearchResponseKeys.USER_PROFILES]: routes.administration.profiles.edit,
  [SearchResponseKeys.ROLES]: routes.administration.rolesAndPermissions.edit,
  [SearchResponseKeys.PARENTS_GUARDIANS]: routes.registration.parentsGuardians.edit,
  [SearchResponseKeys.YOUTH_CLUB_MEMBERS]: routes.registration.youthClubMembers.edit,
  [SearchResponseKeys.EVENTS]: routes.contentManagement.events.edit,
};

export const searchViewRoutesMap = {
  [SearchResponseKeys.USER_PROFILES]: routes.administration.profiles.view,
  [SearchResponseKeys.ROLES]: routes.administration.rolesAndPermissions.view,
  [SearchResponseKeys.PARENTS_GUARDIANS]: routes.registration.parentsGuardians.view,
  [SearchResponseKeys.YOUTH_CLUB_MEMBERS]: routes.registration.youthClubMembers.view,
  [SearchResponseKeys.EVENTS]: routes.contentManagement.events.view,
};

export const searchViewListRoutesMap = {
  [SearchResponseKeys.USER_PROFILES]: routes.administration.profiles.main,
  [SearchResponseKeys.ROLES]: routes.administration.rolesAndPermissions.main,
  [SearchResponseKeys.PARENTS_GUARDIANS]: routes.registration.parentsGuardians.main,
  [SearchResponseKeys.YOUTH_CLUB_MEMBERS]: routes.registration.youthClubMembers.main,
  [SearchResponseKeys.EVENTS]: routes.contentManagement.events.main,
};
