import React from 'react';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { colors } from '../../themes/colors';

interface TabsComponentProps extends TabsProps {
  currentTabHasError?: boolean
  currentTabHasWarning?: boolean
}
const Tabs = styled(({ currentTabHasError, currentTabHasWarning, ...restProps }: TabsComponentProps) => (
  <MuiTabs
    {...restProps}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    sx={{
      mx: -10,
      '& .MuiTabs-indicatorSpan': {
        backgroundColor: currentTabHasError
          ? colors.accent.pink[1]
          : currentTabHasWarning
            ? colors.accent.orange[1]
            : colors.accent.green[1],
      },
    }}
  />
))({
  position: 'relative',
  '&:after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    bottom: 1,
    width: 'calc(100% - 20px)',
    left: 10,
    height: 1,
    backgroundColor: colors.base.gray[5],
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px!important',
    zIndex: 10,
  },
  '& .MuiTabs-indicatorSpan': {
    width: 'calc(100% - 24px)',
    borderRadius: 2,
  },
});

export default Tabs;
