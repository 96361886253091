import { lazy } from 'react';
import navigationGroupNames from './navigationGroupNames';
import { Access } from '../types/common';
import routes from './routes';
import { Levels } from '../enums/common';

const Home = lazy(() => import('../routes/Home'));
const Reporting = lazy(() => import('../routes/Reporting'));
const SchedulingComponent = lazy(() => import('../routes/Scheduling'));
const OrganisationsAndClubs = lazy(() =>
  import('../routes/Administration/OrganisationsAndClubs/OrganisationsAndClubs'));
const Profiles = lazy(() => import('../routes/Administration/Profiles'));
const RolesAndPermissions = lazy(() => import('../routes/Administration/RolesAndPermissions'));
const ParentsGuardians = lazy(() => import('../routes/Registration/ParentsGuardians'));
const YouthClubMembers = lazy(() => import('../routes/Registration/YouthClubMembers/YouthClubMembers'));
const InformationPublishing = lazy(() => import('../routes/ContentManagement/InformationPublishing'));
const Events = lazy(() => import('../routes/ContentManagement/Events'));
const RenewMembership = lazy(() => import('../routes/Administration/RenewMembershipClubOrganizations'));

const accesses = (permissionNames: Record<string, string>): Access[] => ([
  {
    navigationGroupName: null,
    accessRoutes: [
      {
        name: 'Home',
        Component: Home,
        route: routes.home,
        permissionKeys: [permissionNames.VIEW_HOME_PAGE_ON_WEB_APP],
        levels: [Levels.COUNTRY, Levels.REGIONAL, Levels.CLUB],
        parentItem: true,
      },
    ],
  },
  {
    navigationGroupName: navigationGroupNames.scheduling,
    accessRoutes: [
      {
        name: 'Scheduling',
        Component: SchedulingComponent,
        route: routes.scheduling,
        permissionKeys: [permissionNames.VIEW_CALENDAR_MONTH_VIEW],
        levels: [Levels.COUNTRY, Levels.REGIONAL, Levels.CLUB],
      },
    ],
  },
  {
    navigationGroupName: navigationGroupNames.registration,
    accessRoutes: [
      {
        name: 'Youth Club members',
        Component: YouthClubMembers,
        route: routes.registration.youthClubMembers.main,
        allowChildRoutes: true,
        levels: [Levels.REGIONAL, Levels.CLUB],
        permissionKeys: [
          permissionNames.VIEW_LIST_OF_ACTIVE_ACCOUNTS_OF_YOUTH,
          permissionNames.VIEW_LIST_OF_DEACTIVE_ACCOUNTS_OF_YOUTH,
        ],
      },
      {
        name: 'Parents/Guardians',
        Component: ParentsGuardians,
        route: routes.registration.parentsGuardians.main,
        allowChildRoutes: true,
        levels: [Levels.REGIONAL, Levels.CLUB],
        permissionKeys: [
          permissionNames.VIEW_LIST_OF_ACTIVE_ACCOUNTS_OF_CLIENTS,
          permissionNames.VIEW_LIST_OF_RESTRICTED_ACCOUNTS_OF_CLIENTS,
          permissionNames.VIEW_LIST_OF_DEACTIVATED_ACCOUNTS_OF_CLIENTS,
        ],
      },
    ],
  },
  {
    navigationGroupName: navigationGroupNames.administration,
    accessRoutes: [
      {
        name: 'Roles and permissions',
        Component: RolesAndPermissions,
        route: routes.administration.rolesAndPermissions.main,
        allowChildRoutes: true,
        levels: [Levels.COUNTRY, Levels.REGIONAL],
        permissionKeys: [permissionNames.VIEW_LIST_OF_ROLES],
      },
      {
        name: 'User profiles',
        Component: Profiles,
        route: routes.administration.profiles.main,
        allowChildRoutes: true,
        levels: [Levels.COUNTRY, Levels.REGIONAL, Levels.CLUB],
        permissionKeys: [
          permissionNames.VIEW_A_LIST_OF_APPLICATION_REQUESTS,
          permissionNames.VIEW_A_LIST_OF_ACTIVE_ACCOUNTS_OF_USERS,
          permissionNames.VIEW_A_LIST_OF_REJECTED_REQUESTS,
          permissionNames.VIEW_A_LIST_OF_DEACTIVATE_ACCOUNTS_OF_USERS,
        ],
      },
      {
        name: 'Organisations/Clubs',
        Component: OrganisationsAndClubs,
        route: routes.administration.organisationsAndClubs.main,
        allowChildRoutes: true,
        levels: [Levels.COUNTRY, Levels.REGIONAL, Levels.CLUB],
        permissionKeys: [
          {
            name: permissionNames.VIEW_LIST_OF_ACTIVE_ORGANIZATIONS,
          },
          {
            name: permissionNames.VIEW_LIST_OF_INSURANCE_REQUESTS,
            specificLevels: [Levels.COUNTRY, Levels.REGIONAL],
          },
          {
            name: permissionNames.VIEW_LIST_OF_DEACTIVATED_ORGANIZATIONS,
            specificLevels: [Levels.COUNTRY, Levels.REGIONAL],
          },
          { name: permissionNames.VIEW_LIST_OF_PENDING_ORGANIZATIONS },
        ],
      },
      {
        name: 'Renew membership',
        Component: RenewMembership,
        route: routes.administration.renewMembershipClubOrganizations.main,
        allowChildRoutes: true,
        levels: [Levels.COUNTRY, Levels.REGIONAL],
        permissionKeys: [permissionNames.VIEW_A_LIST_OF_RENEW_MEMBERSHIP_REQUESTS],
      },
    ],
  },
  {
    navigationGroupName: navigationGroupNames.contentManagement,
    accessRoutes: [
      {
        name: 'Events',
        Component: Events,
        route: routes.contentManagement.events.main,
        allowChildRoutes: true,
        levels: [Levels.COUNTRY, Levels.REGIONAL, Levels.CLUB],
        permissionKeys: [permissionNames.VIEW_LIST_OF_EVENTS],
      },
      {
        name: 'Information publishing',
        Component: InformationPublishing,
        route: routes.contentManagement.informationPublishing,
        levels: [Levels.COUNTRY, Levels.REGIONAL, Levels.CLUB],
        permissionKeys: [permissionNames.VIEW_A_LIST_OF_PUBLISHED_DOCUMENTS_IN_THE_INFORMATION_PUBLISHING_TABLE],
      },
    ],
  },
  {
    navigationGroupName: null,
    accessRoutes: [
      {
        name: 'Reporting',
        Component: Reporting,
        route: routes.reporting,
        permissionKeys: [permissionNames.VIEW_LIST_OF_REPORTS],
        levels: [Levels.COUNTRY, Levels.REGIONAL, Levels.CLUB],
        parentItem: true,
      },
    ],
  },
]);

export default accesses;
