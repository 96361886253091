import React from 'react';
import { Divider, Stack } from '@mui/material';

import ChatBox from './components/ChatBox';
import SearchBox from './components/SearchBox';
import ChatsList from './components/ChatsList';

const Messenger = () => {

  return (
    <Stack
      direction="row"
      divider={<Divider
        flexItem
        orientation="vertical"
      />}
      height={500}
    >
      <Stack width={340}>
        <SearchBox/>
        <ChatsList/>
      </Stack>

      <ChatBox width={590}/>
    </Stack>
  );
};

export default Messenger;
