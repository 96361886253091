import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, ListItemButton, Stack, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';

import { colors } from '../../../../themes/colors';
import { appTypeMapFull } from '../../../../constants/appType';
import { getNotificationRoutes } from '../../../../constants/notificationRoute';
import { readNotification } from '../../../../api/notifications';
import { useAppSelector } from '../../../../store/store';
import { Notification } from '../../../../types/common';
import { NotificationsRedirect } from '../../../../enums/common';

interface NotificationsItemProps {
  notificationItem: Notification
  onClose: () => void
  refetch: () => void
}

const NotificationsItem = ({
  notificationItem,
  onClose,
  refetch,
}: NotificationsItemProps) => {
  const { title, body, createdAt, id, attributes: { source, link, data, eventFromMs } } = notificationItem;
  const { user: { activeUserRight }, permissions } = useAppSelector((state) => state) || {};
  const userRightId = activeUserRight?.id;
  const permissionsNames = permissions.permissionNames || null;

  const navigate = useNavigate();

  let bodyText = body;

  if (bodyText && eventFromMs) {
    bodyText = bodyText.replace('StartDateOfEvent', format(new Date(Number(eventFromMs)), 'dd.MM.yyyy'));
    bodyText = bodyText.replace('StartTime', format(new Date(Number(eventFromMs)), 'HH:mm'));
  }

  const onClick = () => {
    if (userRightId) readNotification({ userRightId, notificationId: id })
      .then(async () => {
        const notificationRoteItem = getNotificationRoutes(permissionsNames).find(item => item.redirect === link);
        const {
          mainRoute,
          mainRoutePermissionKey,
          alternativeRoute,
          searchParam = '',
          alternativeRoutePermissionKey,
          fallbackRoute,
          checkExistingFunction,
          redirect,
        } = notificationRoteItem || {};

        const preparedData = data ?
          (
            redirect === NotificationsRedirect.SCHEDULING_PAGE ||
            redirect === NotificationsRedirect.FULL_EVENT_CARD
          ) ? `${data}` : `/${data}`
          : '';
        let preparedRoute: string;

        if (checkExistingFunction && data) {
          try {
            await checkExistingFunction({ id: data, userRightId });

            const withMainRoutePermissionKey = activeUserRight?.role?.permissions?.some(
              (permission) => permission.name === mainRoutePermissionKey);

            const withAlternativeRoutePermissionKey = activeUserRight?.role?.permissions?.some(
              (permission) => permission.name === alternativeRoutePermissionKey);

            preparedRoute = mainRoutePermissionKey
              ? withMainRoutePermissionKey
                ? mainRoute + preparedData + searchParam
                : withAlternativeRoutePermissionKey
                  ? alternativeRoute + preparedData + searchParam
                  : fallbackRoute ?? ''
              : mainRoute + preparedData + searchParam;

          } catch (e) {
            preparedRoute = fallbackRoute ?? '';
          }
        } else {
          preparedRoute = mainRoute + preparedData + searchParam;
        }

        navigate(preparedRoute);

        refetch();
        onClose();
      })
      .catch((e) => console.error(e));
  };

  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        px: 16,
        py: 12,
        color: colors.accent.green[1],
        '&:hover': {
          bgcolor: colors.base.green[3],
          cursor: 'pointer',
        },
      }}
    >
      <Stack spacing={8}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={8}
        >
          <Box
            bgcolor={colors.accent.orange[1]}
            borderRadius="50%"
            height={8}
            width={8}
          />
          <Typography
            fontWeight={700}
            variant="body1"
          >
            {title}
          </Typography>
        </Stack>
        <Typography variant="body1">
          <Markdown>
            {bodyText}
          </Markdown>
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          divider={(
            <Box
              bgcolor={colors.base.gray[3]}
              borderRadius="50%"
              height={2}
              width={2}
            />)}
          spacing={8}
        >
          <Typography
            color={colors.base.gray[3]}
            variant="caption"
          >
            {appTypeMapFull[source]}
          </Typography>
          <Box>
            <Typography
              color={colors.base.gray[3]}
              variant="caption"
            >
              {format(new Date(createdAt), 'dd.MM.yyyy')}
            </Typography>
            <Typography
              color={colors.base.gray[3]}
              ml={10}
              variant="caption"
            >
              {format(new Date(createdAt), 'HH:mm')}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </ListItemButton>
  );
};

export default NotificationsItem;
