import { Access, PermissionItem } from '../types/common';
import accesses from '../constants/accesses';
import { Levels } from '../enums/common';

interface MatchAccessesProps {
  currentPermissions: PermissionItem[]
  permissionNames: Record<string, string>
  level: Levels
}

export const matchAccesses = ({ currentPermissions, permissionNames, level }: MatchAccessesProps) => {
  const allAccesses = accesses(permissionNames);
  return allAccesses.reduce((arr: Access[], { navigationGroupName, accessRoutes }) => {
    const filteredAccesses = accessRoutes.filter((item) => {
      const hasPermission = item.permissionKeys.some((permission) =>{
        if (typeof permission === 'string') {
          return currentPermissions.find(({ name: itemName }) => itemName === permission);
        } else if (currentPermissions.find(({ name: itemName }) => itemName === permission.name)) {
          return permission.specificLevels ? permission.specificLevels.some(v => v === level) : true;
        } else {
          return false;
        }
      });

      const hasLevel = item.levels.some(v => v === level);

      return hasPermission && hasLevel;
    });
    if (filteredAccesses.length) return [...arr, { navigationGroupName, accessRoutes: filteredAccesses }];
    return arr;
  }, []);
};
