import { styled } from '@mui/material/styles';
import MuiOutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

import { colors } from '../../../../themes/colors';
import typography from '../../../../themes/typography';

const OutlinedInput = styled(MuiOutlinedInput)<OutlinedInputProps>(() => ({
  backgroundColor: colors.base.white[1],
  ...typography.body1,
  height: 36,
  '& fieldset': {
    borderColor: colors.base.gray[6],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.base.gray[4],
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.base.gray[4],
    borderWidth: 1,
  },
  '&.Mui-disabled fieldset': {
    borderColor: `${colors.base.gray[6]}!important`,
  },
  '&.Mui-disabled': {
    backgroundColor: colors.base.white[2],
  },
  '.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    backgroundColor: colors.base.white[2],
  },
  '.Mui-disabled': {
    backgroundColor: colors.base.white[2],
    '-webkit-text-fill-color': colors.base.gray[1],
    '&::placeholder': {
      '-webkit-text-fill-color': colors.base.gray[5],
    },
  },
  '.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.accent.pink[1],
  },
  '& .MuiInputBase-input': {
    padding: 8,
    '&::placeholder': {
      color: colors.base.gray[5],
      opacity: 1,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.base.white[2],
      '-webkit-text-fill-color': colors.base.gray[1],
    },
  },
}));

export default OutlinedInput;
