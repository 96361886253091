export const SECOND_DURATION_MS = 1000;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const DAY_DURATION_MINUTES = HOURS_IN_DAY * MINUTES_IN_HOUR;
export const MINUTE_DURATION_MS = SECONDS_IN_MINUTE * SECOND_DURATION_MS;
export const DAY_DURATION_MS = DAY_DURATION_MINUTES * MINUTE_DURATION_MS;
export const TIME_STAMP_START = 1970;

export const currectLocationsOffset = new Date().getTimezoneOffset();

export const LAST_MONTH_INDEX = 11;
export const SUNDAY_INDEX = 6;
export const shortDays: string[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const monthNames: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
