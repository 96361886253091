import Keycloak from 'keycloak-js';

const { REACT_APP_KEYCLOAK_URL, REACT_APP_KEYCLOAK_REALM_NAME = '', REACT_APP_KEYCLOAK_CLIENT_ID = '' } = process.env;

const keycloakInstance = Keycloak({
  url: REACT_APP_KEYCLOAK_URL,
  realm: REACT_APP_KEYCLOAK_REALM_NAME,
  clientId: REACT_APP_KEYCLOAK_CLIENT_ID,
});

export default keycloakInstance;
