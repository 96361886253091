import { searchEditRoutesMap, searchViewRoutesMap, searchViewListRoutesMap } from '../routes';
import { GlobalSearchResponseValue } from '../../../api/common';

export const getPreparedPath = ({
  editAllowed,
  viewAllowed,
  key,
  id,
}: {
  editAllowed: boolean;
  viewAllowed: boolean;
  key: keyof GlobalSearchResponseValue;
  id?: string;
}): string => {
  let preparedRoute = '';

  if (editAllowed) {
    preparedRoute = searchEditRoutesMap[key];
  } else if (viewAllowed) {
    preparedRoute = searchViewRoutesMap[key];
  } else {
    return searchViewListRoutesMap[key];
  }

  if (id) {
    preparedRoute = `${preparedRoute}/${id}`;
  }

  return preparedRoute;
};
