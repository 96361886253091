import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import { setDefaultPGRole } from '../../metaDataSlice';
import { getDefaultPGRole } from '../../../api/roles';

export function* handleGetMetaData(): Generator<CallEffect | PutEffect, void, any> {
  try {
    const defaultPGRole = yield call(getDefaultPGRole);

    yield put(setDefaultPGRole(defaultPGRole));
  } catch (e) {
    console.log(e);
  }
}
