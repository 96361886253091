import { client } from '../api/client';
import urls from '../constants/urls';
import { ChatRoom, Message } from '../components/Messenger/types';
import { OrganizationItem, ResponseWithPagination } from '../types/common';
import { RoleItem } from '../types/roles';
import { Status, UserType } from '../enums/common';
import { OLD_MESSAGES_PORTION } from '../components/Messenger/constants/constants';

const { messages } = urls;

export const getAllUnreadMessages = () => client.get('all-unread');

export interface ChatUserRight {
  role: RoleItem
  organization: OrganizationItem
  userId: string
  userRightId: number
  username: string
  name: string
  firstName: string
  lastName: string
  email: string
  userType?: UserType
  status: Status
  clientStatus: string
  isDeleted?: boolean
}

export const getContactsList = (userRightId: number, searchValue?: string): Promise<Array<ChatUserRight>> => client.get('/rights/all-by-search', {
  params: {
    searchValue,
    userRightId,
  },
},
);

export const getChatsList = (userRightId: number, id: string): Promise<Array<ChatRoom>> => client.get('/chat-rooms', {
  params: {
    userRightId,
    userId: id,
  },
});

export const getChannelById = (chatRoomId: string): Promise<ChatRoom> => client.get('/chat-rooms/room', {
  params: {
    id: chatRoomId,
  },
});

export const getMessagesListByChatId = (chatId: string, page?: number, size?: number): Promise<ResponseWithPagination<Message>> => client.get(messages, {
  params: {
    id: chatId,
    page: page || 0,
    size: size || OLD_MESSAGES_PORTION,
  },
});

export const getPreviousMessagesListByLastReadAtOfChatId = (chatId: string, lastReadAt: number, size: number): Promise<ResponseWithPagination<Message>> => client.get(`${messages}/before-date`, {
  params: {
    id: chatId,
    date: lastReadAt,
    size,
  },
});

export const createChat = (
  createdById: string, participantsIds: Array<{ userRightId: number }>,
): Promise<ChatRoom> => client.post('chat-rooms/create', {
  participants: participantsIds,
  createdBy: createdById,
});

export const sendMessage = (data: {
  chatRoomId: string
  sentBy: string
  text: string
}): Promise<ChatRoom> => client.post(`${messages}/create`, data);

export const changeLastReadDate = (data: {
  chatRoomId: string
  participantId: string
  lastReadAt: number
}): Promise<{
  participantId: string
  lastReadAt: number
}> => client.patch('last-read-dates/update', data);
