import urls from '../constants/urls';
import { client } from './client';
import { DestinationItem, Region } from '../types/common';
import { AppType, SearchResponseKeys, Title } from '../enums/common';
import { EventStatus } from '../enums/scheduling';

const { clubs, destinations, search } = urls;

export const getAllDestinations = (userRightId: number): Promise<Array<DestinationItem>> => client.get(`${destinations}/all`, {
  params: {
    userRightId,
  },
});

export const getClubsByDestinationId = (destinationId: number): Promise<Array<Region>> =>
  client.get(`${clubs}/all`, {
    params: {
      destinationId,
    },
  });

interface GlobalSearchQueryParams {
  userRightId: number
  searchText: string
}

interface SearchProfileItem {
  id: string
  name: string
  surname: string
  title: Title
}

interface SearchRoleItem {
  id: string;
  name: string;
  appType: AppType;
}

export interface SearchEventItem {
  id: string;
  name: string;
  status: EventStatus
}

export interface GlobalSearchResponseValue {
  [SearchResponseKeys.EVENTS]: SearchEventItem[]
  [SearchResponseKeys.PARENTS_GUARDIANS]: SearchProfileItem[]
  [SearchResponseKeys.USER_PROFILES]: SearchProfileItem[]
  [SearchResponseKeys.ROLES]: SearchRoleItem[]
  [SearchResponseKeys.YOUTH_CLUB_MEMBERS]: SearchProfileItem[]
}

export const globalSearch = ({
  userRightId,
  searchText,
}: GlobalSearchQueryParams): Promise<GlobalSearchResponseValue> => client.get(search, {
  params: {
    userRightId,
    searchText,
  },
});
