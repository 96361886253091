import { User } from '../types/common';

interface Names {
  firstName?: string
  lastName?: string
  name?: string
  surname?: string
}

export const getStringAvatar = (user?: User | null): string => {
  let resultString = '';

  if (user?.firstName) {
    resultString = user.firstName.charAt(0);
  }

  if (user?.lastName) {
    resultString += user.lastName.charAt(0);
  }

  return resultString.toUpperCase();
};

export const getFullName = <T extends Names>(user?: T | null): string => {
  if (!user) return '';

  const firstPart = user.firstName || user.name || '';
  const secondPart = user.lastName || user.surname || '';

  if (firstPart && secondPart) {
    return `${firstPart} ${secondPart}`;
  }

  if (firstPart && !secondPart) return firstPart;
  if (!firstPart && secondPart) return secondPart;
  return '';
};
