import { call, CallEffect, put, PutEffect, SagaReturnType } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { setActiveUserRight, setUser, setUserRights, setAdditionalUserRightData } from '../../userSlice';
import { getUserInfo } from '../../../api/user';
import { UserRight } from '../../../types/common';
import { AppType, OrganizationStatus } from '../../../enums/common';
import { getOrganizationById } from '../../../api/organizations';
import { getDownloadUrl } from '../../../api/files';

type ResUserType = SagaReturnType<typeof getUserInfo>;
type ResOrganizationByIdType = SagaReturnType<typeof getOrganizationById>;
type ResLogo = SagaReturnType<typeof getDownloadUrl> | undefined;

export function* handleGetUser(): Generator<CallEffect | PutEffect, void, any> {
  try {
    const resUser: ResUserType = yield call(getUserInfo);

    const userRightsPrepared = resUser.userRights ? resUser.userRights
      ?.sort((a, b) => a.organization.name.localeCompare(b.organization.name))
      : [];

    let activeUserRight;
    //try to recover UserRightId
    const activeUserRightId = sessionStorage.getItem('activeUserRightId');

    if (activeUserRightId) {
      activeUserRight = userRightsPrepared?.find((userRight) => userRight.id === Number(activeUserRightId) &&
        userRight.organization.status !== OrganizationStatus.DEACTIVATED);
      if (!activeUserRight) activeUserRight = userRightsPrepared?.find((userRight) => {
        return userRight.role.appType === AppType.WEB &&
          userRight.organization.status !== OrganizationStatus.DEACTIVATED;
      });
    } else {
      activeUserRight = userRightsPrepared?.find((userRight) => {
        return userRight.role.appType === AppType.WEB &&
          userRight.organization.status !== OrganizationStatus.DEACTIVATED;
      });
    }

    if (activeUserRight?.organization.id) {
      const {
        missionStatement,
        logo = '',
      }: ResOrganizationByIdType = yield call(
        getOrganizationById,
        { id: activeUserRight.organization.id, userRightId: Number(activeUserRight.id) },
      );

      let logoUrl: ResLogo;
      if (logo) logoUrl = yield call(getDownloadUrl, logo);

      yield put(setAdditionalUserRightData({
        logoUrl: logoUrl ?? '',
        missionStatement: missionStatement ?? '',
      }));
    }

    yield put(setUser({ ...resUser, userRights: userRightsPrepared }));
    if (activeUserRight) yield put(setActiveUserRight(activeUserRight));
  } catch (e) {
    console.error(e);
  }
}

export function* handleSetUser({ payload: id }: PayloadAction<number>): Generator<CallEffect | PutEffect, void, any> {
  try {
    const resUser: ResUserType = yield call(getUserInfo);
    const userRightsPrepared: UserRight[] | undefined = resUser.userRights
      ?.filter((userRightItem) => userRightItem.role.appType === AppType.WEB)
      .sort((a, b) => a.organization.name.localeCompare(b.organization.name));

    const activeUserRight: UserRight | undefined = userRightsPrepared?.find(
      (userRight: UserRight) => userRight.id === id &&
        userRight.organization.status !== OrganizationStatus.DEACTIVATED) || userRightsPrepared?.find((userRight) => {
      return userRight.role.appType === AppType.WEB &&
        userRight.organization.status !== OrganizationStatus.DEACTIVATED;
    });

    if (activeUserRight?.organization.id) {
      const {
        missionStatement,
        logo = '',
      }: ResOrganizationByIdType = yield call(
        getOrganizationById,
        { id: activeUserRight.organization.id, userRightId: activeUserRight.id },
      );
      let logoUrl: ResLogo;
      if (logo) logoUrl = yield call(getDownloadUrl, logo);

      yield put(setAdditionalUserRightData({
        logoUrl: logoUrl ?? '',
        missionStatement: missionStatement ?? '',
      }));
    }

    yield put(setUserRights(userRightsPrepared || []));
    if (activeUserRight) yield put(setActiveUserRight(activeUserRight));
  } catch (e) {
    console.error(e);
  }
}
