import { colors } from './colors';

export const scrollStyles = {
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    height: 10,
    width: 7,
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
    margin: 4,
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: 3,
    backgroundColor: colors.base.gray[4],
    minHeight: 24,
    border: 4,
    borderLeft: 1,
    borderColor: colors.base.white[1],
  },
  '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
    backgroundColor: colors.base.gray[3],
  },
};
