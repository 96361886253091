import { createAction } from '@reduxjs/toolkit';
import {
  CHANGE_SEARCH_MODE,
  SELECT_CHANNEL,
  SELECT_NEW_CONTACT,
  FETCH_CHANNELS_LIST,
  FETCH_SEARCHED_CHANNELS_LIST,
  INITIALIZE_MESSENGER,
  SET_USER,
  CREATE_CHANNEL_SEND_MESSAGE,
  ADD_NEW_MESSAGE_IN_CHAT,
  ADD_NEW_CHANNEL,
  ADD_MORE_MESSAGES,
  SEND_READ_MESSAGES,
  FETCH_META,
  UPD_YCM_FORM_DATA_FROM_TAB,
  REMOVE_YCM_WITH_FILES,
} from './actionTypes';
import { SearchMode } from '../../components/Messenger/constants/enum';
import { LastReadDateDto, Message } from '../../components/Messenger/types';
import { ActualPGData } from '../../forms/YouthClubMemberPagedForm/dataToValues';
import { ClientRightItem } from '../../forms/ParentsGuardiansForm/parentsGuardianDefaultValues';
import { DeleteItem } from '../../types/common';

export const setUser = createAction<number>(SET_USER);

export const fetchChannelsList = createAction(FETCH_CHANNELS_LIST);
export const fetchSearchChannelsList = createAction<string | undefined>(FETCH_SEARCHED_CHANNELS_LIST);
export const initializeMessenger = createAction(INITIALIZE_MESSENGER);
export const changeSearchMode = createAction<SearchMode>(CHANGE_SEARCH_MODE);
export const selectChannel = createAction<string>(SELECT_CHANNEL);
export const selectNewContact = createAction<number>(SELECT_NEW_CONTACT);
export const createChannelSendMessage = createAction<string>(CREATE_CHANNEL_SEND_MESSAGE);
export const addNewMessage = createAction<Message>(ADD_NEW_MESSAGE_IN_CHAT);
export const addNewChannel = createAction<string>(ADD_NEW_CHANNEL);
export const addMoreMessages = createAction(ADD_MORE_MESSAGES);
export const sendReadMessage = createAction<LastReadDateDto>(SEND_READ_MESSAGES);
export const fetchMeta = createAction(FETCH_META);
export const updateYCMFormDataFromTab = createAction<{ ycmId: string, parentGuardianData: ActualPGData & {
  createRightFormStateItems: ClientRightItem[]
}, callback?: ()=>void }>(UPD_YCM_FORM_DATA_FROM_TAB);
export const removeYCMById = createAction<DeleteItem>(REMOVE_YCM_WITH_FILES);

