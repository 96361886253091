import { APP_NAME, USER_MODULE, MESSENGER_MODULE, META_MODULE, YCM_MODULE } from '../ducks/constants';

export const SET_USER = `${APP_NAME}/${USER_MODULE}/SET_USER`;
export const FETCH_CHANNELS_LIST = `${APP_NAME}/${MESSENGER_MODULE}/FETCH_CHANNELS_LIST`;
export const INITIALIZE_MESSENGER = `${APP_NAME}/${MESSENGER_MODULE}/INITIALIZE_MESSENGER`;
export const FETCH_SEARCHED_CHANNELS_LIST = `${APP_NAME}/${MESSENGER_MODULE}/FETCH_SEARCHED_CHANNELS_LIST`;
export const CHANGE_SEARCH_MODE = `${APP_NAME}/${MESSENGER_MODULE}/CHANGE_SEARCH_MODE`;
export const SELECT_CHANNEL = `${APP_NAME}/${MESSENGER_MODULE}/SELECT_CHANNEL`;
export const SELECT_NEW_CONTACT = `${APP_NAME}/${MESSENGER_MODULE}/SELECT_NEW_CONTACT`;
export const CREATE_CHANNEL_SEND_MESSAGE = `${APP_NAME}/${MESSENGER_MODULE}/CREATE_CHANNEL_SEND_MESSAGE`;
export const ADD_NEW_MESSAGE_IN_CHAT = `${APP_NAME}/${MESSENGER_MODULE}/ADD_NEW_MESSAGE_IN_CHAT`;
export const ADD_NEW_CHANNEL = `${APP_NAME}/${MESSENGER_MODULE}/ADD_NEW_CHANNEL`;
export const ADD_MORE_MESSAGES = `${APP_NAME}/${MESSENGER_MODULE}/ADD_MORE_MESSAGES`;
export const SEND_READ_MESSAGES = `${APP_NAME}/${MESSENGER_MODULE}/SEND_READ_MESSAGES`;
export const FETCH_META = `${APP_NAME}/${META_MODULE}/FETCH_META`;
export const UPD_YCM_FORM_DATA_FROM_TAB = `${APP_NAME}/${YCM_MODULE}/UPD_YCM_FORM_DATA_FROM_TAB`;
export const REMOVE_YCM_WITH_FILES = `${APP_NAME}/${YCM_MODULE}/REMOVE_YCM_WITH_FILES`;

