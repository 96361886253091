import React from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';

import ContactCard from '../../components/ContactCard';

import { SearchMode } from '../../constants/enum';
import { NO_CHATS, NO_GLOBAL_CHATS, SEARCH_TITLE } from '../../constants/constants';
import { useAppSelector } from '../../../../store/store';
import { colors } from '../../../../themes/colors';
import { caseInsensetiveSearch } from '../../../../utils/commonUtils';
import { SeachedContactCard } from '../../types';
import { scrollStyles } from '../../../../themes/scrollStyles';

const SearchedList = ({ isSearching, isSearchMode, searchedChannels }: {
  isSearchMode?: boolean
  isSearching: boolean
  searchedChannels: SeachedContactCard[]
}) => {
  if (isSearching) return (
    <Typography
      alignSelf="center"
      color={colors.base.gray[4]}
      fontWeight={700}
      mt={20}
      variant="subtitle1"
    >
      <CircularProgress
        size={16}
        sx={{ margin: 2 }}
      />
    </Typography>
  );

  return (
    isSearchMode && searchedChannels.length ?
      <>
        {searchedChannels.map((data, i) => {
          return (
            <ContactCard
              key={i}
              cardData={data}
              disabled={false}
              isActive={false}
              searchedId={data.userRightId}
            />);
        })}
      </>
      :
      <Typography
        alignSelf="center"
        color={colors.base.gray[4]}
        fontWeight={700}
        mt={20}
        variant="subtitle1"
      >
        {NO_GLOBAL_CHATS}
      </Typography>
  );
};

const ChatsList = () => {
  const {
    messenger: {
      isSearchMode,
      isSearching,
      channels,
      searchMode,
      searchValue,
      searchedChannels,
      newActiveChannelId,
      activeChatId,
    },
    user: {
      activeUserRight,
      profile,
    },
  } = useAppSelector((state) => state) || {};
  let contacts = channels;
  let noChatsMessage = SEARCH_TITLE;

  if (isSearchMode) {
    if (searchMode === SearchMode.Chat) {
      contacts = channels.filter((el) => {
        const interlocutor: Record<string, any> = el.participants.member;
        return ['name', 'surname', 'organization', 'role'].some((key) => caseInsensetiveSearch(interlocutor[key], searchValue.trim()));
      });
      noChatsMessage = NO_CHATS;
    }
    if (searchMode === SearchMode.Global) {
      noChatsMessage = NO_GLOBAL_CHATS;
    }
  }

  const newActiveChannel = searchedChannels.find(el => el.userRightId === newActiveChannelId);

  return (
    <Stack
      flexGrow={1}
      sx={{
        overflowY: 'auto',
        ...scrollStyles,
      }}
    >
      {newActiveChannel && (
        <ContactCard
          key={newActiveChannelId}
          cardData={newActiveChannel}
          disabled={false}
          isActive
        />
      )}
      {
        isSearchMode && (searchMode === SearchMode.Global) ? (
          <SearchedList
            isSearchMode={isSearchMode}
            isSearching={isSearching}
            searchedChannels={searchedChannels}
          />
        ) : (
          contacts.length ?
            [...contacts]
              .sort((a, b) => ((b.messages?.[0]?.createdAt || 0) - (a.messages?.[0]?.createdAt || 0)))
              .map((data) => {
                const {
                  id, messages, participants,
                } = data;

                const myId = `${profile?.id}/${activeUserRight?.id}`;
                const unreadMessagesCount = messages?.filter((el) => (el.createdAt > participants.user?.lastReadAt || 0) && el.sentBy !== myId).length || 0;
                const interlocutor = participants.member;

                return (
                  <ContactCard
                    key={id}
                    cardData={{
                      name: interlocutor?.name,
                      organization: interlocutor?.organization,
                      role: interlocutor?.role,
                      surname: interlocutor?.surname,
                    }}
                    disabled={interlocutor.isDeleted}
                    id={id}
                    isActive={activeChatId === id}
                    messages={messages}
                    unreadMessgesCount={unreadMessagesCount}
                  />);
              })
            :
            <Typography
              alignSelf="center"
              color={colors.base.gray[4]}
              fontWeight={700}
              mt={20}
              variant="subtitle1"
            >
              {noChatsMessage}
            </Typography>
        )
      }
    </Stack>
  );
};

export default ChatsList;
