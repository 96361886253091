import React, { ReactNode } from 'react';
import { Typography as TypographyMUI, TypographyProps } from '@mui/material';

interface TypographyOwnProps {
  children: ReactNode
  withEllipsis?: boolean
}

const Typography = ({ children, withEllipsis = false, ...restProps }: TypographyOwnProps & TypographyProps) => {

  return (
    <TypographyMUI
      overflow={withEllipsis ? 'hidden' : 'inherit'}
      sx={{ userSelect: 'none' }}
      textOverflow={withEllipsis ? 'ellipsis' : 'inherit'}
      variant='subtitle1'
      whiteSpace={withEllipsis ? 'nowrap' : 'normal'}
      {...restProps}
    >
          {children}
    </TypographyMUI>
  );
};

export default Typography;
