import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { Logo, LogoAWTG, LogoLimerick } from '../icons/icons';
import { colors } from '../../themes/colors';
import theme from '../../themes/rootTheme';
import { useAppSelector } from '../../store/store';

interface SidebarProps {
  children: ReactNode
}

const Sidebar = ({ children }: SidebarProps) => {
  const {
    additionalUserRightData: { logoUrl },
    activeUserRight,
  } = useAppSelector((state) => state.user);

  const [imageIsNotAvailable, setImageIsNotAvailable] = useState(false);

  useEffect(() => {
    setImageIsNotAvailable(false);
  }, [activeUserRight?.id]);

  return (
    <Stack
      bgcolor={colors.base.green[4]}
      boxShadow={theme.shadows[1]}
      justifyContent="space-between"
      pb={10}
      pt={16}
      px={8}
      width={250}
    >
      <Box>
        {!!logoUrl && (
          <Box
            height={50}
            px={16}
            width={200}
          >
            <img
              alt="Logo was not uploaded"
              onError={() => setImageIsNotAvailable(true)}
              src={logoUrl}
              style={{
                display: imageIsNotAvailable ? 'none' : 'block',
                objectFit: 'cover',
                width: 200,
                height: 50,
              }}
            />
          </Box>
        )}
        {children}
      </Box>
      <Stack
        direction="row"
        px={16}
        spacing={16}
      >
        <Logo/>
        <LogoLimerick/>
        <LogoAWTG/>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
