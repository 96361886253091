import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { START_SEARCH_COUNT } from '../components/Messenger/constants/constants';
import { SearchMode } from '../components/Messenger/constants/enum';
import { ChatRoom, LastReadDateDto, Message, SeachedContactCard } from '../components/Messenger/types';

interface InitialState {
  isOpen: boolean
  isSearchMode: boolean
  searchValue: string
  channels: Array<ChatRoom>
  activeChatId: null | string //id of ChatRoom
  searchMode: SearchMode
  searchedChannels: Array<SeachedContactCard>
  isSearching: boolean
  newActiveChannelId: null | number //userRightId of selected SeachedContactCard
  messengerError: string
  oldestMessageId: null | string
}

const initialState: InitialState = {
  isOpen: false,
  isSearchMode: false,
  searchValue: '',
  channels: [], // set here all gotten channels for logined userRight
  activeChatId: null, // set here active chosen channel
  searchMode: SearchMode.Chat,
  searchedChannels: [],
  isSearching: false,
  newActiveChannelId: null,
  messengerError: '',
  oldestMessageId: null,
};

const messengerSlice = createSlice({
  name: 'messenger',
  initialState: initialState,
  reducers: {
    setMessengerOpened: (state) => {
      state.isOpen = true;
    },
    setMessengerClosed: (state) => {
      state.isOpen = false;
    },
    setSearchMode: (state, { payload: value }: PayloadAction<boolean>) => {
      if (!value && state.searchValue) {
        state.searchValue = '';
      }
      state.isSearchMode = value;
    },
    setSearchValue: (state, { payload: value }: PayloadAction<string>) => {
      if (value.length >= START_SEARCH_COUNT && !state.isSearchMode) state.isSearchMode = true;
      if (value.length < START_SEARCH_COUNT && state.isSearchMode) state.isSearchMode = false;
      state.searchValue = value;
    },
    switchSearchMode: (state, { payload: value }: PayloadAction<SearchMode>) => {
      state.searchMode = value;
    },
    resetMessenger: () => initialState,
    setChannels: (state, { payload: channels }: PayloadAction<Array<ChatRoom>>) => {
      state.channels = channels;
    },
    setSearchListChannels: (state, { payload }: PayloadAction<Array<SeachedContactCard>>) => {
      state.searchedChannels = payload;
    },
    setIsSearching: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearching = payload;
    },
    setActiveChannel: (state, { payload }: PayloadAction<string | null>) => {
      state.activeChatId = payload;
    },
    setNewActiveChannel: (state, { payload }: PayloadAction<number | null>) => {
      state.newActiveChannelId = payload;
    },
    addNewMessageToChannel: (state, { payload: message }: PayloadAction<Message>) => {
      const chatIndex = state.channels.findIndex(({ id }) => id === message.chatRoomId);
      const newArrMessages = state.channels[chatIndex].messages || [];
      newArrMessages.unshift(message);

      state.channels[chatIndex].messages = newArrMessages;
    },
    setLastReadAtToChannel: (state, {
      payload: readDateObj,
    }: PayloadAction<LastReadDateDto>) => {
      const chatIndex = state.channels.findIndex(({ id }) => id === readDateObj.chatRoomId);

      if (readDateObj.participantId === state.channels[chatIndex].participants.user.userId) {
        state.channels[chatIndex].participants.user.lastReadAt = readDateObj.lastReadAt;
      } else if (readDateObj.participantId === state.channels[chatIndex].participants.member.userId) {
        state.channels[chatIndex].participants.member.lastReadAt = readDateObj.lastReadAt;
      }
    },
    setMessengerError: (state, { payload: messengerError }: PayloadAction<string>) => {
      state.messengerError = messengerError;
    },
    setOldestMessageId: (state, { payload: oldestMessageId }: PayloadAction<string | null>) => {
      state.oldestMessageId = oldestMessageId;
    },
    setOldMessagesChannel: (state, { payload: { chatIndex, oldPackMessages } }: PayloadAction<{
      chatIndex: number
      oldPackMessages: Array<Message>
    }>) => {
      const messages = state.channels[chatIndex].messages || [];
      state.channels[chatIndex].messages = [...messages, ...oldPackMessages];
    },
  },
});

export const {
  setMessengerOpened,
  setMessengerClosed,
  setSearchMode,
  setSearchValue,
  setIsSearching,
  switchSearchMode,
  resetMessenger,
  setChannels,
  setSearchListChannels,
  setActiveChannel,
  setNewActiveChannel,
  addNewMessageToChannel,
  setLastReadAtToChannel,
  setMessengerError,
  setOldMessagesChannel,
  setOldestMessageId,
} = messengerSlice.actions;

export default messengerSlice.reducer;
