import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { colors } from '../../themes/colors';
import typography from '../../themes/typography';

const InputInSelect = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: colors.base.white[1],
    ...typography.body1,
    height: 36,
    padding: 0,
    '&.MuiInputBase-adornedEnd': {
      paddingRight: 30,
    },
    '& .MuiAutocomplete-input': {
      padding: 8,
      '&::placeholder': {
        color: colors.base.gray[5],
        opacity: 1,
      },
    },
    '& fieldset': {
      borderColor: colors.base.gray[6],
    },
    '&:hover fieldset': {
      borderColor: colors.base.gray[4],
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.base.gray[4],
      borderWidth: 1,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.base.white[2],
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.base.gray[6],
      },
    },
    '.Mui-disabled': {
      '-webkit-text-fill-color': colors.base.gray[1],
      '&::placeholder': {
        '-webkit-text-fill-color': colors.base.gray[5],
      },
    },
    '.Mui-disabled fieldset': {
      backgroundColor: colors.base.white[2],
    },
    '.Mui-error fieldset': {
      borderColor: colors.accent.pink[1],
    },
  },
}));

export default InputInSelect;
