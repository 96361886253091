import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { colors } from '../../themes/colors';

interface TextHighlightProps {
  highlightText: string
  wholeText: string
}

const TextHighlight = ({ highlightText = '', wholeText = '' }: TextHighlightProps) => {
  if (!highlightText) return (<>{wholeText}</>);

  const matches = match(wholeText, highlightText, { insideWords: true });
  const parts = parse(wholeText, matches);
  
  return (
    <>
      {parts.map(({ text, highlight }, index) => (
        highlight
          ? (
            <span
              key={`${text}-${index}`}
              style={{
                backgroundColor: colors.base.green[1],
                borderRadius: 2,
              }}
            >
              {text}
            </span>)
          : text))}
    </>
  );
};

export default TextHighlight;
