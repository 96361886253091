import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { IconButton, Stack } from '@mui/material';

import OutlinedTextField from '../../../../components/atoms/Inputs/OutlinedTextField';
import { SendIcon } from '../../../../components/icons/icons';

import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { colors } from '../../../../themes/colors';
import { MAX_MESSAGE_LENGTH, WRONG_SEND_MESSAGE } from '../../constants/constants';
import { createChat, sendMessage } from '../../../../api/messenger';
import {
  setActiveChannel,
  setChannels,
  setMessengerError,
  setNewActiveChannel,
} from '../../../../store/messengerSlice';
import { HIDE_DURATION_MS } from '../../../../constants/common';
import { createChannelSendMessage } from '../../../../store/sagas/sagaActions';

const SendMessageArea = () => {

  const {
    messenger: { activeChatId, newActiveChannelId, channels },
    user: { activeUserRight, profile },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [messageText, setMessageText] = useState('');

  const inputFormatRules = (input: string) => {
    if (input === ' ' || input === '\n') return null;
    return input;
  };

  const onChange = (e: BaseSyntheticEvent) => {
    if (inputFormatRules(e.target.value) === null) return;
    if (e.target.value.length > MAX_MESSAGE_LENGTH) return;
    setMessageText(e.target.value);
  };

  useEffect(() => {
    if (activeChatId && messageText) setMessageText('');
  }, [activeChatId]);

  const onSendMessage = async () => {
    // TODO: recheck saga instead// dispatch(createChannelSendMessage(messageText));
    if (!profile?.id || !activeUserRight) return;

    try {
      const createdById = `${profile.id}/${activeUserRight.id}`;
      if (newActiveChannelId) {
        const recipientId = newActiveChannelId;
        const newChannel = await createChat(createdById, [
          { userRightId: activeUserRight?.id },
          { userRightId: recipientId },
        ]);
        dispatch(setNewActiveChannel(null));
        dispatch(setChannels([newChannel, ...channels]));
        dispatch(setActiveChannel(newChannel.id));

        await sendMessage({
          chatRoomId: newChannel.id,
          sentBy: createdById,
          text: messageText,
        });
      } else if (activeChatId) {
        await sendMessage({
          chatRoomId: activeChatId,
          sentBy: createdById,
          text: messageText,
        });
      }
      setMessageText('');
    } catch (e) {
      dispatch(setMessengerError(WRONG_SEND_MESSAGE));
      setTimeout(() => dispatch(setMessengerError('')), HIDE_DURATION_MS);
    }
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={8}
      padding="16px"
    >
      <OutlinedTextField
        fullWidth
        maxRows={3}
        multiline
        onChange={onChange}
        onKeyDown={(e) => {
          if (!e.shiftKey && e.key === 'Enter') {
            e.preventDefault();
            onSendMessage();
          }
        }}
        placeholder="Write a message..."
        value={messageText}
      />
      <IconButton
        disabled={!messageText}
        onClick={onSendMessage}
      >
        <SendIcon color={messageText ? colors.accent.green[1] : colors.base.gray[4]}/>
      </IconButton>
    </Stack>
  );
};

export default SendMessageArea;
