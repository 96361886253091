import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Divider, Fab, Stack } from '@mui/material';
import { isSameDay, isSameMinute } from 'date-fns';

import MessageItem from '../../components/MessageItem/MessageItem';
import Typography from '../../../../components/Typography';
import { ChevronDownIcon } from '../../../../components/icons/icons';

import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { NO_MESSAGES } from '../../constants/constants';
import { colors } from '../../../../themes/colors';
import { getLabelStringFromDateTimeMessage } from '../../../../utils/timeUtils';
import { Message } from '../../types';
import { addMoreMessages, sendReadMessage } from '../../../../store/sagas/sagaActions';
import { scrollStyles } from '../../../../themes/scrollStyles';

const ChatContent = () => {
  const {
    messenger: { activeChatId, channels, oldestMessageId },
    user: { activeUserRight, profile },
  } = useAppSelector((state) => state) || {};

  const currentChat = channels.find(el => el.id === activeChatId);

  const listMessages = currentChat?.messages || [];

  const dispatch = useAppDispatch();

  const messagesListNode = useRef<HTMLElement>(null);
  const [positionOnListY, setPositionOnListY] = useState<null | number>(null);
  const [isShownScrollBtn, setIsShownScrollBtn] = useState(false);

  const delayForSetOnScroll = 100;
  const assumedLastDiapason = 20;

  const reversedList = [...listMessages].reverse();

  const onScrollHandler = useCallback(({ currentTarget }) => {
    setPositionOnListY(currentTarget.scrollTop + currentTarget.clientHeight);

    if (currentTarget.scrollTop === 0 &&
      currentTarget.clientHeight !== currentTarget.scrollHeight) {
      dispatch(addMoreMessages());
    }
  }, [messagesListNode]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (messagesListNode?.current) {
        messagesListNode.current?.addEventListener('scroll', onScrollHandler, false);
      }
    }, delayForSetOnScroll);
    return () => clearTimeout(timeout);

  }, []);

  useEffect(() => {
    if (messagesListNode?.current?.clientHeight) setPositionOnListY(messagesListNode?.current?.clientHeight);
  }, [messagesListNode?.current, activeChatId]);

  const handleScrollChatBottom = useCallback(() => {
    if (messagesListNode?.current) {
      const { scrollHeight, clientHeight } = messagesListNode?.current || {};
      messagesListNode.current.scrollTop = scrollHeight - clientHeight;
      setPositionOnListY(scrollHeight);
    }
  }, [messagesListNode]);

  useEffect(() => {
    const value = messagesListNode.current?.scrollHeight && positionOnListY &&
      (messagesListNode.current.scrollHeight - positionOnListY > assumedLastDiapason);

    setIsShownScrollBtn(!!value);
  }, [
    messagesListNode?.current,
    positionOnListY,
    activeChatId,
    reversedList.length,
  ]);

  const myId = profile && activeUserRight ? `${profile?.id}/${activeUserRight?.id}` : '';

  const scrollChatTo = useCallback((value: number) => {
    if (messagesListNode?.current) {
      messagesListNode.current.scrollTop = value;
      setPositionOnListY(value + messagesListNode?.current.clientHeight);
    }
  }, [messagesListNode]);

  useEffect(() => {
    if (!messagesListNode?.current) return;
    const unreadMessages = reversedList.filter(el => el.sentBy !== myId && (el.createdAt > (currentChat?.participants?.user?.lastReadAt || 0)));

    const lastSentMessage = listMessages[0];
    //CHECK LIST
    // the scroll button should be displayed correctly depending on the position of the chat
    // the chat should not move in place when adding with new incoming messages if there is a scroll button

    // case1: chat should scroll down when replenished with new incoming messages if there is no scroll button
    // case2: the chat should scroll down when replenished with new outgoing messages, regardless of the scroll button
    // case3: chat should scroll to my last message if there is no inbox (on opening)
    // case4: chat should scroll to the first unread incoming message message if exist (when opened) and read those that are visible in the area

    const case1 = messagesListNode.current && !isShownScrollBtn && currentChat && unreadMessages.length === 1;
    const case2 = messagesListNode.current && currentChat && lastSentMessage && (lastSentMessage.sentBy === myId);
    const case3 = currentChat ? reversedList.find(el => (el.sentBy !== myId) && (el.createdAt > currentChat.participants.user.lastReadAt)) : undefined;
    const case4 = messagesListNode.current && currentChat && unreadMessages.length > 1;

    if (case1 || case2 || (!case3 && !oldestMessageId)) {
      handleScrollChatBottom();
    } else if (case4 && !isShownScrollBtn) {
      const index = reversedList
        .findIndex(el => el.sentBy !== myId && (el.createdAt > (currentChat?.participants?.user?.lastReadAt || 0)));
      const firstElement: any = messagesListNode?.current.childNodes[0].childNodes[index];

      if (firstElement?.offsetTop) scrollChatTo(firstElement.offsetTop);
    }
    if (oldestMessageId) {
      const index = reversedList.findIndex(el => el.id === oldestMessageId);
      if (!index) return;
      const firstElement: any = messagesListNode?.current?.childNodes[0].childNodes[index];

      if (firstElement?.offsetTop) scrollChatTo(firstElement.offsetTop);
    }
  }, [activeChatId, messagesListNode, reversedList
    .length]);

  const isAnyUnreadedMessage = useMemo(() => {
    if (!currentChat || !currentChat?.participants?.user?.lastReadAt || !listMessages.length) return;
    return listMessages.find((el) => (el.sentBy !== myId) && el.createdAt > (currentChat.participants.user.lastReadAt || 0));
  }, [listMessages.length, currentChat?.participants?.user?.lastReadAt]);

  useEffect(() => {
    if (!isAnyUnreadedMessage) return;

    reversedList.forEach((message, i) => {
      const currentElement: any = messagesListNode?.current?.childNodes[0].childNodes[i];

      if (
        activeChatId &&
        positionOnListY &&
        currentElement &&
        positionOnListY >= currentElement.offsetTop &&
        myId !== message.sentBy &&
        (currentChat?.participants.user.lastReadAt || 0) < message.createdAt
      ) {
        dispatch(sendReadMessage({
          chatRoomId: activeChatId,
          lastReadAt: message.createdAt,
          participantId: myId,
        }));
      }
    });
  }, [positionOnListY]);

  return (
    <>
      <Stack
        ref={messagesListNode}
        flex={1}
        px={16}
        sx={{
          overflowY: 'auto',
          position: 'relative',
          ...scrollStyles,
        }}
      >
        {listMessages.length && activeUserRight?.id ?
          <Stack>
            {[...reversedList].map((message: Message, index, array) => {
              const nextMessage = array[index + 1];
              const hiddenTime = nextMessage && nextMessage.sentBy === message.sentBy
                && isSameMinute(message.createdAt, nextMessage.createdAt);

              const previousMessage = array[index - 1];
              const showUnreadMessagesLabel = false;

              const showDateLabel = !previousMessage || !isSameDay(message.createdAt, previousMessage.createdAt);

              return (
                <Fragment key={message.id}>
                  {showUnreadMessagesLabel &&
                    <Typography
                      color={colors.base.gray[4]}
                      fontWeight={500}
                      variant="caption"
                    >
                      <Divider>Unread messages</Divider>
                    </Typography>}
                  {showDateLabel && (
                    <Typography
                      alignSelf="center"
                      color={colors.base.gray[3]}
                      mb={12}
                      mt={8}
                      variant="body1"
                    >
                      {
                        getLabelStringFromDateTimeMessage(message.createdAt)
                      }
                    </Typography>
                  )}
                  <MessageItem
                    hiddenTime={hiddenTime}
                    message={message}
                    userId={myId}
                  />
                </Fragment>);
            })}
          </Stack>
          :
          <Typography
            color={colors.base.gray[4]}
            fontWeight={700}
            margin="auto"
            variant="subtitle1"
          >
            {NO_MESSAGES}
          </Typography>
        }
      </Stack>
      <Fab
        aria-label="add"
        onClick={handleScrollChatBottom}
        sx={{
          width: 46,
          height: 46,
          display: isShownScrollBtn ? undefined : 'none',
          position: 'absolute',
          bottom: 84,
          right: 16,
          backgroundColor: 'white',
          border: `1px solid ${colors.accent.green[1]}`,
        }}
      >
        <ChevronDownIcon color={colors.accent.green[1]}/>
      </Fab>
    </>
  );
};

export default ChatContent;
