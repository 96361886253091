export enum FileExtension {
  pdf = 'pdf',
  jpe = 'jpe',
  jpg = 'jpg',
  jpeg = 'jpeg',
  jfif = 'jfif',
  png = 'png',
  heic = 'heic',
  svg = 'svg',
  tiff = 'tiff',
  tif = 'tif',
  gif = 'gif',
  bmp = 'bmp',
  ico = 'ico',
  icon = 'icon',
  webp = 'webp',
}

export enum FileStages {
  prepared = 'prepared',
  loading = 'loading',
  uploaded = 'uploaded',
}

export enum FileType {
  jpeg = 'image/jpeg',
  jpe = 'image/jpeg',
  jpg = 'image/jpeg',
  jfif = 'image/jpeg',
  png = 'image/png',
  bmp = 'image/bmp',
  heif = 'image/heif',
  heic = 'image/heic',
  pdf = 'application/pdf',
  svg = 'image/svg+xml',
  tif = 'image/tiff',
  tiff = 'image/tiff',
  gif = 'image/gif',
  webp = 'image/webp',
  ico = 'image/vnd.microsoft.icon',
  icon = 'image/vnd.microsoft.icon',
}

export enum Browser {
  microsoftEdge = 'Microsoft Edge',
  microsoftInternetExplorer = 'Microsoft Internet Explorer',
  mozillaFirefox = 'Mozilla Firefox',
  opera = 'Opera',
  ucBrowser = 'UC Browser',
  samsungBrowser = 'Samsung Browser',
  googleChrome = 'Google Chrome',
  appleSafari = 'Apple Safari',
}

export enum Levels {
  COUNTRY = 'Country',
  REGIONAL = 'Regional',
  CLUB = 'Club',
}

export enum Destination {
  COUNTRY_WEB_PAGE = 'Country web page',
  REGIONAL_WEB_PAGE = 'Regional web page',
  CLUB_WEB_PAGE = 'Club web page',
  VOLUNTEER_MOBILE_APP = 'Volunteer mobile app',
  PARENTS_MOBILE_APP = 'Parents mobile app',
}

export enum ComparingOption {
  EQUAL_TO = 'equals',
  LESS_THAN = 'less',
  MORE_THAN = 'greater',
  BETWEEN = 'between',
}

export enum Gender {
  FEMALE = 'Female',
  MALE = 'Male',
  OTHER = 'Others',
}

export enum TitleType {
  MISS = 'MISS',
  MR = 'MR',
  MRS = 'MRS',
  MS = 'MS',
}

export enum Title {
  MISS = 'Miss',
  MR = 'Mr',
  MRS = 'Mrs',
  MS = 'Ms',
  OTHER = 'Others',
}

export enum FileTitleParent {
  CONSENT = 'Consent',
  INVOICE = 'Invoice',
  OTHER = 'Others',
}

export enum FileTitleYCM {
  CONSENT = 'Consent',
  OTHER = 'Others',
}

export enum FileTitle {
  CONSENT = 'Consent',
  GARDA_VETTING = 'Garda vetting',
  TRANING = 'Training',
  INVOICE = 'Invoice',
  OTHER = 'Others',
}

export enum FileTitleAttendeeCard {
  CONSENT = 'Consent',
  INVOICE = 'Invoice',
  OTHER = 'Others',
}

export enum AppType {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}

export enum PermissionType {
  GENERAL_FEATURES = 'GENERAL_FEATURES',
  CLIENT_MANAGEMENT = 'CLIENT_MANAGEMENT',
  EMPLOYEE_MANAGEMENT = 'EMPLOYEE_MANAGEMENT',
  INFORMATION_PUBLISHING = 'INFORMATION_PUBLISHING',
  ORGANIZATION_MANAGEMENT = 'ORGANIZATION_MANAGEMENT',
  YOUTH_MEMBER_MANAGEMENT = 'YOUTH_MEMBER_MANAGEMENT',
  ROLE_MANAGEMENT = 'ROLE_MANAGEMENT',
  REPORTING_MANAGEMENT = 'REPORTING_MANAGEMENT',
  EVENT_MANAGEMENT = 'EVENT_MANAGEMENT',
  EVENT_SCHEDULING = 'EVENT_SCHEDULING',
  MESSAGING = 'MESSAGING',
}

export enum Status {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum StatusHistory {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
  RENEWED = 'RENEWED',
}

export enum YouthMemberRightStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  NOT_JOINED = 'NOT_JOINED',
}

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  REQUESTED = 'REQUESTED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
}

export enum ClubDocumentStatus {
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
}

export enum FileRoutes {
  PUBLISHING_INFO = '/publishing-page',
  ANNOUNCEMENT_BOARD = '/board',
}

export enum MembershipFileRoutes {
  insurance = '/clubs/insurance',
  affiliation = '/clubs/affiliation',
}

export enum FileDirectory {
  FILES = '/files',
  DOCS = '/docs',
  CLIENT_DOCS = '/docs/client',
  YCM_DOCS = '/docs',
}

export enum SocialMedia {
  facebook = 'Facebook',
  instagram = 'Instagram',
  linkedin = 'Linkedin',
  twitter = 'Twitter',
  website = 'Website',
  youtube = 'Youtube',
  others = 'Others',
}

export enum NotificationsRedirect {
  EMPLOYEE_REQUEST_TAB = 'EMPLOYEE_REQUEST_TAB',
  ORGANIZATION_TAB_ACTIVE = 'ORGANIZATION_TAB_ACTIVE',
  ORGANIZATION_TAB_INSURANCE_REQUESTS = 'ORGANIZATION_TAB_INSURANCE_REQUESTS',
  PARENT_GUARDIAN_PROFILE = 'PARENT_GUARDIAN_PROFILE',
  YOUTH_CLUB_MEMBER_PROFILE = 'YOUTH_CLUB_MEMBER_PROFILE',
  YOUTH_CLUB_MEMBER_PAGE = 'YOUTH_CLUB_MEMBER_PAGE',
  ORGANIZATION_EDITING_FORM = 'ORGANIZATION_EDITING_FORM',
  MEMBERSHIP_DOCUMENTS_TAB = 'MEMBERSHIP_DOCUMENTS_TAB',
  HOME_PAGE = 'HOME_PAGE',
  FULL_EVENT_CARD = 'FULL_EVENT_CARD',
  SCHEDULING_PAGE = 'SCHEDULING_PAGE',
}

export enum TableStylesType {
  CLASSIC = 'classic',
  SIMPLE = 'simple',
}

export enum MembershipType {
  AFFILIATION = 'affiliation',
  INSURANCE = 'insurance',
}

export enum SortTypes {
  ASC = 'asc',
  DESC = 'desc',
}

export enum DescriptionType {
  ERROR = 'error',
  WARNING = 'warning',
}

export enum ClientStatus {
  RESTRICTED = 'RESTRICTED',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum RelationshipType {
  MOTHER = 'MOTHER',
  FATHER = 'FATHER',
  GUARDIAN = 'GUARDIAN',
}

export enum MUIColorType {
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  INHERIT = 'inherit',
  WARNING = 'warning',
  ERROR = 'error',
  PRIMARY = 'primary',
  INFO = 'info',
}

export enum SearchGroupNames {
  EVENTS = 'Events',
  USER_PROFILES = 'User profiles',
  ROLES = 'Roles',
  PARENTS_GUARDIANS = 'Parents/Guardians',
  YOUTH_CLUB_MEMBERS = 'Youth Club members',
}

export enum SearchResponseKeys {
  EVENTS = 'events',
  USER_PROFILES = 'employees',
  ROLES = 'roles',
  PARENTS_GUARDIANS = 'clients',
  YOUTH_CLUB_MEMBERS = 'youthClubMembers',
}

export enum UserType {
  EMPLOYEE = 'EMPLOYEE',
  CLIENT = 'CLIENT',
}

export enum DefaultRoles {
  SECRETARY = 'Secretary',
  VOLUNTEER = 'Volunteer',
  PARENT = 'Parent/Guardian',
}

export enum EventStatuses {
  RESERVED = 'RESERVED',
  BOOKED = 'BOOKED',
  ATTENDED = 'ATTENDED',
  CANCELLED = 'CANCELLED',
  NOT_ATTENDED = 'NOT_ATTENDED',
}

export enum PersonsGroupByType {
  EMPLOYEES = 'Employees',
  PARENTS_GUARDIANS = 'Parents/Guardians',
}
