import { Browser } from '../enums/common';

export const getBrowser = () => {
  const test = (regexp: any) => regexp.test(window.navigator.userAgent);
  switch (true) {
    case test(/edg/i): return Browser.microsoftEdge;
    case test(/trident/i): return Browser.microsoftInternetExplorer;
    case test(/firefox|fxios/i): return Browser.mozillaFirefox;
    case test(/opr\//i): return Browser.opera;
    case test(/ucbrowser/i): return Browser.ucBrowser;
    case test(/samsungbrowser/i): return Browser.samsungBrowser;
    case test(/chrome|chromium|crios/i): return Browser.googleChrome;
    case test(/safari/i): return Browser.appleSafari;
    default: return null;
  }
};
