import React from 'react';
import { Avatar, Box, Chip, Stack } from '@mui/material';

import Typography from '../../../../components/Typography';

import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { selectChannel, selectNewContact } from '../../../../store/sagas/sagaActions';
import { getStringFromTimeToDistanceNow } from '../../../../utils/timeUtils';
import { getFullName, getStringAvatar } from '../../../../helpers/nameHandler';
import { colors } from '../../../../themes/colors';
import { Message } from '../../types';
import useDebounce from '../../../../hooks/useDebounce';

interface ContactCardProps {
  cardData: {
    name?: string
    surname?: string
    organization?: string
    role?: string
  }
  messages?: Array<Message>
  id?: string
  searchedId?: number //userRightId
  disabled?: boolean
  isActive: boolean
  unreadMessgesCount?: number
}

const ContactCard = ({
  cardData,
  disabled,
  isActive,
  messages,
  id,
  searchedId,
  unreadMessgesCount,
}: ContactCardProps) => {

  const dispatch = useAppDispatch();
  const {
    user: { activeUserRight, profile },

  } = useAppSelector((state) => state) || {};

  const debouncedUnreadMessgesCount = useDebounce(unreadMessgesCount, 500);

  const lastMessage = messages?.[0];
  const newChat = !id && !searchedId;

  const myParticipantId = `${profile?.id}/${activeUserRight?.id}`;
  const fromMe = lastMessage?.sentBy == myParticipantId;

  const onContactClick = () => {
    if (isActive || newChat) return;
    if (id) {
      dispatch(selectChannel(id));
    }
    if (searchedId) {
      dispatch(selectNewContact(searchedId));
    }
  };

  return (
    <Stack
      alignItems="start"
      bgcolor={(isActive && colors.base.green[3]) || (disabled && colors.base.gray[7]) || undefined}
      borderBottom={`1px solid ${colors.base.gray[6]}`}
      direction="row"
      onClick={onContactClick}
      padding="12px 16px"
      spacing={14}
      sx={isActive ? {} : { cursor: 'pointer' }}
    >
      <Avatar
        sx={{
          width: 48,
          height: 48,
          backgroundColor: colors.base.white[1],
          border: `1px solid ${disabled ? colors.base.gray[8] : colors.base.gray[1]}`,
          textTransform: 'uppercase',
          fontSize: 12,
          fontWeight: 500,
          color: disabled ? colors.base.gray[8] : undefined,
        }}
      >
        {getStringAvatar({ firstName: cardData.name, lastName: cardData.surname })}
      </Avatar>
      <Stack
        flexGrow={1}
        spacing={8}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            color={disabled ? colors.base.gray[8] : undefined}
            variant="h6"
          >
            {getFullName({ firstName: cardData.name, lastName: cardData.surname })}
          </Typography>
          {id && (
            <Typography
              color={colors.base.gray[4]}>
              {getStringFromTimeToDistanceNow(lastMessage?.createdAt || new Date(2022, 2, 1))}
            </Typography>
          )}
        </Stack>
        <Box
          display="grid"
          gap={4}
          gridTemplateColumns="repeat(auto-fit, minmax(10px, max-content))"
        >
          <Chip
            label={cardData.organization}
            sx={{
              color: disabled ? colors.base.gray[8] : undefined,
              backgroundColor: colors.base.orange[1],
              '.MuiChip-label': { padding: '0 10px' },
            }}
          />
          <Chip
            label={cardData.role}
            sx={{
              color: disabled ? colors.base.gray[8] : undefined,
              backgroundColor: colors.base.blue[3],
              '.MuiChip-label': { padding: '0 10px' },
            }}
          />
        </Box>
        {(id || newChat) && (
          <Box
            display="grid"
            gap={16}
            gridTemplateColumns="repeat(auto-fit, minmax(10px, max-content))"
            justifyContent="space-between"
          >
            <Box display="grid">
              <Typography
                color={disabled ? colors.base.gray[8] : undefined}
                fontWeight={!!debouncedUnreadMessgesCount && !fromMe ? 700 : 400}
                variant={'body1'}
                withEllipsis
              >
                {fromMe || (!id && newChat) ? 'You: ' : ''}
                {lastMessage?.text || ''}
              </Typography>
            </Box>
            {!!debouncedUnreadMessgesCount && (
              <Chip
                label={debouncedUnreadMessgesCount}
                sx={{
                  color: colors.base.white[1],
                  backgroundColor: colors.accent.green[1],
                  '.MuiChip-label': { padding: '0 4px' },
                }}
              />
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default ContactCard;
