import React from 'react';
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import { Avatar, Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';

import AccountItemsContainer from '../../../components/AccountItemsContainer';
import HeaderPopover from '../HeaderPopover';
import { IconLogOut } from '../../icons/icons';
import { useAppSelector } from '../../../store/store';
import { getFullName, getStringAvatar } from '../../../helpers/nameHandler';
import { SUPER_ADMIN_ROLE_NAME } from '../../../constants/common';

import { iconButtonStyles } from '../styles';
import { colors } from '../../../themes/colors';
import { shadows } from '../../../themes/shadows';

const UserMenuPopover = () => {
  const { profile, activeUserRight, disabledSwitchUsers } = useAppSelector((state) => state.user);
  const { keycloak } = useKeycloak();

  const onLogOut = () => {
    window.onunload = () => {
      sessionStorage.removeItem('activeUserRightId');
    };
    keycloak?.logout();
  };

  const navigate = useNavigate();

  return (
    <PopupState
      popupId="demo-popup-popover"
      variant="popover"
    >
      {(popupState) => {
        const { onClick, ...restProps } = bindTrigger(popupState);

        const callback = disabledSwitchUsers ? () => {
          navigate(-1);
        } : onClick;

        return (
          <>
            <IconButton
              color="primary"
              sx={iconButtonStyles}
              {...restProps}
              onClick={callback}
            >
              <Avatar
                sx={{
                  backgroundColor: colors.base.white[1],
                  boxShadow: shadows[3],
                  transition: 'all 150ms',
                }}
              >
                {getStringAvatar(profile)}
              </Avatar>
            </IconButton>
            <HeaderPopover
              footer={(
                <Box
                  px={24}
                  py={14}
                >
                  <Button
                    color="error"
                    endIcon={<IconLogOut color={colors.accent.pink[1]}/>}
                    onClick={onLogOut}
                    size="small"
                  >
                    Log out
                  </Button>
                </Box>
              )}
              header={(
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={24}
                >
                  <Avatar>
                    {getStringAvatar(profile)}
                  </Avatar>
                  <Typography
                    fontWeight={700}
                    variant="body1"
                  >
                    {getFullName(profile)}
                  </Typography>
                </Stack>
              )}
              main={activeUserRight?.role.name === SUPER_ADMIN_ROLE_NAME ? null :
                <AccountItemsContainer setIsOpen={popupState.setOpen}/>}
              popupState={popupState}
            />
          </>
        );
      }}
    </PopupState>
  );
};

export default UserMenuPopover;
