import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { CircularProgress } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../store/store';
import Skeleton from '../Skeleton';

import LoginNotAllowed from '../../routes/LoginNotAllowed';
import styles from './EntryPage.module.scss';

import { getBrowser } from '../../utils/browserUtils';
import { checkProfileAvailability } from '../../api/user';
import { getUser } from '../../store/userSlice';
import { getPermissions } from '../../store/permissionsSlice';
import { AppType, OrganizationStatus } from '../../enums/common';
import LoginIsNotPossibleMessage from '../../routes/LoginNotAllowed/LoginIsNotPossibleMessage';
import LinksToMobileApp from '../../routes/LoginNotAllowed/LinksToMobileApp';

import { setBrowser } from '../../store/appSlice';
import { fetchMeta, initializeMessenger } from '../../store/sagas/sagaActions';
import { connectToWebsocket, disconnectWebsocket } from '../../api/websocketConnection';

const messageUnavailableProfile = 'Your profile is currently unavailable.';
const messageUnavailableOrganisation = 'Your organisation is currently unavailable.';
const { REACT_APP_HOME_URL } = process.env;
//TODO move logic to Redux

const EntryPage = () => {
  const { keycloak, initialized } = useKeycloak();
  const [isAllowedByStatus, setAllowedByStatus] = useState(false);
  const [isAllowedForWeb, setAllowedForWeb] = useState(false);
  const [isAllowedForMobile, setAllowedForMobile] = useState(false);
  const [isCheckProfileAvailabilityLoading, setCheckProfileAvailabilityLoading] = useState(true);
  const [isCheckOrganisationAvailabilityLoading, setCheckOrganisationAvailabilityLoading] = useState(true);

  const dispatch = useAppDispatch();

  const browser = getBrowser();

  dispatch(setBrowser(browser));

  const { activeUserRight, profile } = useAppSelector((state) => state.user);
  const activeUserRightId = activeUserRight?.id;

  window.onunload = () => {
    if (activeUserRightId) sessionStorage.setItem('activeUserRightId', activeUserRightId.toString());
  };

  useEffect(() => {
    if (initialized && keycloak && !keycloak.authenticated) {
      keycloak.login({
        redirectUri: REACT_APP_HOME_URL,
      });
    }
  }, [initialized, keycloak]);

  const { userRights = [], firstLogin, id } = profile || {};

  useEffect(() => {
    if (keycloak && keycloak.authenticated) {
      checkProfileAvailability()
        .then(() => {
          setAllowedByStatus(true);
          dispatch(getUser());
          dispatch(getPermissions());
          dispatch(fetchMeta());
        })
        .catch(() => {
          setAllowedByStatus(false);
          setCheckOrganisationAvailabilityLoading(false);
        })
        .finally(() => {
          setCheckProfileAvailabilityLoading(false);
        });
    }
  }, [initialized, keycloak]);

  useEffect(() => {
    if (initialized && keycloak?.token && id && activeUserRightId) {
      disconnectWebsocket();
      dispatch(initializeMessenger());
      connectToWebsocket(dispatch, {
        token: keycloak.token,
        userId: `${id}/${activeUserRightId}`,
        userRightId: activeUserRightId,
      });
    }
  }, [initialized, keycloak?.token, activeUserRightId, id]);

  useEffect(() => {
    if (isCheckOrganisationAvailabilityLoading && !isCheckProfileAvailabilityLoading && id && profile) {
      setCheckOrganisationAvailabilityLoading(false);
      userRights.forEach((userRight) => {
        if (userRight.organization.status !== OrganizationStatus.DEACTIVATED) {
          if (
            userRight.role.appType === AppType.WEB &&
            !isAllowedForWeb
          ) {
            setAllowedForWeb(true);
          }

          if (userRight.role.appType === AppType.MOBILE && !isAllowedForMobile) {
            setAllowedForMobile(true);
          }
        }
      });
    }
  }, [userRights, id, isCheckProfileAvailabilityLoading, profile]);

  return (
    keycloak?.authenticated
    && !isCheckProfileAvailabilityLoading
    && !isCheckOrganisationAvailabilityLoading
      ? (isAllowedByStatus && isAllowedForWeb)
        ? (
          <Skeleton/>
        ) : (
          <Routes>
            <Route
                element={
                  <LoginNotAllowed>
                    <LinksToMobileApp withPasswordStatus={false}/>
                  </LoginNotAllowed>
                }
                path="/mobile-links"
              />
              <Route
                element={
                  <LoginNotAllowed>
                    {isAllowedByStatus
                      ? firstLogin && !isAllowedForWeb && isAllowedForMobile
                        ? <LinksToMobileApp withPasswordStatus/>
                        : <LoginIsNotPossibleMessage message={messageUnavailableOrganisation}/>
                      : <LoginIsNotPossibleMessage message={messageUnavailableProfile}/>}
                  </LoginNotAllowed>
                }
                path="*"
              />
            </Routes>
        )
      : (
        <div className={styles.spinner}>
          <CircularProgress/>
        </div>
      )
  );
};

export default EntryPage;
