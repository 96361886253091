import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateItem, MonthYear } from '../types/dateAndTime';

interface InitialState {
  chosenDate: DateItem | null
  chosenMonthYear: MonthYear | null
}

const initialState: InitialState = {
  chosenDate: null,
  chosenMonthYear: null,
};

const schedulingSlice = createSlice({
  name: 'scheduling',
  initialState: initialState,
  reducers: {
    setChosenDate: (state, action: PayloadAction<DateItem | null>) => {
      state.chosenDate = action.payload;
    },
    setChosenMonthYear: (state, action: PayloadAction<MonthYear | null>) => {
      state.chosenMonthYear = action.payload;
    },
    resetCalendar: () => initialState,
  },
});

export const { setChosenDate, setChosenMonthYear, resetCalendar } = schedulingSlice.actions;

export default schedulingSlice.reducer;
