export const colors = {
  base: {
    black: {
      1: '#333538',
    },
    blue: {
      3: '#CCE5FF',
      4: '#F4FAFF',
      5: '#EDFDFF',
      6: '#809EAB',
      7: '#D5EEF1',
      8: '#B0C7EA',
      9: '#F3F8FD',
    },
    gray: {
      1: '#333538',
      3: '#606875',
      4: '#A9B3BC',
      5: '#D9DDE1',
      6: '#E5EAF0',
      7: '#F2F2F2',
      8: '#969696',
      9: '#616161',
    },
    green: {
      1: '#9AD27E',
      2: '#B7DFA3',
      3: '#E5F1E3',
      4: '#FBFEFA',
      5: '#F7FBF7',
    },
    orange: {
      1: '#FAEAD2',
    },
    pink: {
      1: '#F0DADE',
    },
    white: {
      1: '#FFFFFF',
      2: '#F4F8FB',
      3: '#F9F9F9',
      4: '#FCFDFE',
    },
    yellow: {
      1: '#F1F518',
    },
  },
  accent: {
    aqua: {
      1: '#46AABD',
    },
    blue: {
      1: '#465BBD',
      2: '#3E77CD',
      3: '#575FCF',
    },
    brown: {
      1: '#B87251',
    },
    green: {
      1: '#6FBE46',
      2: '#5A964F',
      3: '#6ABE4A',
    },
    orange: {
      1: '#E36414',
      2: '#FD6E15',
      3: '#FFC048',
    },
    pink: {
      1: '#B7475D',
      2: '#F53B57',
      3: '#D32F2F',
    },
    yellow: {
      1: '#E3E214',
    },
  },
};
