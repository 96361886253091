import { NotificationsRedirect, OrganizationStatus, Status } from '../enums/common';
import routes from './routes';
import tabs from '../forms/OrganizationAndClubForm/constants/tabs';

import { getParentGuardianById, getYouthClubMemberById } from '../api/user';
import { getOrganizationById } from '../api/organizations';
import { getFullCardEvent } from '../api/events';

type CheckExistingFunction = ({ id, userRightId }: { id: string | number, userRightId: number }) => Promise<any>;

interface NotificationRouteItem {
  redirect: string
  mainRoute: string
  mainRoutePermissionKey?: string
  searchParam?: string
  alternativeRoute?: string
  alternativeRoutePermissionKey?: string
  fallbackRoute?: string
  checkExistingFunction?: CheckExistingFunction
}

export const getNotificationRoutes = (permissionNames: Record<string, string> | null): NotificationRouteItem[] => {
  if (!permissionNames) return [];

  return [
    {
      redirect: NotificationsRedirect.EMPLOYEE_REQUEST_TAB,
      mainRoute: routes.administration.profiles.main,
      searchParam: `?tab=${Status.REQUESTED}`,
    },
    {
      redirect: NotificationsRedirect.PARENT_GUARDIAN_PROFILE,
      mainRoute: routes.registration.parentsGuardians.edit,
      mainRoutePermissionKey: permissionNames.EDIT_CLIENT_DETAILS,
      alternativeRoute: routes.registration.parentsGuardians.view,
      alternativeRoutePermissionKey: permissionNames.VIEW_CLIENT_DETAILS,
      fallbackRoute: routes.registration.parentsGuardians.main,
      checkExistingFunction: getParentGuardianById,
    },
    {
      redirect: NotificationsRedirect.ORGANIZATION_TAB_ACTIVE,
      mainRoute: routes.administration.organisationsAndClubs.main,
    },
    {
      redirect: NotificationsRedirect.ORGANIZATION_TAB_INSURANCE_REQUESTS,
      mainRoute: routes.administration.organisationsAndClubs.main,
      searchParam: `?tab=${OrganizationStatus.REQUESTED}`,
    },
    {
      redirect: NotificationsRedirect.MEMBERSHIP_DOCUMENTS_TAB,
      mainRoute: routes.administration.organisationsAndClubs.edit,
      mainRoutePermissionKey: permissionNames.EDIT_ORGANIZATION_DETAILS,
      searchParam: `?tab=${tabs.membership.value}`,
      alternativeRoute: routes.administration.organisationsAndClubs.view,
      alternativeRoutePermissionKey: permissionNames.VIEW_ORGANIZATION_DETAILS,
      fallbackRoute: routes.administration.organisationsAndClubs.main,
      checkExistingFunction: getOrganizationById,
    },
    {
      redirect: NotificationsRedirect.ORGANIZATION_EDITING_FORM,
      mainRoute: routes.administration.organisationsAndClubs.edit,
      mainRoutePermissionKey: permissionNames.EDIT_ORGANIZATION_DETAILS,
      alternativeRoute: routes.administration.organisationsAndClubs.view,
      alternativeRoutePermissionKey: permissionNames.VIEW_ORGANIZATION_DETAILS,
      fallbackRoute: routes.administration.organisationsAndClubs.main,
      checkExistingFunction: getOrganizationById,
    },
    {
      redirect: NotificationsRedirect.YOUTH_CLUB_MEMBER_PROFILE,
      mainRoute: routes.registration.youthClubMembers.edit,
      mainRoutePermissionKey: permissionNames.EDIT_YOUTH_FROM_YOUTH_MEMBER_PAGE,
      alternativeRoute: routes.registration.youthClubMembers.view,
      alternativeRoutePermissionKey: permissionNames.VIEW_YOUTH_DETAILS,
      fallbackRoute: routes.registration.youthClubMembers.main,
      checkExistingFunction: getYouthClubMemberById,
    },
    {
      redirect: NotificationsRedirect.YOUTH_CLUB_MEMBER_PAGE,
      mainRoute: routes.registration.youthClubMembers.main,
    },
    {
      redirect: NotificationsRedirect.HOME_PAGE,
      mainRoute: routes.home,
    },
    {
      redirect: NotificationsRedirect.FULL_EVENT_CARD,
      mainRoute: `${routes.scheduling}?id=`,
      mainRoutePermissionKey: permissionNames.VIEW_EVENT_SCHEDULING_DETAILS,
      fallbackRoute: routes.scheduling,
      checkExistingFunction: getFullCardEvent as CheckExistingFunction,
    },
    {
      redirect: NotificationsRedirect.SCHEDULING_PAGE,
      mainRoute: `${routes.scheduling}?date=`,
      mainRoutePermissionKey: permissionNames.VIEW_CALENDAR_MONTH_VIEW,
    },
  ];
};
