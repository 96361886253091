import { GridSortItem } from '@mui/x-data-grid';

import urls from '../constants/urls';
import { client } from './client';
import {
  AttendeeType,
  EventPlaceType,
  EventStatus,
  InvolvementFilterType,
  PaymentType,
  PaymentFilterType,
  SwitchType,
} from '../enums/scheduling';
import { DestinationItem, OrganizationItem, ResponseWithPagination, UserRight } from '../types/common';
import { EventCalendar, FullEvent } from '../types/events';
import { CreateEventPayloadData, UpdateEventPayloadData } from '../types/events';
import { ClientStatus, ComparingOption, Status, UserType } from '../enums/common';

const { events, scheduler } = urls;

export interface FilterEventsParams {
  startDate?: number
  startDateOption?: ComparingOption
  paymentType?: string
  attendeeAudienceIds?: string
  attendeeOrganizationIds?: string
  responsiblePersonIds?: string
  numberOfSpaces?: number
  numberOfSpacesOption?: ComparingOption
  assistantOrganizationIds?: string
  changeTime?: number
  changeTimeOption?: ComparingOption
  changedByUserIds?: string
  changedByOrganizationIds?: string
  registrationTimeLimit?: boolean
}

interface QueryParams {
  page?: number | string
  size?: number | string
  from?: number
  to?: number
  userRightId?: number
  eventStatus?: EventStatus
  sortingOptions?: GridSortItem
  filters?: FilterEventsParams
}

export interface EventResponse {
  id: string
  consent?: string
  numberOfAssistant: number
  details: {
    title: string
    from: number
    to: number
    description?: string
    numberOfSpaces: number
    placeType: EventPlaceType
    venue: {
      id: number
      postCode?: string
      formattedAddress: string
      placeId: string
      lat: number
      lng: number
      createdAt: number
      updatedAt: number
    },
    paymentType: PaymentType
    paymentLink: string
    price: string
    registrationLimitTime?: number
  },
  responsiblePersons: Array<{
    shareContact: boolean
    userRight: UserRight,
  }>
  attendeeAudience: Array<{
    destination: DestinationItem,
    organizations: Array<OrganizationItem>
  }>,
  assistantAudience: {
    destination: DestinationItem,
    organizations: Array<OrganizationItem>
  },
}

interface User {
  id: string
  username: string
  name: string
  firstName: string
  lastName: string
  email: string
  userType: UserType
  createdAt: number
  updatedAt: number
  updatedByUserRight: string
  isCreatedByAdmin: true
  status: Status
  clientStatus: ClientStatus
  contactNumber: string
}

export interface DataForFilters {
  changedByOrganizations: OrganizationItem[]
  regions: OrganizationItem[]
  clubs: OrganizationItem[]
  eventStatus: EventStatus
  paymentType: PaymentType
  numberFilterOptions: ComparingOption
  availableUsers: User[]
  attendeeAudiences: DestinationItem[]
  registrationTimeLimit: SwitchType
}

interface UpdateEventProps {
  eventId: string
  payloadData: UpdateEventPayloadData
  userRightId: number
}

interface ResponsiblePerson extends UserRight {
  isBusy: boolean
}

interface GetResponsiblePersonsQueryParams {
  userRightId: number
  from?: number
  to?: number
}

export const createEvent = (payloadData: CreateEventPayloadData, userRightId: number) => client.post(`${events}/create`, payloadData, {
  params: {
    userRightId,
  },
});

export const updateEvent = ({
  eventId,
  payloadData,
  userRightId,
}: UpdateEventProps) => client.patch(`${events}/${eventId}`, payloadData, {
  params: {
    userRightId,
  },
});

export const getEventById = (eventId: string, userRightId: number): Promise<EventResponse> => client.get(`${events}/${eventId}`, {
  params: {
    userRightId,
  },
});

export const getDataForEventFilters = (userRightId: number): Promise<DataForFilters> => client.get(`${events}/filter`, {
  params: {
    userRightId,
  },
});

export const getFilteredEvents = ({
  page,
  size,
  from,
  to,
  userRightId,
  eventStatus,
  filters,
  sortingOptions,
}: QueryParams): Promise<ResponseWithPagination<Event>> => client.get(events, {
  params: {
    page,
    size,
    from,
    to,
    userRightId,
    eventStatus,
    sortBy: sortingOptions?.field,
    sortDirection: sortingOptions?.sort,
    ...filters,
  },
});

export const getEventByDates = (params: {
  userRole?: InvolvementFilterType
  eventPaymentType?: PaymentFilterType
  from: number
  to: number
  userRightId?: number
}): Promise<EventCalendar[]> => client.get(scheduler, { params });

export const getFullCardEvent = ({ id, userRightId }: {
  id: string
  userRightId?: number
}): Promise<FullEvent> =>
  client.get(`${scheduler}/${id}`, {
    params: {
      userRightId,
    },
  });

interface PayloadDataMarkAsPaid {
  id: string | number
  type?: AttendeeType
}

interface MarkAsPaidProps {
  eventId: string
  userRightId: number
  payloadData: PayloadDataMarkAsPaid
}

interface MarkAsCheckIn extends MarkAsPaidProps {
  participantType: string
}

export const markAsPaid = ({ eventId, payloadData, userRightId }: MarkAsPaidProps) => (
  client.post(`${scheduler}/${eventId}/attendees/mark-as-paid`, payloadData, {
    params: {
      userRightId,
    },
  })
);

export const markAsCheckIn = ({ eventId, payloadData, userRightId, participantType }: MarkAsCheckIn) => (
  client.post(`${scheduler}/${eventId}/${participantType}/check-in`, payloadData, {
    params: {
      userRightId,
    },
  })
);

export const deleteEventsByIds = (userRightId: number, ids: string[]): Promise<any> => client.delete(events, {
  params: {
    userRightId,
    deletedIds: ids.join(','),
  },
});

interface PayloadData {
  ids: string[]
}

export const cancelEventsByIds = (
  userRightId: number,
  payloadData: PayloadData,
): Promise<any> => client.patch(`${events}/cancel`, payloadData, {
  params: {
    userRightId,
  },
});

export const downloadEvents = ({
  eventStatus,
  sortingOptions,
  userRightId,
  filters = {},
}: QueryParams): Promise<Blob> => client.get(`${events}/download`, {
  params: {
    eventStatus,
    sortBy: sortingOptions?.field,
    sortDirection: sortingOptions?.sort,
    userRightId,
    ...filters,
  },
  responseType: 'blob',
});

export const getResponsiblePersons = ({
  userRightId,
  from,
  to,
}: GetResponsiblePersonsQueryParams): Promise<ResponsiblePerson[]> => client.get(`${events}/responsible-person/all`, {
  params: {
    userRightId,
    from,
    to,
  },
});
